import {Component, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {PhoneFlagsService} from '../phoneFlags.service';
import {SubdomainsService} from '../subdomain.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';

@Component({
    selector: 'app-restore',
    templateUrl: './restore.component.html',
    styleUrls: ['./restore.component.css'],
    animations: [
        trigger('hidepreloader', [
            state('start', style({
                display: 'flex',
                opacity: 1
            })),
            state('end', style({
                display: 'none',
                opacity: 0
            })),
            transition('start => end', animate(500))
        ])
    ],
})

export class RestoreComponent implements OnInit {
    constructor(private translateService: TranslateService, private router: Router, private http: HttpClient, public PhoneFlagsService: PhoneFlagsService, public SubdomainsService: SubdomainsService, private cookieService: CookieService) {
    }

    hidepreloaderState = 'start';
    form: FormGroup;
    formConf: FormGroup;
    formPassword: FormGroup;
    subdomain = this.SubdomainsService.subdomain;
    session = this.randomString(32);
    mode = 'tel';
    wrongCode = 0;
    passwordMode = 'password';
    passwordNotSame = false;
    userNoExist = false;
    phoneFocused = false;
    buttonText = 'Resend message';

    @ViewChild('code') code;

    private randomString(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    ngOnInit() {
        setTimeout(() => {
            this.animateLoader();
        }, 500);
        this.form = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
        });
        this.formConf = new FormGroup({
            num1: new FormControl('', [Validators.required, Validators.minLength(4)]),
        });
        this.formPassword = new FormGroup({
            password: new FormControl('', [Validators.required, Validators.minLength(6)]),
            confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
        });
        if (this.cookieService.get('selectedLng')) {
            this.translateService.setDefaultLang(this.cookieService.get('selectedLng'));
        } else {
            this.translateService.setDefaultLang('fr');
        }
    }

    animateLoader() {
        this.hidepreloaderState = 'end';
    }

    flagChanged() {
        const formData = {...this.form.value};
        console.log('formData', formData.flag);
        this.PhoneFlagsService.setFlag(formData.flag);
    }

    restoreRequest(email, role, byEmail): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=restoreRequest', {
            email,
            role,
            byEmail,
        }, {
            headers: new HttpHeaders({
                authorization: this.session
            })
        });
    }

    submit() {
        if (this.form.invalid) {
            return;
        }
        const formData = {...this.form.value};
        this.restoreRequest(formData.email, this.subdomain, 1).subscribe(response => {
            console.log('Response restore', response);
            if (response.status == 1) {
                this.mode = 'code';
            } else {
                // user not found
                this.userNoExist = true;
            }
        });
    }

    confirmUserBySms(email, code, role, byEmail): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=restoreAuth', {
            email,
            code,
            role,
            byEmail,
        }, {
            headers: new HttpHeaders({
                authorization: this.session
            })
        });
    }

    submitCode(code: string) {
        this.hidepreloaderState = 'start';
        const formDataPhone = {...this.form.value};
        this.confirmUserBySms(formDataPhone.email, code, this.subdomain, 1).subscribe(response => {
            console.log('Response sms confirm', response);
            if (response.result === 1) {
                localStorage.setItem('access_key', this.session);
                localStorage.setItem('auth_key', response.access_key);
                localStorage.setItem('user_id', response.user_id);
                this.mode = 'password';
            } else {
                this.wrongCode = 1;
                this.code.reset();
            }
            this.hidepreloaderState = 'end';
        });
    }

    onCodeChanged() {

    }

    submitPassword() {
        if (this.formPassword.invalid) {
            return;
        }
        const formData = {...this.formPassword.value};
        if (formData.password !== formData.confirmPassword) {
            console.log('Value - ', formData.password, formData.confirmPassword);
            this.passwordNotSame = true;
            return;
        }
        console.log('Value - ', formData);
        this.setNewPassword(formData.password).subscribe(response => {
            console.log('Response auth', response);
            if (response.result) {
                // go to dashboard
                this.SubdomainsService.gotoBookingsPage();
            }
        });
    }

    setNewPassword(password): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=restorePassword', {
            password,
        }, {
            headers: new HttpHeaders({
                authorization: this.session
            })
        });
    }

    resend() {
        this.buttonText = 'Sending...';
        const formData = {...this.form.value};
        this.restoreRequest(formData.email, this.subdomain, 1).subscribe(response => {
            console.log('Response restore', response);
            if (response.status == 1) {
                this.buttonText = 'Sent';
                setTimeout(() => {
                    this.buttonText = 'Resend message';
                }, 1000);
            }
        });
    }
}
