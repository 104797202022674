<div class="currentBooking alarm" *ngIf="currentBookingId">
    <div class="blured_view"></div>
    <!--    <div class="loader" [@hidepreloader]="hidepreloaderState">-->
    <!--        <img class="loaderImg" src="assets/img/loader.gif">-->
    <!--    </div>-->
    <div class="right">
        <div class="modal" *ngIf="alreadySent">
            <div class="frame">
                <p>Déjà envoyé pour accord</p>
                <div class="flex" style="justify-content: center;">
                    <button type="button" style="margin-right: 0;" (click)="alreadySent = false;">OK</button>
                </div>
            </div>
        </div>
        <div class="modal" *ngIf="showFillAllFields">
            <div class="frame">
                <p>Veuillez renseigner les champs indiqués en rouge</p>
                <div class="flex" style="justify-content: center;">
                    <button type="button" style="margin-right: 0;" (click)="showFillAllFields = false; focusOnSome();">OK</button>
                </div>
            </div>
        </div>
        <div class="modal" *ngIf="showDeleteAlert">
            <div class="frame">
                <p>Souhaitez-vous vraiment supprimer la zone {{deletezoneNumber + 1}}?</p>
                <div class="flex">
                    <button type="button" (click)="deleteZone()">Oui</button>
                    <button type="button" (click)="closePopUp()">Non</button>
                </div>
            </div>
        </div>
        <!-- booking-->
        <form class="loginForm" [formGroup]="formNew"
              (scroll)="onScroll()" [className]="showAlertInvoice ? 'alertValidation' : ''">
            <div style="height: 100%;">
                <div class="row headerBooking">
                    <div class="col-10">
                        <div *ngIf="booking" class="bookingN">Réservation #{{this.currentBookingId}} crée
                            le {{booking.created | date: 'fullDate' : 'UTC' : 'fr_FR'}}
                            par {{booking.author_name}} {{booking.author_surname}}</div>
                    </div>
                    <div class="col flexFull">
                        <!--                        <div class="status">-->
                        <!--                            <mat-select class="bookingStatus" formControlName="status">-->
                        <!--                                <mat-option style="background: #bfbf3c;"-->
                        <!--                                            value="0">{{ 'booking.Created' | translate }}</mat-option>-->
                        <!--                                <mat-option value="1"-->
                        <!--                                            style="background: green;">{{ 'booking.Confirmed' | translate }}</mat-option>-->
                        <!--                                <mat-option value="2">{{ 'booking.In progress' | translate }}</mat-option>-->
                        <!--                                <mat-option value="3">{{ 'booking.Finished' | translate }}</mat-option>-->
                        <!--                                <mat-option value="-1"-->
                        <!--                                            style="background: red;">{{ 'booking.Canceled' | translate }}</mat-option>-->
                        <!--                            </mat-select>-->
                        <!--                        </div>-->
                        <div class="delete">
                            <button class="button" type="button" (click)="closeBooking()">
                                <svg style="width: 13px; height: 13px; margin-right: 5px;" viewBox="0 0 329.26933 329"
                                     width="329pt" xmlns="http://www.w3.org/2000/svg">
                                    <path style="fill: #fff;"
                                          d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0"></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="bodyBooking">
                    <div class="allSteps">
                        <div class="columnsBooking" #widgetsContent>
                            <div class="step step1" [@step1]="step1State">
                                <div class="box step_1_general">
                                    <div class="topPart">
                                        <div class="flex">
                                            <div class="justNumber active"><span
                                                    class="zoneN">1</span>
                                            </div>
                                            <h3>Détails DE L’OFFRE</h3>
                                        </div>
                                        <!--                                        <div class="option">-->
                                        <!--                                            <div class="value subH">-->
                                        <!--                                                Date et heure-->
                                        <!--                                            </div>-->
                                        <!--                                            <div class="value">-->
                                        <!--                                                <mat-form-field style="width: 150px;">-->
                                        <!--                                                    <input style="text-transform: capitalize;" matInput-->
                                        <!--                                                           [matDatepicker]="start"-->
                                        <!--                                                           (click)="start.open()" formControlName="startDate"-->
                                        <!--                                                           [min]="today">-->
                                        <!--                                                    <mat-datepicker #start></mat-datepicker>-->
                                        <!--                                                </mat-form-field>-->
                                        <!--                                                <mat-select formControlName="timeHour"-->
                                        <!--                                                            class="timeHour"-->
                                        <!--                                                            style="margin: 0 2px 0 5px; text-align: center;">-->
                                        <!--                                                    <mat-option value="00">00</mat-option>-->
                                        <!--                                                    <mat-option *ngFor="let number of hours"-->
                                        <!--                                                                value="{{number < 10 ? '0'+number : number}}">{{number < 10 ? '0' + number : number}}</mat-option>-->
                                        <!--                                                </mat-select>-->
                                        <!--                                                :-->
                                        <!--                                                <mat-select formControlName="timeMin"-->
                                        <!--                                                            class="timeHour"-->
                                        <!--                                                            style="margin-left: 3px; text-align: center;">-->
                                        <!--                                                    <mat-option *ngFor="let number of minutes"-->
                                        <!--                                                                value="{{number < 10 ? '0'+number : number}}">{{number < 10 ? '0' + number : number}}</mat-option>-->
                                        <!--                                                </mat-select>-->
                                        <!--                                            </div>-->
                                        <!--                                        </div>-->
                                        <div class="option">
                                            <div class="value subH">
                                                {{ 'users.Address' | translate }}
                                            </div>
                                            <div class="value input" style="margin-top: 0;">
                                                <input ngx-google-places-autocomplete formControlName="pickup" #place
                                                       #placesRef="ngx-places"
                                                       (onAddressChange)="handleAddressChange($event); patchMText();"
                                                       placeholder="Adresse de l’installation"
                                                       name="somethingAutofillDoesntKnow"
                                                       autocomplete="new-password"
                                                />
                                                <button *ngIf="this.formNew.get('pickup').value.length > 0"
                                                        (click)="this.formNew.controls.pickup.setValue('')">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <rect x="4" y="11" width="16" height="2" rx="1" fill="white"/>
                                                        <rect x="13" y="4" width="16" height="2" rx="1"
                                                              transform="rotate(90 13 4)" fill="white"/>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="option">
                                            <div class="value subH">
                                                Types habitation
                                            </div>
                                            <div class="value">
                                                <mat-select class="bookingTypes" placeholder="Maison, appartement..."
                                                            formControlName="typeHabitation"
                                                            (closed)="this.submitNewBooking(this.currentBookingId)">
                                                    <mat-option value="1">Appartement</mat-option>
                                                    <mat-option value="2">Maison individuelle</mat-option>
                                                    <mat-option value="3">Bureau</mat-option>
                                                    <mat-option value="4">Commerce</mat-option>
                                                    <mat-option value="5">Immeuble</mat-option>
                                                    <mat-option value="6">Hotel</mat-option>
                                                    <mat-option value="7">Local industriel</mat-option>
                                                    <mat-option value="8">Usine</mat-option>
                                                </mat-select>
                                            </div>
                                        </div>
                                        <div class="option">
                                            <div class="value subH">
                                                Type de service
                                            </div>
                                            <div class="value multiple">
                                                <mat-select class="bookingTypes" formControlName="typeService" multiple
                                                            placeholder="Alarmes, vidéosurveillance..."
                                                            (closed)="this.submitNewBooking(this.currentBookingId)">
                                                    <mat-option value="1" class="notSelected">Alarme Intrusion
                                                    </mat-option>
                                                    <mat-option value="2" class="notSelected">Alarme Incendie
                                                    </mat-option>
                                                    <mat-option value="3" class="notSelected">Video</mat-option>
                                                    <mat-option value="4" class="notSelected">Controle d’accés
                                                    </mat-option>
                                                    <mat-option value="5" class="notSelected">Contrat de Maintenance
                                                    </mat-option>
                                                    <mat-option value="6" class="notSelected">Intervention</mat-option>
                                                </mat-select>
                                            </div>
                                        </div>
                                        <!--                                        <div class="option">-->
                                        <!--                                            <div class="value subH">-->
                                        <!--                                                {{ 'booking.Comment' | translate }}-->
                                        <!--                                            </div>-->
                                        <!--                                            <div class="value input">-->
                                        <!--                                                    <textarea-->
                                        <!--                                                            placeholder="Veuillez nous indiquer des détails supplémentaires"-->
                                        <!--                                                            ng-model="myTextarea"-->
                                        <!--                                                            formControlName="info"></textarea>-->
                                        <!--                                                <button *ngIf="this.formNew.get('info').value.length > 0"-->
                                        <!--                                                        (click)="this.formNew.controls.info.setValue('')">-->
                                        <!--                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"-->
                                        <!--                                                         xmlns="http://www.w3.org/2000/svg">-->
                                        <!--                                                        <rect x="4" y="11" width="16" height="2" rx="1" fill="white"/>-->
                                        <!--                                                        <rect x="13" y="4" width="16" height="2" rx="1"-->
                                        <!--                                                              transform="rotate(90 13 4)" fill="white"/>-->
                                        <!--                                                    </svg>-->
                                        <!--                                                </button>-->
                                        <!--                                            </div>-->
                                        <!--                                        </div>-->
                                        <div class="option">
                                            <div class="value subH">
                                                Nombre de zones à sécuriser
                                            </div>
                                            <div class="value">
                                                <mat-select class="bookingTypes" formControlName="numOfZ"
                                                            placeholder="1, 2, 3..."
                                                            (closed)="cloneSeveralZones(); this.submitNewBooking(this.currentBookingId);">
                                                    <mat-option *ngFor="let number of zones16"
                                                                value="{{number}}">{{number}}</mat-option>
                                                </mat-select>
                                            </div>
                                        </div>

                                        <div class="option">
                                            <div class="value subH">
                                                Coefficient
                                            </div>
                                            <div class="value input">
                                                <input type="number"
                                                       formControlName="coef"
                                                       (change)="this.recalcPrice();"
                                                       autocomplete="new-password"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="box" *ngIf="this.formNew.get('typeService').value.includes('5')">
                                    <div class="option products">
                                        <div class="option">
                                            <div class="key zoneName"
                                                 style="display: flex; justify-content: space-between; align-items: center;">
                                                <h3 style="margin: 0;">Contrat de Maintenance</h3>
                                            </div>
                                            <div style="display: flex; flex-direction: column; justify-content: space-between; height: calc(100vh - 410px);">
                                                <div>
                                                    <div class="option">
                                                        <div class="key subH">
                                                            Tarif annuelle
                                                        </div>
                                                        <div style="flex-direction: row; align-items: center; display: flex;">
                                                            <div class="value input hourRate">
                                                                <input type="number"
                                                                       formControlName="tarifMPrice"
                                                                       (change)="this.submitNewBooking(this.currentBookingId); this.patchMText()"
                                                                       autocomplete="new-password"
                                                                />
                                                                <span>Euros par an</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="option">
                                                        <div class="key subH">
                                                            Contrat de Maintenance text
                                                        </div>
                                                        <div style="flex-direction: row; align-items: center; display: flex;">
                                                            <div class="value input hourRate">
                                                                <textarea type="text"
                                                                          formControlName="descriptionM"
                                                                          (change)="this.submitNewBooking(this.currentBookingId);"
                                                                          autocomplete="new-password"
                                                                          style="height: 400px;"
                                                                ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="box zoneBox" *ngFor="let zone of zones; let z = index"
                                     [attr.data-index]="z">
                                    <div class="option products">
                                        <div class="option">
                                            <div class="key zoneName"
                                                 style="display: flex; justify-content: space-between; align-items: center;">
                                                <!--                                                <div>-->
                                                <h3 style="margin: 0;">Zone {{+z + 1}}</h3>
                                                <div>
                                                    <!--                                                                                                    <button type="button"-->
                                                    <!--                                                                                                            (click)="cloneZone(z)">-->
                                                    <!--                                                                                                        <img src="assets/img/duplicate.svg">-->
                                                    <!--                                                                                                    </button>-->
                                                    <button *ngIf="z != 0 || zones.length > 1" type="button"
                                                            (click)="openPopUp(z)" class="deleteZone">
                                                        <svg width="64" height="65" viewBox="0 0 64 65" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M60.8 12.8044H44.8V7.46041C44.7249 5.41183 43.8407 3.4766 42.341 2.07895C40.8414 0.681295 38.8488 -0.0647311 36.8 0.004411H27.2C25.1512 -0.0647311 23.1586 0.681295 21.659 2.07895C20.1593 3.4766 19.2751 5.41183 19.2 7.46041V12.8044H3.2C2.35131 12.8044 1.53737 13.1416 0.937258 13.7417C0.337142 14.3418 0 15.1557 0 16.0044C0 16.8531 0.337142 17.667 0.937258 18.2671C1.53737 18.8673 2.35131 19.2044 3.2 19.2044H6.4V54.4044C6.4 56.9505 7.41143 59.3923 9.21178 61.1926C11.0121 62.993 13.4539 64.0044 16 64.0044H48C50.5461 64.0044 52.9879 62.993 54.7882 61.1926C56.5886 59.3923 57.6 56.9505 57.6 54.4044V19.2044H60.8C61.6487 19.2044 62.4626 18.8673 63.0627 18.2671C63.6629 17.667 64 16.8531 64 16.0044C64 15.1557 63.6629 14.3418 63.0627 13.7417C62.4626 13.1416 61.6487 12.8044 60.8 12.8044ZM25.6 44.8044C25.6 45.6531 25.2629 46.467 24.6627 47.0671C24.0626 47.6673 23.2487 48.0044 22.4 48.0044C21.5513 48.0044 20.7374 47.6673 20.1373 47.0671C19.5371 46.467 19.2 45.6531 19.2 44.8044V32.0044C19.2 31.1557 19.5371 30.3418 20.1373 29.7417C20.7374 29.1415 21.5513 28.8044 22.4 28.8044C23.2487 28.8044 24.0626 29.1415 24.6627 29.7417C25.2629 30.3418 25.6 31.1557 25.6 32.0044V44.8044ZM25.6 7.46041C25.6 6.94841 26.272 6.40441 27.2 6.40441H36.8C37.728 6.40441 38.4 6.94841 38.4 7.46041V12.8044H25.6V7.46041ZM44.8 44.8044C44.8 45.6531 44.4629 46.467 43.8627 47.0671C43.2626 47.6673 42.4487 48.0044 41.6 48.0044C40.7513 48.0044 39.9374 47.6673 39.3373 47.0671C38.7371 46.467 38.4 45.6531 38.4 44.8044V32.0044C38.4 31.1557 38.7371 30.3418 39.3373 29.7417C39.9374 29.1415 40.7513 28.8044 41.6 28.8044C42.4487 28.8044 43.2626 29.1415 43.8627 29.7417C44.4629 30.3418 44.8 31.1557 44.8 32.0044V44.8044Z"
                                                                  fill="#EFEFEF"/>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="flex"
                                                 style="margin-bottom: 10px; background: #0b76ba40; border-radius: 3px;">
                                                <button type="button" [className]="zone.mode == 'g' ? 'active' : ''"
                                                        (click)="zone.mode = 'g'">
                                                    Général
                                                </button>
                                                <button type="button" [className]="zone.mode == 't' ? 'active' : ''"
                                                        (click)="zone.mode = 't'">
                                                    Technicien
                                                </button>
                                                <button type="button" [className]="zone.mode == 'm' ? 'active' : ''"
                                                        (click)="zone.mode = 'm'">
                                                    Matériels
                                                </button>
                                            </div>
                                            <div *ngIf="zone.mode == 'g'"
                                                 style="display: flex; flex-direction: column; justify-content: space-between; height: calc(100vh - 410px);">
                                                <div>
                                                    <div class="option">
                                                        <div class="key subH">Nom de la zone</div>
                                                        <div class="value input">
                                                            <input placeholder="Parking, bureau, garage..."
                                                                   formControlName="zone_{{z}}"
                                                                   (change)="zoneNameEdit(z, 'zone_'+z); this.submitNewBooking(this.currentBookingId);"
                                                                   autocomplete="new-password"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div class="option">
                                                        <div class="key subH">Tarif horaire installation
                                                        </div>
                                                        <div style="flex-direction: row; align-items: center; display: flex;">
                                                            <!--                                                            <div class="value input" style="min-width: 120px;">-->
                                                            <!--                                                                <div class="imInput">-->
                                                            <!--                                                                    {{zone.duration % 60 == 0 ? zone.duration / 60 : zone.duration / 60 - 0.5}}-->
                                                            <!--                                                                    h {{zone.duration % 60 < 10 ? '0' + zone.duration % 60 : zone.duration % 60}}-->
                                                            <!--                                                                    mn-->
                                                            <!--                                                                </div>-->
                                                            <!--                                                            </div>-->
                                                            <div class="value input hourRate">
                                                                <input type="number"
                                                                       formControlName="priceH_{{z}}"
                                                                       (change)="calculateZonePrice(z); this.submitNewBooking(this.currentBookingId);"
                                                                       autocomplete="new-password"
                                                                />
                                                                <span>€/h</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="option" style="text-align: right">
                                                    <b>
                                                        Total
                                                        time {{zone.duration % 60 == 0 ? zone.duration / 60 : zone.duration / 60 - 0.5}}
                                                        h {{zone.duration % 60 < 10 ? '0' + zone.duration % 60 : zone.duration % 60}}
                                                        mn
                                                    </b>
                                                    <b>
                                                        Total {{zone.price | number : '1.2-2'}}€
                                                    </b>
                                                </div>
                                            </div>


                                            <div *ngIf="zone.mode == 't'">


                                                <div *ngFor="let agent of zone.agents; let i = index"
                                                     [attr.data-index]="i">
                                                    <div class="key subH bold">
                                                        Technicien {{i + 1}}
                                                    </div>
                                                    <div class="option">
                                                        <div class="flex">
                                                            <div class="value subH">
                                                                Date et heure
                                                            </div>
                                                            <button class="addT" *ngIf="i > 0" type="button"
                                                                    (click)="deleteAgent(z, i)">
                                                                <span style="font-size: 13px;">Supprimer</span>
                                                            </button>
                                                        </div>
                                                        <div class="value">
                                                            <input class="form-control" forma="yyyy-mm-dd" (dateSelect)="inputChangedAgent('zone_'+z+'_agent_'+i+'_id','zone_'+z+'_agent_'+i+'_date', 'zone_'+z+'_agent_'+i+'_hour', 'zone_'+z+'_agent_'+i+'_min', 'zone_'+z+'_agent_'+i+'_duration', z, i)"
                                                                                  name="dp" formControlName="zone_{{z}}_agent_{{i}}_date" style="width: 150px; opacity: 0;" ngbDatepicker dataformatas="" #date="ngbDatepicker" (focus)="date.toggle()">
                                                            <button class="dateBtn" (click)="date.toggle()">{{zonesTDateFake['zone_'+z+'_agent_'+i+'_id']}}</button>
<!--                                                            <mat-form-field style="width: 50%; text-align: center;">-->
<!--                                                                <input style="text-transform: capitalize;" matInput-->
<!--                                                                       [matDatepicker]="start"-->
<!--                                                                       (click)="start.open()"-->
<!--                                                                       formControlName="zone_{{z}}_agent_{{i}}_date"-->
<!--                                                                       [min]="today"-->
<!--                                                                       (dateChange)="inputChangedAgent('zone_'+z+'_agent_'+i+'_id','zone_'+z+'_agent_'+i+'_date', 'zone_'+z+'_agent_'+i+'_hour', 'zone_'+z+'_agent_'+i+'_min', 'zone_'+z+'_agent_'+i+'_duration', z, i); this.submitNewBooking(this.currentBookingId);"-->
<!--                                                                       autocomplete="new-password"-->
<!--                                                                />-->
<!--                                                                <mat-datepicker #start></mat-datepicker>-->
<!--                                                            </mat-form-field>-->
                                                            <mat-select formControlName="zone_{{z}}_agent_{{i}}_hour"
                                                                        class="timeHour"
                                                                        style="margin: 0 2px 0 5px; text-align: center;"
                                                                        (closed)="inputChangedAgent('zone_'+z+'_agent_'+i+'_id','zone_'+z+'_agent_'+i+'_date', 'zone_'+z+'_agent_'+i+'_hour', 'zone_'+z+'_agent_'+i+'_min', 'zone_'+z+'_agent_'+i+'_duration', z, i); this.submitNewBooking(this.currentBookingId);">
                                                                <mat-option value="00">00</mat-option>
                                                                <mat-option *ngFor="let number of hours"
                                                                            value="{{number < 10 ? '0'+number : number}}">{{number < 10 ? '0' + number : number}}</mat-option>
                                                            </mat-select>
                                                            :
                                                            <mat-select formControlName="zone_{{z}}_agent_{{i}}_min"
                                                                        class="timeHour"
                                                                        style="margin-left: 3px; text-align: center;"
                                                                        (closed)="inputChangedAgent('zone_'+z+'_agent_'+i+'_id','zone_'+z+'_agent_'+i+'_date', 'zone_'+z+'_agent_'+i+'_hour', 'zone_'+z+'_agent_'+i+'_min', 'zone_'+z+'_agent_'+i+'_duration', z, i); this.submitNewBooking(this.currentBookingId);">
                                                                <mat-option *ngFor="let number of minutes"
                                                                            value="{{number < 10 ? '0'+number : number}}">{{number < 10 ? '0' + number : number}}</mat-option>
                                                            </mat-select>
                                                        </div>
                                                    </div>

                                                    <div class="option products">
                                                        <div class="key subH">
                                                            Prénom
                                                        </div>
                                                        <div style="flex-direction: row; align-items: center; display: flex;">
                                                            <div class="value input" style="min-width: 120px;">
                                                                <mat-select formControlName="zone_{{z}}_agent_{{i}}_id"
                                                                            placeholder="Claude"
                                                                            (closed)="inputChangedAgent('zone_'+z+'_agent_'+i+'_id','zone_'+z+'_agent_'+i+'_date', 'zone_'+z+'_agent_'+i+'_hour', 'zone_'+z+'_agent_'+i+'_min', 'zone_'+z+'_agent_'+i+'_duration', z, i); this.submitNewBooking(this.currentBookingId);">
                                                                    <mat-option value="1">Artem</mat-option>
                                                                    <mat-option value="2">Julien</mat-option>
                                                                </mat-select>
                                                            </div>
                                                            <div class="value input" style="min-width: 120px;">
                                                                <mat-select
                                                                        formControlName="zone_{{z}}_agent_{{i}}_duration"
                                                                        (closed)="inputChangedAgent('zone_'+z+'_agent_'+i+'_id','zone_'+z+'_agent_'+i+'_date', 'zone_'+z+'_agent_'+i+'_hour', 'zone_'+z+'_agent_'+i+'_min', 'zone_'+z+'_agent_'+i+'_duration', z, i); this.submitNewBooking(this.currentBookingId);">
                                                                    <mat-option *ngFor="let number of durationOne"
                                                                                value="{{number}}">{{number % 60 == 0 ? number / 60 : number / 60 - 0.5}}
                                                                        h {{number % 60 < 10 ? '0' + number % 60 : number % 60}}
                                                                        mn
                                                                    </mat-option>
                                                                </mat-select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button *ngIf="i+1 == zone.agents.length" class="addT" type="button"
                                                            (click)="cloneAgent(z, zone.agents.length)">
                                                        <img style="width: 18px; height: 18px;"
                                                             src="assets/img/plus_white.svg"><span
                                                            style="font-size: 13px; font-weight: 500;">Ajouter un technicien</span>
                                                    </button>
                                                </div>

                                            </div>


                                            <div *ngIf="zone.mode == 'm'">
                                                <div class="option products">
                                                    <!--                                                    <div class="key subH">-->
                                                    <!--                                                        Matériels et équipements-->
                                                    <!--                                                    </div>-->
                                                    <div class="value dropDownInput prdkt"
                                                         *ngFor="let product of zone.products; let i = index"
                                                         [attr.data-index]="i">

                                                        <div class="flex actsMats">
                                                            Matériel {{+i + 1}}
                                                            <button *ngIf="i != 0" type="button"
                                                                    (click)="deleteProduct(z, i)">
                                                                <svg width="18" height="18" viewBox="0 0 91 91"
                                                                     fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M67.8822 64.3467L48.7904 45.2548L67.8822 26.163C68.8586 25.1866 68.8586 23.6038 67.8822 22.6274C66.9059 21.6511 65.323 21.6511 64.3467 22.6274L45.2548 41.7193L26.1629 22.6274C25.1866 21.6511 23.6037 21.6511 22.6274 22.6274C21.6511 23.6038 21.6511 25.1866 22.6274 26.163L41.7193 45.2548L22.6274 64.3467C21.6511 65.3231 21.6511 66.9059 22.6274 67.8822C23.6037 68.8586 25.1866 68.8586 26.1629 67.8822L45.2548 48.7904L64.3467 67.8822C65.323 68.8586 66.9059 68.8586 67.8822 67.8822C68.8586 66.9059 68.8586 65.3231 67.8822 64.3467Z"
                                                                          fill="#EFEFEF"/>
                                                                </svg>

                                                            </button>
                                                        </div>
                                                        <!--                                                            <svg class="userSettings"-->
                                                        <!--                                                                 style="width: 10px; transform: rotate(0);"-->
                                                        <!--                                                                 viewBox="0 0 32 8" fill="#fff"-->
                                                        <!--                                                                 xmlns="http://www.w3.org/2000/svg">-->
                                                        <!--                                                                <circle cx="28" cy="4" r="4" fill="#C4C4C4"/>-->
                                                        <!--                                                                <circle cx="16" cy="4" r="4" fill="#C4C4C4"/>-->
                                                        <!--                                                                <circle cx="4" cy="4" r="4" fill="#C4C4C4"/>-->
                                                        <!--                                                            </svg>-->
                                                        <!--                                                            <div class="dropDown" style="display: none;">-->
                                                        <!--                                                                <button type="button"-->
                                                        <!--                                                                        (click)="cloneProduct(z, zone.products.length)">-->
                                                        <!--                                                                    <img src="assets/img/duplicate.svg"> Ajouter-->
                                                        <!--                                                                </button>-->
                                                        <!--                                                                <button *ngIf="i != 0" type="button"-->
                                                        <!--                                                                        (click)="deleteProduct(z, i)">-->
                                                        <!--                                                                    <img src="assets/img/icon_delete.svg">-->
                                                        <!--                                                                    Supprimer-->
                                                        <!--                                                                </button>-->
                                                        <!--                                                            </div>-->
                                                        <div class="flex">
                                                            <div class="value input" style="min-width: 185px;">
                                                                <input formControlName="zone_{{z}}_product_{{i}}"
                                                                       placeholder="Agility, alarmes,…"
                                                                       (change)="inputChanged('zone_'+z+'_product_'+i,'zone_'+z+'_product_'+i+'_price', 'zone_'+z+'_product_'+i+'_qwt', z, i, 'duration_'+z); this.submitNewBooking(this.currentBookingId);"
                                                                       autocomplete="new-password"
                                                                       (input)="this.filter('zone_'+z+'_product_'+i)"
                                                                       (focus)="this.filter('zone_'+z+'_product_'+i)"
                                                                />
                                                                <ul style="display: none"
                                                                    [className]="'zone_'+z+'_product_'+i">
                                                                    <li *ngFor="let product of allProducts; let ii = index"
                                                                        [attr.data-index]="i">
                                                                        <button type="button"
                                                                                (click)="patchProduct('zone_'+z+'_product_'+i, product.name, 'zone_'+z+'_product_'+i+'_qwt', 'zone_'+z+'_product_'+i+'_price', product.price, z, i)">{{product.name}}{{product.name == 'Ce produit n’existe pas' ? '' : ''}}</button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="value input prix">
                                                                <input type="number"
                                                                       formControlName="zone_{{z}}_product_{{i}}_qwt"
                                                                       (change)="inputChanged('zone_'+z+'_product_'+i,'zone_'+z+'_product_'+i+'_price', 'zone_'+z+'_product_'+i+'_qwt', z, i, 'duration_'+z); this.submitNewBooking(this.currentBookingId);"
                                                                       autocomplete="new-password"
                                                                       (focus)="ifDelete0('zone_'+z+'_product_'+i+'_qwt')"
                                                                       (blur)="ifAdd0('zone_'+z+'_product_'+i+'_qwt')"
                                                                />
                                                                <span>Qt</span>
                                                            </div>
                                                            <div class="value input prix">
                                                                <input type="number"
                                                                       formControlName="zone_{{z}}_product_{{i}}_price"
                                                                       (change)="inputChanged('zone_'+z+'_product_'+i, 'zone_'+z+'_product_'+i+'_price', 'zone_'+z+'_product_'+i+'_qwt', z, i, 'duration_'+z); this.submitNewBooking(this.currentBookingId);"
                                                                       autocomplete="new-password"
                                                                       (focus)="ifDelete0('zone_'+z+'_product_'+i+'_price')"
                                                                       (blur)="ifAdd0('zone_'+z+'_product_'+i+'_price')"
                                                                />
                                                                <span>€</span>
                                                            </div>
                                                            <div class="value input prix">
                                                                <input style="border: none !important; background: transparent; font-weight: bold;" type="number" readonly [value]="+this.formNew.get('zone_'+z+'_product_'+i+'_price').value * +this.formNew.get('zone_'+z+'_product_'+i+'_qwt').value"
                                                                />
                                                                <span style="font-weight: bold;">€</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button class="addT" type="button"
                                                        (click)="cloneProduct(z, zone.products.length)">
                                                    <img style="width: 18px; height: 18px;"
                                                         src="assets/img/plus_white.svg"><span
                                                        style="font-size: 13px; font-weight: 500;">Ajouter</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="step step2" [@step2]="step2State">
                                <div class="box">
                                    <div class="flex">
                                        <div class="justNumber active"><span
                                                class="zoneN">2</span>
                                        </div>
                                        <h3>INFORMATION FACTURATION</h3>
                                    </div>
                                    <div class="option">
                                        <div class="value">
                                            <mat-radio-group aria-label="Select an option"
                                                             formControlName="pOrP"
                                                             (change)="this.submitNewBooking(this.currentBookingId)">
                                                <mat-radio-button value="1"
                                                                  (click)="business = 0; calculateFullPrice();">
                                                    Particulier
                                                </mat-radio-button>
                                                <mat-radio-button value="2"
                                                                  (click)="business = 1; calculateFullPrice();">
                                                    Professionnel
                                                </mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                    <div class="option" style="padding: 20px 0 0;">
                                        <div class="value">
                                            <mat-radio-group aria-label="Select an option"
                                                             formControlName="dOrF"
                                                             (change)="this.submitNewBooking(this.currentBookingId)">
                                                <mat-radio-button value="1">Devis</mat-radio-button>
                                                <mat-radio-button value="2">Facture</mat-radio-button>
                                            </mat-radio-group>
                                        </div>
                                    </div>
                                    <div *ngIf="business == 1" class="option" style="margin-top: 12px;">
                                        <div class="key subH">Nom de la société</div>
                                        <div class="value input">
                                            <input formControlName="companyName"
                                                   (change)="this.submitNewBooking(this.currentBookingId)"
                                                   placeholder="Nom de la société"
                                                   autocomplete="new-password"/>
                                        </div>
                                    </div>
                                    <div class="option" [style]="business == 0 ? 'margin-top: 12px' : ''">
                                        <div class="flex">
                                            <div>
                                                <div class="key subH">{{business == 0 ? 'Nom du client' : 'Nom du directeur'}}</div>
                                                <div class="value input">
                                                    <input formControlName="clientSurname"
                                                           (change)="this.submitNewBooking(this.currentBookingId)"
                                                           [placeholder]="business == 0 ? 'Durand' : 'Durand'"
                                                           autocomplete="new-password"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div class="key subH">{{business == 0 ? 'Prénom du client' : 'Prénom du directeur'}}</div>
                                                <div class="value input">
                                                    <input formControlName="clientName"
                                                           (change)="this.submitNewBooking(this.currentBookingId)"
                                                           [placeholder]="business == 0 ? 'Marie' : 'Marie'"
                                                           autocomplete="new-password"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="option">
                                        <div class="key subH">{{business == 0 ? 'Téléphone du client' : 'Numéro de téléphone de la société'}}</div>
                                        <div class="value input">
                                            <!--                                            <input formControlName="clientPhone"-->
                                            <!--                                                   (change)="this.submitNewBooking(this.currentBookingId)"-->
                                            <!--                                                   placeholder="+33 6 24 90 25 55"-->
                                            <!--                                                   autocomplete="new-password"/>-->


                                            <div [className]="!formNew.get('clientPhone').invalid && formNew.get('clientPhone').touched ? 'valid value input flag':'value input flag'">
                                                <mat-select formControlName="flag" (selectionChange)="flagChanged()"
                                                            [className]="formNew.get('clientPhone').invalid && formNew.get('clientPhone').touched ? 'invalid':''">
                                                    <mat-option value="fr"><img class="telflag"
                                                                                src="assets/img/flags/flag_fr.png"> +33
                                                    </mat-option>
                                                    <mat-option value="ru"><img class="telflag"
                                                                                src="assets/img/flags/flag_ru.png"> +7
                                                    </mat-option>
                                                    <mat-option value="usa"><img class="telflag"
                                                                                 src="assets/img/flags/flag_usa.png"> +1
                                                    </mat-option>
                                                </mat-select>
                                                <input class="input flagSelectInput" type="tel"
                                                       autocomplete="new-password"
                                                       formControlName="clientPhone"
                                                       mask="{{this.PhoneFlagsService.phoneMask}}"
                                                       placeholder="{{this.PhoneFlagsService.phonePlaceholder}}"
                                                       (change)="this.submitNewBooking(this.currentBookingId)"
                                                />
                                                <div class="validation"
                                                     *ngIf="formNew.get('clientPhone').invalid && formNew.get('clientPhone').touched">
                                                    <small *ngIf="formNew.get('clientPhone').errors">
                                                        {{ 'partner.Please add correct number' | translate }}
                                                    </small>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="option">
                                        <div class="key subH">{{business == 0 ? 'Email du client' : 'Email de la société'}}</div>
                                        <div class="value input">
                                            <input formControlName="clientEmail"
                                                   (change)="this.submitNewBooking(this.currentBookingId)"
                                                   placeholder="example@site.com"
                                                   autocomplete="new-password"
                                            />
                                            <div class="validation"
                                                 *ngIf="formNew.get('clientEmail').invalid && formNew.get('clientEmail').touched">
                                                <small *ngIf="formNew.get('clientEmail').errors.required">
                                                    {{ 'partner.Please enter eMail' | translate }}
                                                </small>
                                                <small *ngIf="formNew.get('clientEmail').errors.email">
                                                    {{ 'partner.Please enter correct eMail' | translate }}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="option">
                                        <div class="key subH">{{business == 0 ? 'Adresse du client' : 'Adresse de la société'}}</div>
                                        <div class="value input">
                                            <input ngx-google-places-autocomplete
                                                   formControlName="clientAddress"
                                                   autocomplete="new-password"
                                                   (onAddressChange)="handleAddressInFactureChange($event); this.submitNewBooking(this.currentBookingId);"
                                                   placeholder="11 avenue de Fabron, Nice, 06200"/>
                                        </div>
                                    </div>

                                    <div class="option" *ngIf="business == 1">
                                        <div class="key subH">Numéro de SIRET de la société</div>
                                        <div class="value input">
                                            <input formControlName="clientProfSiret"
                                                   (change)="this.submitNewBooking(this.currentBookingId)"
                                                   placeholder="48339332800024"
                                                   autocomplete="new-password"
                                            />
                                        </div>
                                    </div>
                                    <div class="option" *ngIf="business == 1">
                                        <div class="key subH">Numéro de TVA de la société</div>
                                        <div class="value input">
                                            <input formControlName="clientProfTva"
                                                   (change)="this.submitNewBooking(this.currentBookingId)"
                                                   placeholder="0123.456.789"
                                                   autocomplete="new-password"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="step step3" [@step3]="step3State">
                                <div class="box">
                                    <div class="flex">
                                        <div class="justNumber active"><span
                                                class="zoneN">3</span>
                                        </div>
                                        <h3>INFORMATION du CONTACT</h3>
                                    </div>
                                    <div class="option">
                                        <div class="flex">
                                            <div>
                                                <div class="key subH">{{business == 0 ? 'Nom du contact' : 'Nom du référent'}}</div>
                                                <div class="value input">
                                                    <input formControlName="clientContactSurname"
                                                           style="margin-top: 0;"
                                                           (change)="this.submitNewBooking(this.currentBookingId)"
                                                           placeholder='Durand'
                                                           autocomplete="new-password"
                                                           [className]="clientSurnameError == true ? 'invalid':''"
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <div class="key subH">{{business == 0 ? 'Prénom du contact' : 'Prénom du référent'}}</div>
                                                <div class="value input">
                                                    <input formControlName="clientContactName"
                                                           (change)="this.submitNewBooking(this.currentBookingId)"
                                                           placeholder='Marie'
                                                           autocomplete="new-password"
                                                           [className]="clientNameError == true ? 'invalid':''"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="option" *ngIf="business == 1">
                                        <div class="key subH">Fonction du référent de la société</div>
                                        <div class="value input">
                                            <input formControlName="clientProfRole"
                                                   (change)="this.submitNewBooking(this.currentBookingId)"
                                                   placeholder="Manager..."
                                                   autocomplete="new-password"
                                            />
                                        </div>
                                    </div>
                                    <div class="option">
                                        <div class="key subH">{{business == 0 ? 'Numéro de téléphone du contact' : 'Numéro de téléphone du référent'}}</div>
                                        <div class="value input">
                                            <!--                                            <input formControlName="clientContactPhone"-->
                                            <!--                                                   (change)="this.submitNewBooking(this.currentBookingId)"-->
                                            <!--                                                   placeholder="+33 6 12 73 99 31"-->
                                            <!--                                                   autocomplete="new-password"-->
                                            <!--                                            />-->

                                            <div [className]="!formNew.get('clientContactPhone').invalid && formNew.get('clientContactPhone').touched ? 'valid value input flag':'value input flag'">
                                                <mat-select formControlName="flag" (selectionChange)="flagChanged()"
                                                            [className]="formNew.get('clientContactPhone').invalid && formNew.get('clientContactPhone').touched  || clientPhoneError == true ? 'invalid':'valid'">
                                                    <mat-option value="fr"><img class="telflag"
                                                                                src="assets/img/flags/flag_fr.png"> +33
                                                    </mat-option>
                                                    <mat-option value="ru"><img class="telflag"
                                                                                src="assets/img/flags/flag_ru.png"> +7
                                                    </mat-option>
                                                    <mat-option value="usa"><img class="telflag"
                                                                                 src="assets/img/flags/flag_usa.png"> +1
                                                    </mat-option>
                                                </mat-select>
                                                <input class="input flagSelectInput" type="tel"
                                                       autocomplete="new-password"
                                                       formControlName="clientContactPhone"
                                                       mask="{{this.PhoneFlagsService.phoneMask}}"
                                                       placeholder="{{this.PhoneFlagsService.phonePlaceholder}}"
                                                       (change)="this.submitNewBooking(this.currentBookingId)"
                                                       [className]="clientPhoneError == true ? 'invalid':''"
                                                />
                                                <div class="validation"
                                                     *ngIf="formNew.get('clientContactPhone').invalid && formNew.get('clientContactPhone').touched">
                                                    <small *ngIf="formNew.get('clientContactPhone').errors">
                                                        {{ 'partner.Please add correct number' | translate }}
                                                    </small>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="option">
                                        <div class="key subH">{{business == 0 ? 'Email du contact' : 'Email du référent de la société'}}</div>
                                        <div class="value input">
                                            <input formControlName="clientContactEmail"
                                                   (change)="this.submitNewBooking(this.currentBookingId)"
                                                   placeholder="example@site.com"
                                                   autocomplete="new-password"
                                                   [className]="clientEmailError == true ? 'invalid':''"
                                            />
                                            <div class="validation"
                                                 *ngIf="formNew.get('clientContactEmail').invalid && formNew.get('clientContactEmail').touched">
                                                <small *ngIf="formNew.get('clientContactEmail').errors.required">
                                                    {{ 'partner.Please enter eMail' | translate }}
                                                </small>
                                                <small *ngIf="formNew.get('clientContactEmail').errors.email">
                                                    {{ 'partner.Please enter correct eMail' | translate }}
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="step step4" [@step4]="step4State">
                                <div class="box">
                                    <div class="flex">
                                        <div class="justNumber active"><span
                                                class="zoneN">4</span>
                                        </div>
                                        <h3>méthode de PAIEMENT</h3>
                                    </div>
                                    <div class="vFlex">
                                        <div class="flex">
                                            <div class="option" style="width: 70%;">
                                                <div class="value">
                                                    <mat-select formControlName="paymentMethod"
                                                                (closed)="this.submitNewBooking(this.currentBookingId)">
                                                        <mat-option
                                                                value="0">{{ 'booking.Payment method' | translate }}</mat-option>
                                                        <mat-option
                                                                value="1">{{ 'filters.Cash' | translate }}</mat-option>
                                                        <mat-option
                                                                value="2">{{ 'filters.Bank transfer' | translate }}</mat-option>
                                                        <mat-option
                                                                value="3">{{ 'filters.Credit card' | translate }}</mat-option>
                                                        <mat-option
                                                                value="4">{{ 'filters.PayPal' | translate }}</mat-option>
                                                        <mat-option
                                                                value="5">{{ 'filters.Check' | translate }}</mat-option>
                                                        <mat-option
                                                                value="6">{{ 'filters.Account' | translate }}</mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                            <div class="option">
                                                <div class="value input">
                                                    <input type="number" formControlName="price" readonly
                                                           (change)="this.submitNewBooking(this.currentBookingId)"
                                                           placeholder="{{ 'bookings.Price' | translate }}"
                                                           autocomplete="new-password"
                                                    />
                                                    <span style="position: absolute; right: 10px;">€</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="flex taxs grey">
                                                <span>TOTAL H.T</span>
                                                <span>:</span>
                                                <span *ngIf="this.formNew.get('price').value > 0">{{this.ht | number:'1.2-2'}}
                                                    €</span>
                                                <span *ngIf="this.formNew.get('price').value == 0">0.00€</span>
                                            </div>
                                            <div class="flex taxs">
                                                <span>TOTAL T.V.A ({{this.formNew.get('pOrP').value}}0%)</span>
                                                <span>:</span>
                                                <span *ngIf="this.formNew.get('price').value > 0">{{this.tva | number:'1.2-2'}}
                                                    €</span>
                                                <span *ngIf="this.formNew.get('price').value == 0">0.00€</span>
                                            </div>
                                            <div class="flex taxs">
                                                <span>TOTAL T.T.C</span>
                                                <span>:</span>
                                                <span *ngIf="this.formNew.get('price').value > 0">{{this.formNew.get('price').value | number:'1.2-2'}}
                                                    €</span>
                                                <span *ngIf="this.formNew.get('price').value == 0">0.00€</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="saveWrap">
                            <button *ngIf="booking" style="position: relative;"
                                    [className]="sentToSign ? 'btn' : 'btn grey'"
                                    type="button" (click)="sendToSign();">
                                <div class="load" *ngIf="showLoaderSign">
                                    <img class="loaderImg" src="assets/img/loader_ransparent.gif">
                                </div>
                                <div class="load" *ngIf="!showLoaderSign && booking.signed == 0">
                                    <img class="loaderImg" src="assets/img/pencil.svg">
                                </div>
                                <div class="load tick" *ngIf="!showLoaderSign && booking.signed == 1">
                                    <img class="loaderImg" src="assets/img/tick.svg">
                                </div>
                                Bon pour accord
                            </button>
                            <button class="btn grey" type="button" (click)="viewInvoiceNew();">Voir facture</button>
                            <button class="btn grey" type="button" (click)="viewCM();">Voir
                                contrat de maintenance
                            </button>
                            <button class="btn grey" type="button" (click)="scrollRight();" [disabled]="disabledLeft"><
                                Précédent
                            </button>
                            <button class="btn grey" type="button" (click)="scrollLeft();">Suivant ></button>
                            <button class="btn" type="button" style="position: relative;"
                                    (click)="this.submitNewBooking(this.currentBookingId)">
                                <div class="load" *ngIf="showLoader">
                                    <img class="loaderImg" src="assets/img/loader_blue.gif">
                                </div>
                                Enregistrer
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <!-- booking end-->
    </div>
</div>
