import {Component, OnInit, AfterViewInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Booking, BookingsService} from '../bookings.service';
import {SubdomainsService} from '../subdomain.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';
import {first, map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';

const moment = _rollupMoment || _moment;

export interface SatDatepickerRangeValue<D> {
    begin: D | null;
    end: D | null;
}

@Component({
    selector: 'app-bookings',
    templateUrl: './alarms.component.html',
    styleUrls: ['./alarms.component.css'],

    animations: [
        trigger('hidepreloader', [
            state('start', style({
                display: 'flex',
                opacity: 1
            })),
            state('end', style({
                display: 'none',
                opacity: 0
            })),
            transition('start => end', animate(500)),
            transition('end => start', animate(500))
        ])
    ]
})

export class AlarmsComponent implements OnInit {
    constructor(public bookingsService: BookingsService, private router: Router, private route: ActivatedRoute, private translateService: TranslateService, public SubdomainsService: SubdomainsService, private dateAdapter: DateAdapter<Date>,) {
        this.dateAdapter.setLocale('en');
        this.route.queryParams.subscribe(params => {
            console.log('params.update', params.update);
            if (params.update == 1) {
                // this.justClearFilters();
                this.getBookings();
            }
        });
    }

    form: FormGroup;
    bookings: Booking[] = [];
    hidepreloaderState = 'start';
    showFilterState = 'start';

    filterDateStart: any;
    filterDateEnd: any;

    areNeedUpddate = 0;
    dateRangeDisp = {begin: Date, end: Date};

    agent = new FormControl();
    options: string[] = [];
    filteredOptions: Observable<string[]>;


    // private _filter(value: string): string[] {
    //     const filterValue = value.toLowerCase();
    //     return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    // }

    // gettotalRevenue(bookings: Booking[]) {
    //   this.totalRevenue = '0';
    //   bookings.forEach(function(value) {
    //     this.totalRevenue = +this.totalRevenue + (+value.price);
    //   }.bind(this));
    // }

    ngOnInit() {
        this.SubdomainsService.isAdmin();
        if (this.SubdomainsService.subdomain != 'admin') {
            this.SubdomainsService.gotoDashboardPage();
        }

        this.SubdomainsService.aythWithAccessKey().subscribe(response => {
            console.log('Response aythWithAccessKey', response);
            if (response.status === 'ERROR') {
                this.SubdomainsService.gotoHomePage();
            }
            if (response.user_id > 0 && response.status === '1') {
                this.SubdomainsService.gotoConfirmCodePage();
            }
        });

        // this.form = new FormGroup({
        //     duration: new FormControl('0'),
        //     // agent: new FormControl(),
        //     client: new FormControl(),
        //     pickup: new FormControl(),
        //     payment: new FormControl('0'),
        // });

        this.getBookings();
        // this.getBodyguards();
    }

    // getBodyguards() {
    //     this.bookingsService.getBodyguards().subscribe(response => {
    //         console.log('Response bodyguards', response);
    //         this.options = response;
    //         this.filteredOptions = this.agent.valueChanges.pipe(
    //             startWith(''),
    //             map(value => this._filter(value))
    //         );
    //     });
    // }

    getBookings() {
        this.bookingsService.getAlarmBookings().subscribe(response => {
            console.log('Response bookings', response);
            this.bookings = response.bookings;
            if (response.status == 'ERROR' && response.error.code == 133) {
                this.getBookings();
            } else {
                setTimeout(() => {
                    this.animateLoader();
                }, 2500);
            }
        });
    }

    // saveDate(event: any) {
    //     console.log(event.target.value.begin._i);
    //     console.log(event.target.value.end._i);
    //     this.filterDateStart = event.target.value.begin._i;
    //     this.filterDateEnd = event.target.value.end._i;
    //     this.submit();
    //     this.dateRangeDisp = event.target.value;
    // }

    // submit() {
    //     const formData = {...this.form.value};
    //     console.log('Value - ', this.filterDateStart, this.filterDateEnd, formData, this.agent.value);
    //     let parameters = '';
    //     if (formData.duration != null) {
    //         parameters = parameters + '&duration=' + formData.duration;
    //     }
    //     if (formData.pickup != null) {
    //         parameters = parameters + '&pickup=' + formData.pickup;
    //     }
    //     if (this.agent.value != null) {
    //         parameters = parameters + '&agent=' + this.agent.value;
    //     }
    //     if (formData.client != null) {
    //         parameters = parameters + '&client=' + formData.client;
    //     }
    //     if (formData.payment != null) {
    //         parameters = parameters + '&payment=' + formData.payment;
    //     }
    //
    //     if (this.filterDateStart != null) {
    //         const syear = this.filterDateStart.year;
    //         let smonth = (this.filterDateStart.month + 1).toString();
    //         if (smonth.length == 1) {
    //             smonth = '0' + smonth;
    //         }
    //         let sdate = this.filterDateStart.date.toString();
    //         if (sdate.length == 1) {
    //             sdate = '0' + sdate;
    //         }
    //         console.log('startDate', syear + '-' + smonth + '-' + sdate);
    //         parameters = parameters + '&startDate=' + syear + '-' + smonth + '-' + sdate;
    //     }
    //
    //     if (this.filterDateEnd != null) {
    //         const syear = this.filterDateEnd.year;
    //         let smonth = (this.filterDateEnd.month + 1).toString();
    //         if (smonth.length == 1) {
    //             smonth = '0' + smonth;
    //         }
    //         let sdate = this.filterDateEnd.date.toString();
    //         if (sdate.length == 1) {
    //             sdate = '0' + sdate;
    //         }
    //         console.log('endDate', syear + '-' + smonth + '-' + sdate);
    //         parameters = parameters + '&endDate=' + syear + '-' + smonth + '-' + sdate;
    //     }
    //
    //     // this.bookingsService.getBookingsWithParameters(parameters).subscribe(response => {
    //     //     console.log('Response bookings', response);
    //     //     this.bookings = response;
    //     //     setTimeout(() => {
    //     //         this.animateLoader();
    //     //     }, 2500);
    //     // });
    // }

    // toggleFilters() {
    //     this.showFilterState = this.showFilterState === 'end' ? 'start' : 'end';
    // }

    // clearFilters() {
    //     this.form.patchValue({
    //         duration: '0',
    //         // agent: new FormControl(),
    //         client: '',
    //         pickup: '',
    //         payment: '0',
    //     });
    //     this.agent.patchValue('');
    //     this.dateRangeDisp = {begin: Date, end: Date};
    //     this.getBookings();
    // }

    // justClearFilters() {
    //     this.form.patchValue({
    //         duration: '0',
    //         // agent: new FormControl(),
    //         client: '',
    //         pickup: '',
    //         payment: '0',
    //     });
    //     this.agent.patchValue('');
    //     this.dateRangeDisp = {begin: Date, end: Date};
    // }

    animateLoader() {
        this.hidepreloaderState = 'end';
    }
}
