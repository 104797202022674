import {Component, HostListener, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {UsersService} from '../users.service';
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';
import {formatDate} from '@angular/common';
import {SubdomainsService} from '../subdomain.service';
import {Router} from '@angular/router';
import {FormControl, FormGroup} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MY_FORMATS} from '../booking/booking.component';
import {BookingsService} from '../bookings.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

const moment = _rollupMoment || _moment;

@Component({
    selector: 'app-topheader',
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },

        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
    template: `
        <div class="loader" [@hidepreloader]="hidepreloaderState">
            <img class="loaderImg" src="assets/img/loader_n.gif">
        </div>
        <div class="app-topheader">
            <div class="flex">
                <form class="filtersForm" [formGroup]="range">
                    <div class="now">

                        <!--                        <mat-form-field>-->
                        <!--                            <input matInput [matDatepicker]="start" readonly (click)="start.open()" (dateChange)="filterByDate()"-->
                        <!--                                   formControlName="start">-->
                        <!--                            <mat-datepicker #start></mat-datepicker>-->
                        <!--                        </mat-form-field>-->
                        <!--                        <span> - </span>-->
                        <!--                        <mat-form-field>-->
                        <!--                            <input matInput [matDatepicker]="end" readonly (click)="end.open()" (dateChange)="filterByDate()"-->
                        <!--                                   formControlName="end">-->
                        <!--                            <mat-datepicker #end></mat-datepicker>-->
                        <!--                        </mat-form-field>-->
                        <!--                        <button (click)="picker.open()">-->
                        <!--                        <button>-->
                        <!--                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">-->
                        <!--                                <path d="M27.1328 3.34375H24.4375V1.9375C24.4375 1.41977 24.0178 1 23.5 1C22.9822 1 22.5625 1.41977 22.5625 1.9375V3.34375H9.4375V1.9375C9.4375 1.41977 9.01779 1 8.5 1C7.98221 1 7.5625 1.41977 7.5625 1.9375V3.34375H4.86719C2.73479 3.34375 1 5.07854 1 7.21094V27.1328C1 29.2652 2.73479 31 4.86719 31H27.1328C29.2652 31 31 29.2652 31 27.1328V7.21094C31 5.07854 29.2652 3.34375 27.1328 3.34375ZM4.86719 5.21875H7.5625V6.15625C7.5625 6.67398 7.98221 7.09375 8.5 7.09375C9.01779 7.09375 9.4375 6.67398 9.4375 6.15625V5.21875H22.5625V6.15625C22.5625 6.67398 22.9822 7.09375 23.5 7.09375C24.0178 7.09375 24.4375 6.67398 24.4375 6.15625V5.21875H27.1328C28.2313 5.21875 29.125 6.11242 29.125 7.21094V9.4375H2.875V7.21094C2.875 6.11242 3.76867 5.21875 4.86719 5.21875ZM27.1328 29.125H4.86719C3.76867 29.125 2.875 28.2313 2.875 27.1328V11.3125H29.125V27.1328C29.125 28.2313 28.2313 29.125 27.1328 29.125Z"-->
                        <!--                                      fill="#848484" stroke="#848484" stroke-width="2"/>-->
                        <!--                            </svg>-->
                        <!--                        </button>-->
                        <!--                        <mat-form-field class="filterPeriod">-->
                        <!--                            <input matInput-->
                        <!--                                   [satDatepicker]="picker"-->
                        <!--                                   (click)="picker.open()"-->
                        <!--                                   readonly-->
                        <!--                                   [value]="dateRangeDisp"-->
                        <!--                                   formControlName="rng"-->
                        <!--                                   (dateChange)="filterByPeriod()"-->
                        <!--                            >-->
                        <!--                            <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>-->
                        <!--                        </mat-form-field>-->
                    </div>
                </form>
                <h2>{{firstPath}}</h2>
                <!--                <div class="search">-->
                <!--                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="#141618">-->
                <!--                        <path d="M13.7713 12.6671L11.1275 10.0311C11.9805 8.94432 12.4433 7.60234 12.4416 6.22081C12.4416 4.99045 12.0768 3.78772 11.3932 2.76471C10.7097 1.74171 9.73811 0.944372 8.60141 0.473534C7.46471 0.00269536 6.21391 -0.120497 5.00719 0.119534C3.80047 0.359565 2.69203 0.95204 1.82204 1.82204C0.95204 2.69203 0.359565 3.80047 0.119534 5.00719C-0.120497 6.21391 0.00269536 7.46471 0.473534 8.60141C0.944372 9.73811 1.74171 10.7097 2.76471 11.3932C3.78772 12.0768 4.99045 12.4416 6.22081 12.4416C7.60234 12.4433 8.94432 11.9805 10.0311 11.1275L12.6671 13.7713C12.7396 13.8438 12.8257 13.9013 12.9204 13.9406C13.0152 13.9798 13.1167 14 13.2192 14C13.3218 14 13.4233 13.9798 13.518 13.9406C13.6127 13.9013 13.6988 13.8438 13.7713 13.7713C13.8438 13.6988 13.9013 13.6127 13.9406 13.518C13.9798 13.4233 14 13.3218 14 13.2192C14 13.1167 13.9798 13.0152 13.9406 12.9204C13.9013 12.8257 13.8438 12.7396 13.7713 12.6671ZM1.5552 6.22081C1.5552 5.29804 1.82884 4.39599 2.3415 3.62874C2.85416 2.86148 3.58283 2.26348 4.43536 1.91035C5.28789 1.55722 6.22599 1.46483 7.13102 1.64485C8.03606 1.82488 8.86739 2.26923 9.51989 2.92173C10.1724 3.57423 10.6167 4.40556 10.7968 5.3106C10.9768 6.21563 10.8844 7.15373 10.5313 8.00626C10.1781 8.85879 9.58014 9.58746 8.81288 10.1001C8.04563 10.6128 7.14358 10.8864 6.22081 10.8864C4.98341 10.8864 3.7967 10.3949 2.92173 9.51989C2.04676 8.64492 1.5552 7.45821 1.5552 6.22081Z"-->
                <!--                              fill="#141618"/>-->
                <!--                    </svg>-->
                <!--                    <input style="border: none !important;" placeholder="Search something">-->
                <!--                </div>-->
            </div>
            <ul class="menu">
                <div class="scan flex" *ngIf="SubdomainsService.subdomain == 'admin'">
                    <button type="button" style="display: flex;" (click)="showScans = !showScans" id="showScans">
                        <img style="height: 30px" src="assets/img/icon_scan.svg" id="showScans">
                    </button>
                    <ul id="showScans" class="submenu" *ngIf="showScans == true"
                        style="color: #efefef; white-space: nowrap; top: 31px; right: -6px;">
                        <li id="showScans">{{scans}} new scan{{scans > 1 ? 's' : ''}}</li>
                    </ul>
                </div>
                <li *ngIf="SubdomainsService.subdomain != 'agent'">
                    <a [className]="newMessages == 0 ? 'link' : 'link alertPulse'">
                        <button type="button" class="dissmHover" style="display: flex; align-items: center;" (click)="showN = !showN"
                                id="showN">
                            <img class="iconPage" id="showN" src="assets/img/icon_notification.svg">
                        </button>
                    </a>
                    <ul class="submenu" id="showN" *ngIf="showN == true">
                        <a href="/chat" id="showN">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                 class="feather feather-mail">
                                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                <polyline points="22,6 12,13 2,6"></polyline>
                            </svg>
                            <span>{{newMessages}} new messages</span>
                        </a>
                        <a href="/users" id="showN">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                 class="feather feather-user-plus">
                                <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                <circle cx="8.5" cy="7" r="4"></circle>
                                <line x1="20" y1="8" x2="20" y2="14"></line>
                                <line x1="23" y1="11" x2="17" y2="11"></line>
                            </svg>
                            <span>{{newUsers}} new users</span>
                        </a>
                    </ul>
                </li>
                <li>
                    <a *ngIf="currentUser" class="link withHover">
                        <button type="button" class="dissmHover" style="display: flex; align-items: center;" (click)="showUD = !showUD"
                                id="showUDID">
                            <img style="height: 32px;" id="showUDID" src="assets/img/user_new.svg">
                            <div style="color: #efefef; font-size: 14px; font-weight: bold; margin-left: 7px; margin-top: 3px;"
                                 id="showUDID">{{currentUser.full_name}}</div>
                        </button>
                    </a>
                    <ul class="submenu" id="showUDID" *ngIf="showUD == true">
                        <li id="showUDID">
                            <div style="color: #efefef;" id="showUDID">
                                <div id="showUDID" *ngIf="currentUser" class="userIcon linkUserName">
                                    <img id="showUDID" *ngIf="!currentUser.image_id" src="assets/img/user_new.svg">
                                    <div id="showUDID" class="currentUserImg" *ngIf="currentUser.image_id">
                                        <div style="width: 64px; height: 64px; margin: 5px auto 7px; background-size: cover;"
                                             [style.background]="'url(https://1eyeonu.com/images/?id=' + currentUser.image_id + ')'"></div>
                                    </div>
                                    <div id="showUDID" *ngIf="currentUser"
                                         style="color: #efefef; font-size: 10px; font-weight: bold; text-transform: uppercase;">
                                        {{currentUser.full_name}}
                                    </div>
                                    <div id="showUDID" *ngIf="currentUser"
                                         style="font-size: 12px;">
                                        {{ currentUser.email }}
                                    </div>
                                </div>
                            </div>
                        </li>
                        <hr>
                        <li>
                            <a class="link" routerLink="/users/{{ currentUser.id }}">
                                <img src="assets/img/icon_edit_new.svg" id="showUDID">
                                <span id="showUDID">{{ 'menu.Profile' | translate }}</span>
                            </a>
                        </li>
                        <li>
                            <a class="link">
                                <button class="link" type="button" (click)="logout()">
                                    <img src="assets/img/icon_logout_new.svg">
                                    <span>{{ 'menu.Logout' | translate }}</span>
                                </button>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="lng">
                    <a class="change language">
                        <div class="currentLng">
                            <span *ngIf="this.currentLanguage == 'en' && intViewportWidth > 767">{{ 'languages.english' | translate }}</span>
                            <span *ngIf="this.currentLanguage == 'ru' && intViewportWidth > 767">{{ 'languages.russian' | translate }}</span>
                            <span *ngIf="this.currentLanguage == 'fr' && intViewportWidth > 767">{{ 'languages.france' | translate }}</span>
                            <span *ngIf="this.currentLanguage == 'en' && intViewportWidth < 768">en</span>
                            <span *ngIf="this.currentLanguage == 'ru' && intViewportWidth < 768">ru</span>
                            <span *ngIf="this.currentLanguage == 'fr' && intViewportWidth < 768">fr</span>
                            <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 9L13.9282 0.75H0.0717969L7 9Z" fill="white"></path>
                            </svg>
                        </div>
                        <div class="dropDownFlags" *ngIf="intViewportWidth > 767">
                            <button *ngIf="this.currentLanguage != 'en'"
                                    (click)="setLanguage('en')">{{ 'languages.english' | translate }}</button>
                            <button *ngIf="this.currentLanguage != 'fr'"
                                    (click)="setLanguage('fr')">{{ 'languages.france' | translate }}</button>
                            <button *ngIf="this.currentLanguage != 'ru'"
                                    (click)="setLanguage('ru')">{{ 'languages.russian' | translate }}</button>
                        </div>

                        <div class="dropDownFlags" *ngIf="intViewportWidth < 768">
                            <button *ngIf="this.currentLanguage != 'en'"
                                    (click)="setLanguage('en')">en
                            </button>
                            <button *ngIf="this.currentLanguage != 'fr'"
                                    (click)="setLanguage('fr')">fr
                            </button>
                            <button *ngIf="this.currentLanguage != 'ru'"
                                    (click)="setLanguage('ru')">ru
                            </button>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    `,
    styles: [`
        @media screen and (min-width: 476px) {
            .app-topheader {
                justify-content: space-between;
            }

            .search {
                display: flex;
            }
        }

        .lng a span {
            font-size: 14px !important;
        }

        a.link.alertPulse:before {
            background-color: #0b76ba;
            content: "";
            display: table;
            border-radius: 50%;
            position: absolute;
            width: 13px;
            height: 12px;
            right: 6px;
            top: 0px;
            z-index: 9;

            animation-name: pulse;
            animation-duration: .9s;
            animation-iteration-count: infinite;
            animation-timing-function: ease-out;
            display: none;
        }

        a.link.alertPulse:after {
            background-color: #0b76ba;
            content: "";
            display: table;
            border-radius: 50%;
            position: absolute;
            width: 8px;
            height: 8px;
            right: 6px;
            top: 0px;
            z-index: 9;
            display: none;
        }

        @keyframes pulse {
            0% {
                opacity: 1;
                width: 7px;
                height: 7px;
                right: 6px;
                top: 0px;
            }
            95% {
                opacity: 0.1;
                right: -4.5px;
                top: -10.5px;
                width: 28px;
                height: 28px;
            }
            100% {
                opacity: 0;
                width: 7px;
                height: 7px;
                right: 6px;
                top: 0px;
            }
        }

        #showN a {
            color: #efefef;
            white-space: nowrap;
        }

        .loader {
            width: 100% !important;
            padding-right: 0 !important;
        }

        .loader {
            height: 100%;
            position: fixed;
            background: #000;
            text-align: center;
            width: 100%;
            z-index: 10;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .currentLng span {
            color: #fff !important;
        }

        a.change.language svg {
            width: 8px;
            margin-left: 3px;
        }

        .userIcon > span > span {
            display: block;
            padding: 0 7px !important;
        }

        .userIcon.linkUserName {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }

        .userIcon > span {
            align-items: flex-end;
            display: flex;
            flex-direction: column;
        }

        .now {
            color: #fff;
            text-transform: capitalize;
            margin: 0 10px;
            /*width: 50px;*/
            display: flex;
            align-items: center;
        }

        .now button {
            background: transparent;
            border: none;
            padding: 3px;
        }

        .now button svg {
            height: 24px;
        }

        input {
            border: none;
            background: transparent;
            padding: 0 0 0 10px;
        }

        .flex {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .scan {
            margin: 0 6px;
            cursor: pointer;
        }

        .scan.flex span {
            color: #007BBE;
            background: #141618;
            padding: 3px 10px;
            margin-left: 12px;
            border-radius: 3px;
            text-transform: uppercase;
            font-size: 12px;
            display: none;
            position: absolute;
            white-space: nowrap;
            left: 12px;
            z-index: 6;
        }

        .scan.flex:hover span {
            display: block;
        }

        .scan.flex span:before {
            content: "";
            position: absolute;
            left: -9px;
            top: 7px;
            height: 6px;
            border-top: 5px solid transparent;
            border-right: 10px solid #141618;
            border-bottom: 5px solid transparent;
        }

        .scan.flex {
            position: relative;
        }

        ul.menu .link img {
            height: 32px;
            width: auto;
        }

        ul.menu li {
            position: relative;
        }

        .link span {
            position: absolute;
            top: 47px;
            background: #fff;
            padding: 5px 10px;
            border-radius: 3px;
            left: 0;
            display: none;
            white-space: nowrap;
        }

        .link:hover span {
            display: block;
        }

        .link {
            position: relative;
        }

        .link span:before {
            content: "";
            position: absolute;
            right: 24px;
            top: -10px;
            height: 6px;
            transform: rotate(90deg);
            border-top: 5px solid transparent;
            border-right: 10px solid white;
            border-bottom: 5px solid transparent;
        }

        .link svg {
            height: 22px;
            width: auto;
        }

        .link svg path {
            fill: #848484;
        }

        .link.active svg path {
            fill: #0a75ba;
        }

        button.link {
            background: transparent;
            color: #fff;
            border: none;
            display: flex;
            align-items: center;
            padding: 0;
            color: grey !important;
        }

        button.link:hover {
            opacity: 1;
            color: #0e75ba;
        }

        button.link span {
            top: 36px !important;
        }

        ul.submenu {
            position: absolute;
            display: block;
            background: #141618;
            border-radius: 3px;
            right: 0;
            box-shadow: 2px 2px 12px #00000070;
            border: 1px solid #007BBE;
            padding: 10px;
            margin-top: 16px;
            z-index: 9;
        }

        ul.submenu:before {
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 10px solid #007BBE;
            content: ' ';
            position: absolute;
            top: -10px;
            right: 13px;
            z-index: 9;
        }

        ul.submenu li {
            display: block;
        }

        ul.submenu li span {
            display: block !important;
            position: unset !important;
            background: #141618;
        }

        ul.submenu .link span:before {
            display: none;
        }

        ul.submenu li:first-child .link span:before {
            display: block !important;
            border-right: 10px solid #141618;
        }

        a.link.active {
            color: #0e75ba;
        }

        a.link {
            color: #848484;
        }

        .currentUserImg div {
            background-size: cover !important;
        }

        button {
            border: none;
            background: transparent;
            padding: 0;
            margin: 0;
        }

        li:hover span {
            color: #848484;
        }


        .app-topheader {
            width: 100%;
            position: fixed;
            top: 0;
            background: #141618;
            z-index: 7;
            padding: 10px 15px 10px 85px;
            border-bottom: 1px solid #32363F;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
        }

        li {
            margin: 0;
            padding: 0;
            display: inline-block;
        }

        .app-topheader a {
            padding: 0px 6px;
            display: inline-block;
            display: flex;
            align-items: center;
        }

        .search {
            background: aliceblue;
            padding: 6px 20px;
            border-radius: 5px;
            color: #141618;
            font-size: 14px;
            display: flex;
            align-items: center;
            margin: 0 10px;
        }

        .search img {
            padding: 0 12px 0 0;
        }

        a.change.language {
            position: relative;
        }

        .dropDownFlags {
            position: absolute;
            text-align: left;
            top: 25px;
            border-radius: 3px !important;
            background: #22232a;
            display: none;
            box-shadow: 5px 5px 5px #0000003d;
        }

        img.telflag {
            width: 20px;
            margin-right: 7px;
        }

        .dropDownFlags button {
            display: flex;
            align-items: center;
            width: 100px;
            background: transparent;
            border: none;
            padding: 5px 10px;
            color: #fff;
            justify-content: flex-start;
        }

        a.change.language:hover .dropDownFlags {
            display: block;
        }

        .loader img:last-child {
            width: 40px;
            margin-top: 12px;
        }

        .now button {
            display: flex;
            align-items: center;
        }

        .userIcon.linkUserName img {
            width: 64px;
            height: 64px;
            background: #24282a;
            margin: 5px 10px 7px;
        }

        ul.submenu a span {
            font-size: 14px;
            color: #efefef;
        }

        ul.submenu a img {
            height: 12px !important;
        }

        hr {
            background: #efefef;
            width: 90%;
            margin: 12px auto;
        }

        ul#showN a {
            display: flex;
            align-items: center;
            margin: 5px 0;
        }

        ul#showN a svg {
            width: 24px;
            height: 24px;
            margin-right: 7px;
            background: #0d75b9b8;
            padding: 5px;
            border-radius: 12px;
        }

        .mobLNG {
            display: none;
        }

        h2 {
            color: #efefef;
            font-size: 24px;
            text-transform: capitalize;
            margin: 0 20px 0 0;
        }

        @media screen and (max-width: 767px) {
            .search {
                display: none !important;
            }

            button#showUDID div {
                display: none;
            }

            /*li.lng {*/
            /*    display: none;*/
            /*}*/
            .mobLNG button {
                color: #efefef;
                margin: 5px 7px 0;
            }

            .mobLNG {
                display: flex;
                justify-content: center;
                margin: 5px auto 0;
                border-top: 1px solid #ffffff42;
            }
        }

        @media screen and (max-width: 475px) {
            .app-topheader {
                justify-content: space-between;
            }

            .search {
                display: none;
            }

            .dropDownFlags {
                left: 0;
            }

            .app-topheader a {
                padding: 5px !important;
            }

            img.logoSmall {
                height: 23px !important;
            }

            .scan {
                display: none !important;
            }

            .currentLng {
                white-space: nowrap;
            }

            .dropDownFlags {
                top: 30px;
                right: 5px;
                text-align: center;
            }
        }

        @media screen and (max-width: 374px) {
            .app-topheader {
                padding: 10px 15px 10px 55px
            }

            h2 {
                font-size: 18px;
                margin: 0 10px 0 0;
            }
        }
    `],
    animations: [
        trigger('hidepreloader', [
            state('start', style({
                display: 'flex',
                opacity: 1
            })),
            state('end', style({
                display: 'none',
                opacity: 0
            })),
            transition('start => end', animate(300)),
            transition('end => start', animate(10))
        ])
    ],
})
export class TopheaderComponent implements OnInit {
    constructor(private _adapter: DateAdapter<any>, private bookingsService: BookingsService, public router: Router, private UsersService: UsersService, private translateService: TranslateService, private cookieService: CookieService, public SubdomainsService: SubdomainsService) {
    }

    intViewportWidth = window.innerWidth;
    firstPath = window.location.pathname.split('/')[1];
    currentUser: any;
    isft = '0';
    currentLanguage = 'fr';
    userId = localStorage.getItem('user_id');
    today = new Date();
    now = new Date();
    showUD = false;
    showScans = false;
    showN = false;

    newMessages = 0;

    scans = 0;
    newUsers = 0;

    dateRangeDisp = {begin: new Date, end: new Date};

    range: FormGroup;
    hidepreloaderState = 'start';

    getAllNewMessages() {
        fetch('https://1eyeonu.com/APIv2/?action=getAllNewMessages', {
            method: 'GET',
            headers: {
                authorization: localStorage.getItem('access_key')
            },
        })
            .then(response => response.json())
            .then(responseJson => {
                // console.log('getAllNewMessages', responseJson);
                this.newMessages = responseJson.count;
                this.cookieService.set('new_messages', responseJson.count, 6480000);
            })
            .catch(error => {
                console.log('upload error', error);
            });

        setTimeout(() => {
            this.getAllNewMessages();
        }, 5000);
    }

    ngOnInit() {
        this.getAllNewMessages();
        this.isft = this.cookieService.get('ft');
        setTimeout(() => {
            this.animateLoader('end');
            this.cookieService.set('ft', '1', 3600);
        }, 2500);
        this.range = new FormGroup({
            start: new FormControl(moment.utc()),
            end: new FormControl(moment.utc()),
            rng: new FormControl({begin: moment.utc(), end: moment.utc()})
        });
        this.setCalendarLocale(this.cookieService.get('selectedLng'));
        if (this.cookieService.get('selectedLng')) {
            this.translateService.setDefaultLang(this.cookieService.get('selectedLng'));
            this.currentLanguage = this.cookieService.get('selectedLng');
        } else {
            this.translateService.setDefaultLang('fr');
        }
        if (localStorage.getItem('access_key') != null) {
            this.UsersService.getCurrentUserInfo().subscribe(response => {
                this.currentUser = response.user_info;
                if (!response.user_info) {
                    this.SubdomainsService.gotoHomePage();
                }
                this.scans = response.scans;
                this.newUsers = response.newUsers;
                this.cookieService.set('new_users', response.newUsers, 6480000);

            });
        } else {
            this.SubdomainsService.gotoHomePage();
        }
    }

    filterByPeriod() {
        this.animateLoader('start');
        const formData = {...this.range.value};
        console.log('filterByDate', formData.rng);
        // begin end
        setTimeout(() => {
            let parameters = '';
            parameters = parameters + '&startDate=' + moment(formData.rng.begin).format('YYYY-MM-DD');
            parameters = parameters + '&endDate=' + moment(formData.rng.end).add(1, 'day').format('YYYY-MM-DD');
            this.bookingsService.getBookingsWithParameters(parameters).subscribe(response => {
                console.log('Response bookings', response);
                this.bookingsService.bookings = response;
                setTimeout(() => {
                    this.animateLoader('end');
                }, 1500);
            });
        }, 200);
    }

    filterByDate() {
        this.animateLoader('start');
        let formData = {...this.range.value};
        console.log('filterByDate', formData.start._d, formData.end._d);
        if (formData.start._d > formData.end._d) {
            console.log('wrong_date');
            this.range.patchValue({
                end: moment(formData.start._d)
            });
            setTimeout(() => {
                formData = {...this.range.value};
            }, 100);
        }
        setTimeout(() => {
            let parameters = '';
            console.log('filterByDate', formData.start._d, formData.end._d);
            console.log('startDate', moment(formData.start._d).format('YYYY-MM-DD'));
            parameters = parameters + '&startDate=' + moment(formData.start._d).format('YYYY-MM-DD');
            parameters = parameters + '&endDate=' + moment(formData.end._d).add(1, 'day').format('YYYY-MM-DD');

            this.bookingsService.getBookingsWithParameters(parameters).subscribe(response => {
                console.log('Response bookings', response);
                this.bookingsService.bookings = response;
                setTimeout(() => {
                    this.animateLoader('end');
                }, 1500);
            });
        }, 200);
    }

    animateLoader(way) {
        this.hidepreloaderState = way;
    }

    setCalendarLocale(locale) {
        // switch (locale) {
        //     case 'fr':
        //         this._adapter.setLocale('fr_FR');
        //         break;
        //     case 'ru':
        //         this._adapter.setLocale('ru_RU');
        //         break;
        //     default:
        //         this._adapter.setLocale('en_EN');
        //         break;
        // }
        this._adapter.setLocale('fr_FR');
    }

    setLanguage(languageCode: string) {
        this.translateService.use(languageCode);
        this.cookieService.set('selectedLng', languageCode, 604800);
        this.currentLanguage = languageCode;
        this.setCalendarLocale(languageCode);
    }

    logout() {
        localStorage.removeItem('access_key');
        localStorage.removeItem('auth_key');
        localStorage.removeItem('user_id');
        setTimeout(() => {
            this.SubdomainsService.gotoHomePage();
        }, 50);
    }

    @HostListener('document:click', ['$event'])
    onClick(event: Event) {
        console.log((event.target as Element).id);
        if ((event.target as Element).id != 'showUDID') {
            this.showUD = false;
        }
        if ((event.target as Element).id != 'showScans') {
            this.showScans = false;
        }
        if ((event.target as Element).id != 'showN') {
            this.showN = false;
        }
    }
}
