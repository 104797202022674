<div class="loader" [@hidepreloader]="hidepreloaderState">
    <img class="loaderImg" src="assets/img/loader.gif">
</div>
<div class="table">
    <div class="loginFormWrapper cfc loginFormWrapperHome">
        <a class="change language">
            <div class="currentLng" style="text-align: right;">
                <span *ngIf="this.currentLanguage == 'en'">{{ 'languages.english' | translate }}</span>
                <span *ngIf="this.currentLanguage == 'ru'">{{ 'languages.russian' | translate }}</span>
                <span *ngIf="this.currentLanguage == 'fr'">{{ 'languages.france' | translate }}</span>
                <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 9L13.9282 0.75H0.0717969L7 9Z" fill="white"></path>
                </svg>
            </div>
            <div class="dropDownFlags">
                <button *ngIf="this.currentLanguage != 'en'"
                        (click)="setLanguage('en')">{{ 'languages.english' | translate }}</button>
                <button *ngIf="this.currentLanguage != 'fr'"
                        (click)="setLanguage('fr')">{{ 'languages.france' | translate }}</button>
                <button *ngIf="this.currentLanguage != 'ru'"
                        (click)="setLanguage('ru')">{{ 'languages.russian' | translate }}</button>
            </div>
        </a>
        <img src="assets/img/logo_1eyeonu_t.svg">
        <p>{{ 'confirmation.We sent sms to your phone number' | translate }} +{{userPhoneFormated}}</p>
        <p style="margin-top: 10px;">{{ 'confirmation.Vous navez' | translate }}? <button class="resend" type="button" (click)="resend()">{{ 'confirmation.' + buttonText | translate }}{{buttonTextTimer}}</button></p>
        <form class="loginFormConfirmation" [formGroup]="formConf" (ngSubmit)="submit()">
<!--            <div class="form-control">-->
<!--                <input type="text" maxlength="1" (focus)="wrongCode = 0"-->
<!--                       [style.borderColor]="wrongCode == 1 ? 'red' : ''" formControlName="num1" #num1-->
<!--                       (ngModelChange)="num2.focus()">-->
<!--                <input type="text" maxlength="1" (focus)="wrongCode = 0"-->
<!--                       [style.borderColor]="wrongCode == 1 ? 'red' : ''" formControlName="num2" #num2-->
<!--                       (ngModelChange)="num3.focus()">-->
<!--                <input type="text" maxlength="1" (focus)="wrongCode = 0"-->
<!--                       [style.borderColor]="wrongCode == 1 ? 'red' : ''" formControlName="num3" #num3-->
<!--                       (ngModelChange)="num4.focus()">-->
<!--                <input type="text" maxlength="1" (focus)="wrongCode = 0"-->
<!--                       [style.borderColor]="wrongCode == 1 ? 'red' : ''" formControlName="num4" #num4-->
<!--                       (ngModelChange)="submit()">-->
<!--            </div>-->
            <div [class]="wrongCode == 1 ? 'red form-control' : 'form-control'">
                <code-input
                        #code
                        initialFocusField="0"
                        (codeChanged)="onCodeChanged()"
                        [codeLength]="4"
                        (codeCompleted)="onCodeCompleted($event)">
                </code-input>
            </div>
            <p *ngIf="wrongCode == 1" class="red">{{ 'confirmation.Incorrect verification code' | translate }}</p>
            <button class="btn" type="submit">{{ 'confirmation.Confirm' | translate }}</button>
        </form>
        <button class="cancelReg" type="button"
                (click)="cancelReg()">{{ 'confirmation.Cancel registration' | translate }}</button>
    </div>
</div>

