import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthResponse} from '../interfaces';
import {PhoneFlagsService} from '../phoneFlags.service';
import {SubdomainsService} from '../subdomain.service';
import {TranslateService} from '@ngx-translate/core';
import {first} from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
})

export class HomeComponent implements OnInit {
    constructor(private translateService: TranslateService, private cookieService: CookieService, private router: Router, private activatedRoute: ActivatedRoute, private http: HttpClient, public PhoneFlagsService: PhoneFlagsService, public SubdomainsService: SubdomainsService) {
    }

    currentLanguage = 'fr';
    mode = 'login';
    form: FormGroup;
    formRegistration: FormGroup;
    formRegistrationA: FormGroup;
    checkboxClass = '';
    emailReservedState = false;
    emailReserved: string;
    phonelReservedState = false;
    phoneReserved: string;

    passwordNotSame = false;

    notRegisteredError = false;
    wrongPasswordError = false;
    lang = 'fr';

    passwordMode = 'password';

    session = this.randomString(32);
    role = null;

    ngOnInit() {
        if (this.cookieService.get('selectedLng')) {
            this.setLanguage(this.cookieService.get('selectedLng'));
            this.currentLanguage = this.cookieService.get('selectedLng');
        } else {
            this.setLanguage('fr');
            this.currentLanguage = 'fr';
        }

        this.activatedRoute.queryParams.subscribe(params => {
            const lng = params['lng'];
            if (lng.length > 0) {
                this.translateService.setDefaultLang(lng);
                this.setLanguage(lng);
                this.lang = lng;
                this.currentLanguage = lng;
            }
        });

        if (this.SubdomainsService.subdomain == 'agent') {
            this.role = 2;
        } else if (this.SubdomainsService.subdomain == 'pro') {
            this.role = 4;
        } else if (this.SubdomainsService.subdomain == 'admin') {
            this.role = 3;
        }
        this.SubdomainsService.aythWithAccessKey().subscribe(response => {
            console.log('Response aythWithAccessKey', response);
            if (response.user_id > 0 && response.status === '2') {
                this.SubdomainsService.gotoBookingsPage();
            }
            if (response.user_id > 0 && response.status === '1') {
                this.SubdomainsService.gotoConfirmCodePage();
            }
        });

        this.form = new FormGroup({
            email: new FormControl('', [Validators.email, Validators.required]),
            password: new FormControl('', [Validators.required, Validators.minLength(6)])
        });
        this.formRegistration = new FormGroup({
            name: new FormControl('', [Validators.required]),
            surname: new FormControl('', [Validators.required]),
            flag: new FormControl('fr', [Validators.required]),
            phone: new FormControl('', [Validators.required]),
            companyName: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.email, Validators.required]),
            password: new FormControl('', [Validators.required, Validators.minLength(6)]),
            confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
            // agreed: new FormControl('', [Validators.required]),
        });
        this.formRegistrationA = new FormGroup({
            name: new FormControl('', [Validators.required]),
            surname: new FormControl('', [Validators.required]),
            flag: new FormControl('fr', [Validators.required]),
            phone: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.email, Validators.required]),
            promocode: new FormControl(''),
            password: new FormControl('', [Validators.required, Validators.minLength(6)]),
            confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
            // agreed: new FormControl('', [Validators.pattern('true')]),
        });
        console.log(this.session);
        console.log('subdomain', this.SubdomainsService.subdomain);
    }

    onClickCheckbox() {
        this.checkboxClass = this.checkboxClass === 'active' ? '' : 'active';
        console.log(this.checkboxClass);
    }

    // login: this.form.value.email,
    // password: this.form.value.password,

    private randomString(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    setLanguage(languageCode: string) {
        this.translateService.use(languageCode);
        // this.translateService.get('info.about').pipe(first()).subscribe((value) => console.log(value));
        this.lang = languageCode;
        this.cookieService.set('selectedLng', languageCode, 604800);
    }

    auth(login, password, role): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=auth', {
            login,
            password,
            role,
        }, {
            headers: new HttpHeaders({
                authorization: this.session
            })
        });
    }

    registerPartner(name, surname, phone, email, companyName, password, lng = null): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=registerPartner', {
            name,
            surname,
            phone,
            email,
            companyName,
            password,
            lng,
        }, {
            headers: new HttpHeaders({
                authorization: this.session
            })
        });
    }

    registerAgent(name, surname, phone, email, promocode, password, lng = null): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=registerAgent', {
            name,
            surname,
            phone,
            email,
            promocode,
            password,
            lng,
        }, {
            headers: new HttpHeaders({
                authorization: this.session
            })
        });
    }

    flagChanged() {
        const formData = {...this.formRegistration.value};
        console.log('formData', formData.flag);
        this.PhoneFlagsService.setFlag(formData.flag);
    }

    flagChangedA() {
        const formData = {...this.formRegistrationA.value};
        console.log('formData', formData.flag);
        this.PhoneFlagsService.setFlag(formData.flag);
    }

    submit() {
        if (this.form.invalid) {
            return;
        }
        console.log('Form submitted: ', this.form);
        const formData = {...this.form.value};
        console.log('Value - ', formData);
        this.auth(this.form.value.email, this.form.value.password, this.role).subscribe(response => {
            console.log('Response auth', response);
            if (response.result === '1') {

                localStorage.setItem('access_key', this.session);
                localStorage.setItem('auth_key', response.access_key);
                localStorage.setItem('user_id', response.user_id);
                if (response.user_id > 0 && response.status === '1') {
                    this.SubdomainsService.gotoConfirmCodePage();
                } else {
                    this.SubdomainsService.gotoBookingsPage();
                }
            } else if (response.status === 'ERROR') {
                if (response.error.code === 129) {
                    this.notRegisteredError = true;
                }
                if (response.error.code === 130) {
                    this.wrongPasswordError = true;
                }
            }
        });
    }

    submitRegistration() {
        if (this.formRegistration.invalid) {
            return;
        }
        console.log('Form submitted: ', this.formRegistration);
        const formData = {...this.formRegistration.value};

        if (formData.password !== formData.confirmPassword) {
            console.log('Value - ', formData.password, formData.confirmPassword);
            this.passwordNotSame = true;
            return;
        }
        console.log('Value - ', formData);
        const phone = this.PhoneFlagsService.phoneDialcode + formData.phone;
        this.registerPartner(formData.name, formData.surname, phone, formData.email, formData.companyName, formData.password, this.lang).subscribe(response => {
            console.log('Response auth', response);
            if (response.result === '1') {
                localStorage.setItem('access_key', this.session);
                localStorage.setItem('auth_key', response.access_key);
                localStorage.setItem('user_id', response.user_id);
                localStorage.setItem('phone', phone);

                this.SubdomainsService.gotoConfirmCodePage();
            } else if (response.status == 'ERROR') {
                if (response.error.code == 125) {
                    this.phoneReserved = response.error.text;
                    this.phonelReservedState = true;
                }
                if (response.error.code == 124) {
                    this.emailReserved = response.error.text;
                    this.emailReservedState = true;
                }
            }
        });
    }

    submitRegistrationA() {
        if (this.formRegistrationA.invalid) {
            return;
        }
        console.log('Form submitted: this.lang', this.lang);
        const formData = {...this.formRegistrationA.value};

        if (formData.password !== formData.confirmPassword) {
            console.log('Value - ', formData.password, formData.confirmPassword);
            this.passwordNotSame = true;
            return;
        }
        console.log('Value - ', formData);
        const phone = this.PhoneFlagsService.phoneDialcode + formData.phone;
        this.registerAgent(formData.name, formData.surname, phone, formData.email, formData.promocode, formData.password, this.lang).subscribe(response => {
            console.log('Response auth', response);
            if (response.result === '1') {
                localStorage.setItem('access_key', this.session);
                localStorage.setItem('auth_key', response.access_key);
                localStorage.setItem('user_id', response.user_id);
                localStorage.setItem('phone', phone);

                this.SubdomainsService.gotoConfirmCodePage();
            } else if (response.status == 'ERROR') {
                if (response.error.code == 125) {
                    this.phoneReserved = response.error.text;
                    this.phonelReservedState = true;
                }
                if (response.error.code == 124) {
                    this.emailReserved = response.error.text;
                    this.emailReservedState = true;
                }
            }
        });
    }
}
