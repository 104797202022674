import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {first} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {SubdomainsService} from '../subdomain.service';
import {CookieService} from 'ngx-cookie-service';

@Component({
    selector: 'app-confirmcode',
    templateUrl: './confirmCode.component.html',
    styleUrls: ['./confirmCode.component.css'],
    animations: [
        trigger('hidepreloader', [
            state('start', style({
                display: 'flex',
                opacity: 1
            })),
            state('end', style({
                display: 'none',
                opacity: 0
            })),
            transition('start => end', animate(500))
        ])
    ],
})

export class ConfirmCodeComponent implements OnInit {
    constructor(private translateService: TranslateService, private router: Router, private http: HttpClient, public SubdomainsService: SubdomainsService, private cookieService: CookieService) {
    }

    hidepreloaderState = 'start';
    buttonText = 'Resend message';
    buttonTextTimer = '';
    timer = 30;
    interval: any;
    currentLanguage = 'fr';
    lang = 'fr';
    wrongCode = 0;
    userPhone = '';
    userPhoneFormated = '';
    @ViewChild('num1') num1: ElementRef;
    @ViewChild('num2') num2: ElementRef;
    @ViewChild('num3') num3: ElementRef;
    @ViewChild('num4') num4: ElementRef;

    @ViewChild('code') code;

    formConf: FormGroup;

    ngOnInit() {
        this.userPhone = localStorage.getItem('phone').slice(1);
        setTimeout(() => {
            if (this.userPhone?.length > 0) {
                if (this.userPhone.substr(0, 1) == '7') {
                    this.userPhoneFormated = this.userPhone.substr(0, 1) + ' ' + this.userPhone.substr(1, 3) + ' ' + this.userPhone.substr(4, 3) + '-' + this.userPhone.substr(7, 2) + '-' + this.userPhone.substr(9, 2);
                } else if (this.userPhone.substr(0, 2) == '33') {
                    this.userPhoneFormated = this.userPhone.substr(0, 2) + ' ' + this.userPhone.substr(2, 1) + ' ' + this.userPhone.substr(3, 2) + ' ' + this.userPhone.substr(5, 2) + ' ' + this.userPhone.substr(7, 2) + ' ' + this.userPhone.substr(9, 2);
                } else if (this.userPhone.substr(0, 1) == '1') {
                    this.userPhoneFormated = this.userPhone.substr(0, 1) + ' ' + this.userPhone.substr(1, 3) + ' ' + this.userPhone.substr(4, 3) + ' ' + this.userPhone.substr(7, 4);
                } else {
                    this.userPhoneFormated = this.userPhone;
                }
            }
        }, 500);
        this.formConf = new FormGroup({
            num1: new FormControl('', [Validators.required]),
            num2: new FormControl('', [Validators.required]),
            num3: new FormControl('', [Validators.required]),
            num4: new FormControl('', [Validators.required]),
        });
        setTimeout(() => {
            this.animateLoader();
        }, 500);
        setTimeout(() => {
            this.num1.nativeElement.focus();
        }, 10);

        if (this.cookieService.get('selectedLng')) {
            this.translateService.setDefaultLang(this.cookieService.get('selectedLng'));
            this.currentLanguage = this.cookieService.get('selectedLng');
        } else {
            this.translateService.setDefaultLang('fr');
        }
    }

    setLanguage(languageCode: string) {
        this.translateService.use(languageCode);
        this.cookieService.set('selectedLng', languageCode, 604800);
        this.translateService.get('info.about').pipe(first()).subscribe((value) => console.log(value));
        this.lang = languageCode;
        this.currentLanguage = languageCode;
    }

    animateLoader() {
        this.hidepreloaderState = 'end';
    }

    onCodeChanged() {
        // this.wrongCode = 0;
    }

    submit() {
        setTimeout(() => {
            if (this.formConf.invalid) {
                return;
            }
            this.hidepreloaderState = 'start';
            console.log('Form submitted: ', this.formConf);
            const formData = {...this.formConf.value};
            console.log('Value - ', formData);

            this.confirmUserBySms(formData.num1 + formData.num2 + formData.num3 + formData.num4).subscribe(response => {
                //this.confirmUserBySms(formData.num1).subscribe(response => {
                console.log('Response sms confirm', response);
                if (response.status === 1) {
                    this.SubdomainsService.gotoDashboardPage();
                } else {
                    this.wrongCode = 1;
                }
                this.hidepreloaderState = 'end';
            });
        }, 300);
    }

    onCodeCompleted(code: string) {
        console.log('code', code);
        this.hidepreloaderState = 'start';
        this.confirmUserBySms(code).subscribe(response => {
            console.log('Response sms confirm', response);
            if (response.status === 1) {
                this.SubdomainsService.gotoDashboardPage();
            } else {
                this.wrongCode = 1;
                this.code.reset();
            }
            this.hidepreloaderState = 'end';
        });
    }

    cancelReg() {
        this.cancelPRegistration().subscribe(response => {
            console.log('Response cancelPRegistration', response);
            if (response) {
                localStorage.removeItem('access_key');
                localStorage.removeItem('auth_key');
                localStorage.removeItem('user_id');
                setTimeout(() => {
                    this.SubdomainsService.gotoHomePage();
                }, 50);
            }
        });
    }

    resend() {
        this.buttonText = 'Sending...';
        this.resendMessage().subscribe(response => {
            if (response.status === 1) {
                // this.buttonText = 'Sent';
                this.buttonText = '';
                this.timer = 30;
                this.buttonTextTimer = this.timer + ' sec';
                this.interval = setInterval(
                    () => {
                        this.timer = this.timer - 1;
                        this.buttonTextTimer = this.timer + ' sec';
                    },
                    1000);
                setTimeout(() => {
                    this.buttonText = 'Resend message';
                    this.buttonTextTimer = '';
                    clearInterval(this.interval);
                }, 30000);
            }
        });
        this.code.reset();
    }

    resendMessage(): Observable<any> {
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=resendMessage', {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    cancelPRegistration(): Observable<any> {
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=cancelPartnerRegistration', {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    confirmUserBySms(code): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=confirmUserBySms', {
            code,
        }, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }
}

