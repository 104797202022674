import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {User, UsersService} from '../users.service';
import {PhoneFlagsService} from '../phoneFlags.service';
import {SubdomainsService} from '../subdomain.service';

import {ActivatedRoute, Params, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {formatDate} from '@angular/common';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {CountryISO, PhoneNumberFormat, SearchCountryField} from 'ngx-intl-tel-input';
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {TranslateService} from '@ngx-translate/core';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'MMM DD, YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'll',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-users',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.css'],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },

        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
    animations: [
        trigger('hidepreloader', [
            state('start', style({
                display: 'flex',
                opacity: 1
            })),
            state('end', style({
                display: 'none',
                opacity: 0
            })),
            transition('start => end', animate(500)),
            transition('end => start', animate(10))
        ]),
        trigger('upload', [
            state('start', style({
                width: 0,
            })),
            state('end', style({
                width: '100%',
            })),
            transition('start => end', animate(1500))
        ])
    ],
})


export class UserComponent implements OnInit {
    constructor(private UsersService: UsersService, private translate: TranslateService, public PhoneFlagsService: PhoneFlagsService, private router: Router, private route: ActivatedRoute, public SubdomainsService: SubdomainsService) {
        this.form = new FormGroup({
            role: new FormControl('', [Validators.required]),
            priceRate: new FormControl(12),
            status: new FormControl('', [Validators.required]),
            name: new FormControl('', [Validators.required]),
            surname: new FormControl(''),
            phone: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.email, Validators.required]),
            password: new FormControl(''),
        });
        this.formDocs = new FormGroup({
            idNumber: new FormControl(''),
            idExpired: new FormControl(moment.utc()),
            ssnNumber: new FormControl(''),
            ssnExpired: new FormControl(moment.utc()),
            profCardNumber: new FormControl(''),
            profCardExpired: new FormControl(moment.utc()),
            medicalExpired: new FormControl(moment.utc()),
            driverLicenseNumber: new FormControl(''),
            driverLicenseExpired: new FormControl(moment.utc()),
        });
        this.formFull = new FormGroup({
            dateOfBirth: new FormControl(moment.utc()),
            placeOfBirth: new FormControl(''),
            address: new FormControl(''),
            relationship: new FormControl('2'),
            gender: new FormControl('1'),
            height: new FormControl('180'),
            weight: new FormControl('100'),
            about: new FormControl('Client will see this info'),

            agent1: new FormControl(false),
            agent2: new FormControl(false),
            agent3: new FormControl(false),
            agent4: new FormControl(false),
            agent5: new FormControl(false),

            language1: new FormControl(false),
            language2: new FormControl(false),
            language3: new FormControl(false),
            language4: new FormControl(true),
            language5: new FormControl(false),
            language6: new FormControl(false),
            language7: new FormControl(false),
            language8: new FormControl(false),
        });
        this.formNew = new FormGroup({
            role: new FormControl('', [Validators.required]),
            status: new FormControl('', [Validators.required]),
            name: new FormControl('', [Validators.required]),
            surname: new FormControl(''),
            flag: new FormControl('fr', [Validators.required]),
            phone: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.email, Validators.required]),
            passwordCreate: new FormControl('', [Validators.required, Validators.minLength(6)]),
            confirmPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
        });
    }

    separateDialCode = true;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;

    preferredCountries: CountryISO[] = [CountryISO.France, CountryISO.UnitedStates,
        CountryISO.UnitedKingdom, CountryISO.Russia];

    session = localStorage.getItem('access_key');
    userId = localStorage.getItem('user_id');

    saveSaved = 'Create user';
    userMode = 'general';
    hidepreloaderState = 'start';
    uploadState = 'start';
    user: User[];
    currentUserId: string;
    form: FormGroup;
    formNew: FormGroup;
    formDocs: FormGroup;
    formFull: FormGroup;

    bstatus: boolean;
    isAllodriver: boolean;

    emailReservedState = false;
    emailReserved: string;
    phonelReservedState = false;
    phoneReserved: string;

    newMode = false;
    uploadMode = false;
    isUploaded = false;
    uploadedImg = '';

    uploadModeId = false;
    isUploadedId = false;
    uploadedImgId = '';

    uploadModeSsn = false;
    isUploadedSsn = false;
    uploadedImgSsn = '';

    uploadModePC = false;
    isUploadedPC = false;
    uploadedImgPC = '';

    uploadModeMed = false;
    isUploadedMed = false;
    uploadedImgMed = '';

    uploadModeDriv = false;
    isUploadedDriv = false;
    uploadedImgDriv = '';

    files: any = [];

    idExpiredVal = '';
    ssnExpiredVal = '';
    profCardExpiredVal = '';
    medicalExpiredVal = '';
    driverLicenseExpiredVal = '';

    @ViewChild('place') placesRef: ElementRef;
    @ViewChild('placeAddress') placesAddressRef: ElementRef;

    onScroll() {
        this.placesRef.nativeElement.blur();
        this.placesAddressRef.nativeElement.blur();
    }

    public handleAddressChange(address: Address) {
        console.log('address', address.formatted_address);
        this.formFull.patchValue({
            placeOfBirth: address.formatted_address
        });
    }

    public handleAddressChangeAddress(address: Address) {
        console.log('address', address.formatted_address);
        this.formFull.patchValue({
            address: address.formatted_address
        });
    }

    changeCountry(e, newMode = false) {
        if (newMode) {
            this.formNew.patchValue({
                phone: '+' + e.dialCode,
            });
            return;
        }
        this.form.patchValue({
            phone: '+' + e.dialCode,
        });
    }


    uploadPhoto(event, type) {
        this.uploadFile(event, type);
    }

    uploadFileId(event, type) {
        this.files = [];
        for (let index = 0; index < event.length; index++) {
            const element = event[index];
            this.files.push(element.name);
            console.log('file', element, element.name);
            setTimeout(() => {
                this.upload();
            }, 100);
            // upload here
            this.UsersService.uploadImage(element, type, this.currentUserId).subscribe(response => {
                console.log('response ID upload', response);
                this.isUploadedId = true;
                this.uploadedImgId = response.user_image;
                this.files = [];
                this.uploadState = 'start';
            });
        }
    }

    uploadFileSsn(event, type) {
        this.files = [];
        for (let index = 0; index < event.length; index++) {
            const element = event[index];
            this.files.push(element.name);
            console.log('file', element, element.name);
            setTimeout(() => {
                this.upload();
            }, 100);
            this.UsersService.uploadImage(element, type, this.currentUserId).subscribe(response => {
                console.log('response ID upload', response);
                this.isUploadedSsn = true;
                this.uploadedImgSsn = response.user_image;
                this.files = [];
                this.uploadState = 'start';
            });
        }
    }

    uploadFilePC(event, type) {
        this.files = [];
        for (let index = 0; index < event.length; index++) {
            const element = event[index];
            this.files.push(element.name);
            console.log('file', element, element.name);
            setTimeout(() => {
                this.upload();
            }, 100);
            this.UsersService.uploadImage(element, type, this.currentUserId).subscribe(response => {
                console.log('response Prof Card upload', response);
                this.isUploadedPC = true;
                this.uploadedImgPC = response.user_image;
                this.files = [];
                this.uploadState = 'start';
            });
        }
    }

    uploadFileMed(event, type) {
        this.files = [];
        for (let index = 0; index < event.length; index++) {
            const element = event[index];
            this.files.push(element.name);
            console.log('file', element, element.name);
            setTimeout(() => {
                this.upload();
            }, 100);
            this.UsersService.uploadImage(element, type, this.currentUserId).subscribe(response => {
                console.log('response Med upload', response);
                this.isUploadedMed = true;
                this.uploadedImgMed = response.user_image;
                this.files = [];
                this.uploadState = 'start';
            });
        }
    }

    uploadFileDriv(event, type) {
        this.files = [];
        for (let index = 0; index < event.length; index++) {
            const element = event[index];
            this.files.push(element.name);
            console.log('file', element, element.name);
            setTimeout(() => {
                this.upload();
            }, 100);
            this.UsersService.uploadImage(element, type, this.currentUserId).subscribe(response => {
                console.log('response Med upload', response);
                this.isUploadedDriv = true;
                this.uploadedImgDriv = response.user_image;
                this.files = [];
                this.uploadState = 'start';
            });
        }
    }

    uploadFile(event, type) {
        this.files = [];
        for (let index = 0; index < event.length; index++) {
            const element = event[index];
            this.files.push(element.name);
            console.log('file', element, element.name);
            setTimeout(() => {
                this.upload();
            }, 100);
            // upload here
            this.UsersService.uploadImage(element, type, this.currentUserId).subscribe(response => {
                console.log('response file upload', response);
                this.isUploaded = true;
                this.UsersService.shouldUpdate = true;
                this.uploadedImg = response.user_image;
                setTimeout(() => {
                    this.UsersService.shouldUpdate = false;
                }, 1100);
                this.files = [];
                this.uploadState = 'start';
            });
        }
    }

    deleteAttachment(index) {
        this.files.splice(index, 1);
    }

    deletePhoto() {
        this.uploadMode = true;
        this.isUploaded = false;
        this.uploadState = 'start';
    }

    deleteId() {
        this.uploadModeId = true;
        this.isUploadedId = false;
        this.uploadState = 'start';
    }

    deleteSsn() {
        this.uploadModeSsn = true;
        this.isUploadedSsn = false;
        this.uploadState = 'start';
    }

    deletePC() {
        this.uploadModePC = true;
        this.isUploadedPC = false;
        this.uploadState = 'start';
    }

    deleteMed() {
        this.uploadModeMed = true;
        this.isUploadedMed = false;
        this.uploadState = 'start';
    }

    deleteDriv() {
        this.uploadModeDriv = true;
        this.isUploadedDriv = false;
        this.uploadState = 'start';
    }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            console.log('params', params.id);
            if (params.id == 'new') {
                console.log('params new', params.id);
                this.saveSaved = 'Create user';
                this.userMode = 'general';
                this.newMode = true;
                this.formNew.reset();
                this.emailReservedState = false;
                this.phonelReservedState = false;
                this.bstatus = false;
                this.isAllodriver = false;
                this.user = [];
                this.formNew.patchValue({
                    status: '',
                    role: '',
                    name: '',
                    surname: '',
                    phone: '+33',
                    flag: 'fr',
                    email: '',
                    passwordCreate: '',
                    confirmPassword: '',
                    priceRate: 12,
                });
                this.animateLoaderBack();
            } else {
                // this.form.reset();
                this.currentUserId = params.id;
                this.getUser(params.id);
                this.newMode = false;
            }
        });
    }

    flagChanged() {
        const formData = {...this.formNew.value};
        console.log('formData', formData.flag);
        this.PhoneFlagsService.setFlag(formData.flag);
    }

    bodyguardStstusChange(id) {
        this.animateLoader();

        const status = this.bstatus ? '1' : '2';
        setTimeout(() => {
            this.bstatus = this.bstatus ? false : true;
        }, 50);
        console.log(' this.bstatus ', this.bstatus, id, status);

        this.UsersService.updateBodyguardStatus(id, status).subscribe(response => {
            console.log('Response update b status', response);
            if (response.res) {
                this.animateLoaderBack();
            } else {
                this.animateLoaderBack();
            }
        });
    }

    changeIsAllodriver() {
        this.isAllodriver = !this.isAllodriver;
        this.submit();
    }


    deleteUser(id) {
        this.animateLoader();
        console.log('delete user ', id);
        this.UsersService.deleteUser(id).subscribe(response => {
            console.log('Response delete', response);
            if (response.res) {
                this.UsersService.shouldUpdate = true;
                setTimeout(() => {
                    this.UsersService.shouldUpdate = false;
                    this.router.navigate(['/users']);
                }, 1100);
            } else {
                this.animateLoaderBack();
            }
        });
    }

    getUser(id) {
        this.userMode = 'general';
        this.saveSaved = 'Save';
        this.isUploaded = false;
        this.uploadMode = false;
        this.uploadedImg = '';

        this.uploadModeId = false;
        this.isUploadedId = false;
        this.uploadedImgId = '';

        this.bstatus = false;
        this.isAllodriver = false;

        this.animateLoader();
        this.UsersService.getUser(id).subscribe(response => {
            console.log('response User', response);
            this.user = response;
            if (this.user.length === 0) {
                if (this.SubdomainsService.subdomain == 'agent') {
                    this.router.navigate(['/users', this.userId]);
                } else {
                    this.router.navigate(['/users', 'new']);
                }
            }
            this.user.forEach(function(value) {
                if (value.bdg_status == '2') {
                    this.bstatus = true;
                }
                this.isAllodriver = value.is_allodriver == '1' ? true : false;
                this.form.patchValue({
                    status: value.status,
                    role: value.role,
                    name: value.full_name,
                    surname: value.family_name,
                    phone: value.phone,
                    email: value.email,
                    password: '',
                    priceRate: value.price_rate,
                });
                this.formDocs.patchValue({
                    idNumber: value.passport_number,
                    idExpired: {
                        year: parseInt(value.passport_expire?.slice(0, 4)),
                        month: parseInt(value.passport_expire?.slice(5, 7).replace('0', '')),
                        day: parseInt(value.passport_expire?.slice(8, 10).replace('0', ''))
                    },
                    ssnNumber: value.ssn_number,
                    ssnExpired: {
                        year: parseInt(value.ssn_expire?.slice(0, 4)),
                        month: parseInt(value.ssn_expire?.slice(5, 7).replace('0', '')),
                        day: parseInt(value.ssn_expire?.slice(8, 10).replace('0', ''))
                    },
                    profCardNumber: value.prof_card_number,
                    profCardExpired: {
                        year: parseInt(value.prof_card_expire?.slice(0, 4)),
                        month: parseInt(value.prof_card_expire?.slice(5, 7).replace('0', '')),
                        day: parseInt(value.prof_card_expire?.slice(8, 10).replace('0', ''))
                    },
                    medicalExpired: {
                        year: parseInt(value.medical_expire?.slice(0, 4)),
                        month: parseInt(value.medical_expire?.slice(5, 7).replace('0', '')),
                        day: parseInt(value.medical_expire?.slice(8, 10).replace('0', ''))
                    },
                    driverLicenseNumber: value.driver_license_number,
                    driverLicenseExpired: {
                        year: parseInt(value.driver_license_expire?.slice(0, 4)),
                        month: parseInt(value.driver_license_expire?.slice(5, 7).replace('0', '')),
                        day: parseInt(value.driver_license_expire?.slice(8, 10).replace('0', ''))
                    },
                });

                this.idExpiredVal = new Date(value.passport_expire).toLocaleString(this.translate.instant('info.lng'), {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                });

                this.ssnExpiredVal = new Date(value.ssn_expire).toLocaleString(this.translate.instant('info.lng'), {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                });

                this.profCardExpiredVal = new Date(value.prof_card_expire).toLocaleString(this.translate.instant('info.lng'), {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                });

                this.medicalExpiredVal = new Date(value.medical_expire).toLocaleString(this.translate.instant('info.lng'), {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                });

                this.driverLicenseExpiredVal = new Date(value.driver_license_expire).toLocaleString(this.translate.instant('info.lng'), {
                    year: 'numeric',
                    month: 'short',
                    day: '2-digit',
                });

                this.formFull.patchValue({
                    dateOfBirth: moment.utc(value.birthday),
                    placeOfBirth: value.born_address,
                    address: value.address,
                    relationship: value.family_status,
                    gender: value.sex,
                    height: value.height,
                    weight: value.weight,
                    about: value.info,


                    //
                    // language1: new FormControl(false),
                    // language2: new FormControl(false),
                    // language3: new FormControl(false),
                    // language4: new FormControl(true),
                    // language5: new FormControl(false),
                    // language6: new FormControl(false),
                    // language7: new FormControl(false),
                    // language8: new FormControl(false),
                });

                if (value.languages.indexOf('1') != -1) {
                    this.formFull.patchValue({
                        language1: true,
                    });
                } else {
                    this.formFull.patchValue({
                        language1: false,
                    });
                }
                if (value.languages.indexOf('2') != -1) {
                    this.formFull.patchValue({
                        language2: true,
                    });
                } else {
                    this.formFull.patchValue({
                        language2: false,
                    });
                }
                if (value.languages.indexOf('3') != -1) {
                    this.formFull.patchValue({
                        language3: true,
                    });
                } else {
                    this.formFull.patchValue({
                        language3: false,
                    });
                }
                if (value.languages.indexOf('4') != -1) {
                    this.formFull.patchValue({
                        language4: true,
                    });
                } else {
                    this.formFull.patchValue({
                        language4: false,
                    });
                }
                if (value.languages.indexOf('5') != -1) {
                    this.formFull.patchValue({
                        language5: true,
                    });
                } else {
                    this.formFull.patchValue({
                        language5: false,
                    });
                }
                if (value.languages.indexOf('6') != -1) {
                    this.formFull.patchValue({
                        language6: true,
                    });
                } else {
                    this.formFull.patchValue({
                        language6: false,
                    });
                }
                if (value.languages.indexOf('7') != -1) {
                    this.formFull.patchValue({
                        language7: true,
                    });
                } else {
                    this.formFull.patchValue({
                        language7: false,
                    });
                }
                if (value.languages.indexOf('8') != -1) {
                    this.formFull.patchValue({
                        language8: true,
                    });
                } else {
                    this.formFull.patchValue({
                        language8: false,
                    });
                }

                if (value.services.indexOf('1') != -1) {
                    this.formFull.patchValue({
                        agent1: true,
                    });
                } else {
                    this.formFull.patchValue({
                        agent1: false,
                    });
                }
                if (value.services.indexOf('2') != -1) {
                    this.formFull.patchValue({
                        agent2: true,
                    });
                } else {
                    this.formFull.patchValue({
                        agent2: false,
                    });
                }
                if (value.services.indexOf('3') != -1) {
                    this.formFull.patchValue({
                        agent3: true,
                    });
                } else {
                    this.formFull.patchValue({
                        agent3: false,
                    });
                }
                if (value.services.indexOf('4') != -1) {
                    this.formFull.patchValue({
                        agent4: true,
                    });
                } else {
                    this.formFull.patchValue({
                        agent4: false,
                    });
                }
                if (value.services.indexOf('5') != -1) {
                    this.formFull.patchValue({
                        agent5: true,
                    });
                } else {
                    this.formFull.patchValue({
                        agent5: false,
                    });
                }
                console.log('value.services.indexOf(\'2\')', value.services.indexOf('2'));
            }.bind(this));

            this.animateLoaderBack();
        });
    }

    submit() {
        if (this.form.invalid) {
            return;
        }
        this.animateLoader();
        const formData = {...this.form.value};
        this.UsersService.updateUser(this.currentUserId, formData.status, formData.role, this.isAllodriver, formData.name, formData.surname, formData.phone.number, formData.email, formData.password, formData.priceRate).subscribe(response => {
            this.animateLoaderBack();
            console.log('response general', response);

            this.saveSaved = 'Saved';
            this.form.reset();
            this.form.patchValue({
                status: formData.status,
                role: formData.role,
                name: formData.name,
                surname: formData.surname,
                phone: formData.phone.number,
                email: formData.email,
                password: '',
                priceRate: formData.priceRate,
            });
            this.UsersService.shouldUpdate = true;
            setTimeout(() => {
                this.saveSaved = 'Save';
            }, 1000);
            setTimeout(() => {
                this.UsersService.shouldUpdate = false;
            }, 1100);
        });
    }

    idExpiredChanged() {
        this.idExpiredVal = new Date(this.formDocs.get('idExpired').value.year + '-' + this.formDocs.get('idExpired').value.month + '-' + this.formDocs.get('idExpired').value.day).toLocaleString(this.translate.instant('info.lng'), {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        });
        this.saveDocs();
    }

    ssnExpiredValChanged() {
        this.ssnExpiredVal = new Date(this.formDocs.get('ssnExpired').value.year + '-' + this.formDocs.get('ssnExpired').value.month + '-' + this.formDocs.get('ssnExpired').value.day).toLocaleString(this.translate.instant('info.lng'), {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        });
        this.saveDocs();
    }

    profCardExpiredValChanged() {
        this.profCardExpiredVal = new Date(this.formDocs.get('profCardExpired').value.year + '-' + this.formDocs.get('profCardExpired').value.month + '-' + this.formDocs.get('profCardExpired').value.day).toLocaleString(this.translate.instant('info.lng'), {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        });
        this.saveDocs();
    }

    medicalExpiredValChanged() {
        this.medicalExpiredVal = new Date(this.formDocs.get('medicalExpired').value.year + '-' + this.formDocs.get('medicalExpired').value.month + '-' + this.formDocs.get('medicalExpired').value.day).toLocaleString(this.translate.instant('info.lng'), {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        });
        this.saveDocs();
    }

    driverLicenseExpiredValChanged() {
        this.driverLicenseExpiredVal = new Date(this.formDocs.get('driverLicenseExpired').value.year + '-' + this.formDocs.get('driverLicenseExpired').value.month + '-' + this.formDocs.get('driverLicenseExpired').value.day).toLocaleString(this.translate.instant('info.lng'), {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        });
        this.saveDocs();
    }

    saveDocs() {
        this.animateLoader();
        const formData = {...this.formDocs.value};
        console.log('formData DOCS ', formData);
        this.UsersService.updateDocs(this.currentUserId, formData.idNumber, formData.idExpired.year + '-' + formData.idExpired.month + '-' + formData.idExpired.day, formData.ssnNumber, formData.ssnExpired.year + '-' + formData.ssnExpired.month + '-' + formData.ssnExpired.day, formData.profCardNumber, formData.profCardExpired.year + '-' + formData.profCardExpired.month + '-' + formData.profCardExpired.day, formData.medicalExpired.year + '-' + formData.medicalExpired.month + '-' + formData.medicalExpired.day, formData.driverLicenseNumber, formData.driverLicenseExpired.year + '-' + formData.driverLicenseExpired.month + '-' + formData.driverLicenseExpired.day).subscribe(response => {
            console.log('response DOCS', response);
            this.animateLoaderBack();
            this.saveSaved = 'Saved';
            this.formDocs.reset();
            this.formDocs.patchValue({
                idNumber: formData.idNumber,
                idExpired: formData.idExpired.year + '-' + formData.idExpired.month + '-' + formData.idExpired.day,
                ssnNumber: formData.ssnNumber,
                ssnExpired: formData.ssnExpired.year + '-' + formData.ssnExpired.month + '-' + formData.ssnExpired.day,
                profCardNumber: formData.profCardNumber,
                profCardExpired: formData.profCardExpired.year + '-' + formData.profCardExpired.month + '-' + formData.profCardExpired.day,
                medicalExpired: formData.medicalExpired.year + '-' + formData.medicalExpired.month + '-' + formData.medicalExpired.day,
                driverLicenseNumber: formData.driverLicenseNumber,
                driverLicenseExpired: formData.driverLicenseExpired.year + '-' + formData.driverLicenseExpired.month + '-' + formData.driverLicenseExpired.day,
            });
            setTimeout(() => {
                this.saveSaved = 'Save';
            }, 1000);
        });
    }

    saveFull() {
        this.animateLoader();
        setTimeout(() => {
            const formData = {...this.formFull.value};
            console.log('formData Full ', formData);
            this.UsersService.updateFull(this.currentUserId, formData.about, formData.address, formData.agent1, formData.agent2, formData.agent3, formData.agent4, formData.agent5, formData.dateOfBirth._i, formData.gender, formData.height, formData.language1, formData.language2, formData.language3, formData.language4, formData.language5, formData.language6, formData.language7, formData.language8, formData.placeOfBirth, formData.relationship, formData.weight).subscribe(response => {
                console.log('response Full', response);
                this.animateLoaderBack();
                this.saveSaved = 'Saved';
                // this.formDocs.reset();
                // this.formDocs.patchValue({
                //
                // });
                setTimeout(() => {
                    this.saveSaved = 'Save';
                }, 1000);
            });
        }, 500);
    }

    submitNewUser() {
        this.animateLoader();
        const formData = {...this.formNew.value};
        this.UsersService.createUser(this.currentUserId, formData.status, formData.role, formData.name, formData.surname, formData.phone.number, formData.email, formData.passwordCreate, formData.confirmPassword, this.SubdomainsService.subdomain).subscribe(response => {

            this.animateLoaderBack();
            console.log(response);
            if (response.status == 'SUCCESS') {

                this.saveSaved = 'Created';
                this.UsersService.shouldUpdate = true;

                setTimeout(() => {
                    this.saveSaved = 'Create user';
                    this.router.navigate(['/users', response.user_id]);
                }, 1000);

                setTimeout(() => {
                    this.UsersService.shouldUpdate = false;
                }, 1100);

            } else if (response.status == 'ERROR') {
                if (response.error.code == 124) {
                    this.emailReserved = response.error.text;
                    this.emailReservedState = true;
                }
                if (response.error.code == 125) {
                    this.phoneReserved = response.error.text;
                    this.phonelReservedState = true;
                }
            }

        });
    }

    upload() {
        this.uploadState = 'end';
    }

    animateLoader() {
        this.hidepreloaderState = 'start';
    }

    animateLoaderBack() {
        this.hidepreloaderState = 'end';
    }
}
