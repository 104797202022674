<header class="flex">
    <img style="height: 30px;" class="logo" src="assets/img/logo_1eyeonu_t.svg">
    <div class="flex">
        <a class="change language">
            <div class="dropDownFlags">
                <span *ngIf="lang == 'en'">{{ 'languages.english' | translate }}</span>
                <span *ngIf="lang == 'ru'">{{ 'languages.russian' | translate }}</span>
                <span *ngIf="lang == 'fr'">{{ 'languages.france' | translate }}</span>
                <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7 9L13.9282 0.75H0.0717969L7 9Z" fill="white"></path>
                </svg>
                <div class="hiddenFlags">
                    <button *ngIf="lang != 'en'"
                            (click)="setLanguage('en')">{{ 'languages.english' | translate }}</button>
                    <button *ngIf="lang != 'fr'"
                            (click)="setLanguage('fr')">{{ 'languages.france' | translate }}</button>
                    <button *ngIf="lang != 'ru'"
                            (click)="setLanguage('ru')">{{ 'languages.russian' | translate }}</button>
                </div>
            </div>
        </a>
        <a style="
    border: 1px solid #efefef;
    color: #efefef;
    padding: 5px 20px;
    border-radius: 3px;
    white-space: nowrap;
    margin-left: 20px;
"
           href="/" class="hideM">
            {{ 'partner.LOGIN' | translate }}
        </a>
    </div>
</header>
<div class="table" [style.padding-top]="SubdomainsService.subdomain == 'pro' ? '20px' : '30px'">
    <div *ngIf="SubdomainsService.subdomain == 'pro'" class="sliderWrap">
        <div class="slide" *ngIf="mode == 'login'">
            <h2 class="blue">{{ 'partner.Sociétés de sécurité privée' | translate }}</h2>
            <h2>{{ 'partner.prêt pour la révolution technologique' | translate }}?</h2>
            <p>{{ 'partner.Vous en rêviez nous l’avons fait! Digitalisez vos opérations quotidiennes avec le premier logiciel de gestion facile d’utilisation. Oubliez papier crayon et tableau excel' | translate }}
                .</p>
            <p>{{ 'partner.Essayez pendant 3 mois' | translate }}
                <b>{{ 'partner.GRATUITEMENT' | translate }}</b>! {{ 'partner.Pas de carte bancaire, pas de paiement, pas de contrat, pas d’obligations' | translate }}
                ...</p>
        </div>
        <div class="slide" *ngIf="mode == 'registration'">
            <h2 class="blue">{{ 'partner.Vous n’êtes pas encore inscrit' | translate }}?</h2>
            <h2>{{ 'partner.ENREGISTREZ-VOUS GRATUITEMENT' | translate }}</h2>
            <p>{{ 'partner.ENFIN! Le logiciel que les professionnels de la sécurité privée attendaient! Gérer votre société en quelques clics depuis n’importe où. Dispatchez et geolocalisez vos agents. Editer, Modifier, corriger, envoyer vos devis, factures et planning en quelques secondes' | translate }}
                ....</p>
        </div>
        <!--        <div class="circles flex">-->
        <!--            <div class="circle active"></div>-->
        <!--            <div class="circle"></div>-->
        <!--            <div class="circle"></div>-->
        <!--            <div class="circle"></div>-->
        <!--            <div class="circle"></div>-->
        <!--        </div>-->
    </div>
    <div class="loginFormWrapper loginFormWrapperHome">
        <p *ngIf="SubdomainsService.subdomain == 'pro' || SubdomainsService.subdomain == 'agent'"
           style="margin: 0 0 30px; text-align: center; font-size: 18px; line-height: 22px; font-weight: 600;">
            <a *ngIf="mode == 'login'">{{ 'partner.Connectez-vous' | translate }} </a><a
                *ngIf="mode == 'registration'">
            <span *ngIf="SubdomainsService.subdomain == 'pro'">{{ 'partner.Enregistrez-vous' | translate }}</span>
            <span *ngIf="SubdomainsService.subdomain == 'agent'">{{ 'partner.Enregistrez-vous agent' | translate }}</span></a>
            <span *ngIf="SubdomainsService.subdomain == 'pro'">{{ 'partner.et bénéficiez de notre logiciel gratuitement' | translate }}</span>
            <span *ngIf="SubdomainsService.subdomain == 'agent'">{{ 'partner.et bénéficiez de notre logiciel gratuitement agent' | translate }}</span>
        </p>
        <form *ngIf="mode == 'registration' && SubdomainsService.subdomain == 'agent'" class="loginForm"
              [formGroup]="formRegistrationA"
              (ngSubmit)="submitRegistrationA()">
            <div class="flex" style="padding: 0;">
                <div class="form-control">
                    <input type="text" formControlName="name">
                    <label>{{ 'partner.Name' | translate }}</label>
                    <div class="validation"
                         *ngIf="formRegistrationA.get('name').invalid && formRegistrationA.get('name').touched">
                        <small *ngIf="formRegistrationA.get('name').errors.required">
                            {{ 'partner.Please enter your name' | translate }}
                        </small>
                    </div>
                </div>
                <div class="form-control">
                    <input type="text" formControlName="surname">
                    <label>{{ 'users.Surname' | translate }}</label>
                    <div class="validation"
                         *ngIf="formRegistrationA.get('surname').invalid && formRegistrationA.get('surname').touched">
                        <small *ngIf="formRegistrationA.get('surname').errors.required">
                            {{ 'partner.Please enter your surname' | translate }}
                        </small>
                    </div>
                </div>
            </div>
            <div [className]="this.phonelReservedState ? 'reserved form-control' : 'form-control'">
                <div [className]="!formRegistrationA.get('phone').invalid && formRegistrationA.get('phone').touched ? 'valid value phoneFix':'value phoneFix'">
                    <mat-select formControlName="flag" (selectionChange)="flagChangedA()" class="flagSelect"
                                [className]="formRegistrationA.get('phone').invalid && formRegistrationA.get('phone').touched ? 'invalid':''">
                        <mat-option value="fr"><img class="telflag" src="assets/img/flags/flag_fr.png"> +33</mat-option>
                        <mat-option value="ru"><img class="telflag" src="assets/img/flags/flag_ru.png"> +7</mat-option>
                        <mat-option value="usa"><img class="telflag" src="assets/img/flags/flag_usa.png"> +1
                        </mat-option>
                        <mat-option value="gb"><img class="telflag" src="assets/img/flags/flag_gb.png"> +44</mat-option>
                        <mat-option value="belg"><img class="telflag" src="assets/img/flags/flag_belg.png"> +32
                        </mat-option>
                        <mat-option value="est"><img class="telflag" src="assets/img/flags/flag_est.png"> +372
                        </mat-option>
                        <mat-option value="ger"><img class="telflag" src="assets/img/flags/flag_ger.png"> +49
                        </mat-option>
                        <mat-option value="it"><img class="telflag" src="assets/img/flags/flag_it.png"> +39</mat-option>
                        <mat-option value="port"><img class="telflag" src="assets/img/flags/flag_port.png"> +351
                        </mat-option>
                        <mat-option value="sp"><img class="telflag" src="assets/img/flags/flag_sp.png"> +34</mat-option>
                        <mat-option value="swi"><img class="telflag" src="assets/img/flags/flag_swi.png"> +41
                        </mat-option>
                        <mat-option value="ukr"><img class="telflag" src="assets/img/flags/flag_ukr.png"> +380
                        </mat-option>
                    </mat-select>
                    <div class="validation validationEmail" *ngIf="this.phonelReservedState">
                        <small>
                            {{ 'partner.' + this.phoneReserved | translate }}
                        </small>
                    </div>
                    <input class="input flagSelectInput" type="tel" autocomplete="new-password"
                           (focus)="this.phonelReservedState = false"
                           formControlName="phone" mask="{{this.PhoneFlagsService.phoneMask}}"
                           placeholder="{{this.PhoneFlagsService.phonePlaceholder}}">
                    <label>{{ 'partner.Phone' | translate }}</label>
                    <div class="validation"
                         *ngIf="formRegistrationA.get('phone').invalid && formRegistrationA.get('phone').touched">
                        <small *ngIf="formRegistrationA.get('phone').errors">
                            {{ 'partner.Please add correct number' | translate }}
                        </small>
                    </div>
                </div>
            </div>
            <p style="font-size: 16px; text-align: center; margin: -10px 0 20px; color: #c7c7c7;">{{ 'partner.Please add mobile phone number' | translate }}</p>
            <div [className]="this.emailReservedState ? 'reserved form-control' : 'form-control'">
                <input type="email" formControlName="email" autocomplete="email"
                       (focus)="this.emailReservedState = false">
                <label>eMail</label>
                <div class="validation validationEmail" *ngIf="this.emailReservedState">
                    <small>
                        {{ 'partner.' + this.emailReserved | translate }}
                    </small>
                </div>
                <div class="validation"
                     *ngIf="formRegistrationA.get('email').invalid && formRegistrationA.get('email').touched">
                    <small *ngIf="formRegistrationA.get('email').errors.required">
                        {{ 'partner.Please enter eMail' | translate }}
                    </small>
                    <small *ngIf="formRegistrationA.get('email').errors.email">
                        {{ 'partner.Please enter correct eMail' | translate }}
                    </small>
                </div>
            </div>
            <div class="form-control">
                <input type="{{passwordMode}}" formControlName="password" autocomplete="off">
                <div class="strongest">
                    <span [style.background]="formRegistrationA.controls['password'].value.length > 2 ? '#0A76BA' : '#32363F'"></span>
                    <span [style.background]="formRegistrationA.controls['password'].value.length > 5 ? '#0A76BA' : '#32363F'"></span>
                    <span [style.background]="formRegistrationA.controls['password'].value.length > 8 ? '#0A76BA' : '#32363F'"></span>
                    <span [style.background]="formRegistrationA.controls['password'].value.length > 11 ? '#0A76BA' : '#32363F'"></span>
                    <span [style.background]="formRegistrationA.controls['password'].value.length > 14 ? '#0A76BA' : '#32363F'"></span>
                </div>
                <button type="button" class="p_icon"
                        (click)="passwordMode == 'password' ? passwordMode='text': passwordMode='password'">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path [style.fill]="passwordMode == 'password' ? '#B6C1C8' : '#0A76BA'"
                              d="M21.8699 11.5C21.2299 10.39 17.7099 4.81999 11.7299 4.99999C6.19995 5.13999 2.99995 9.99999 2.12995 11.5C2.04218 11.652 1.99597 11.8245 1.99597 12C1.99597 12.1755 2.04218 12.348 2.12995 12.5C2.75995 13.59 6.12995 19 12.0199 19H12.2699C17.7999 18.86 21.0099 14 21.8699 12.5C21.9577 12.348 22.0039 12.1755 22.0039 12C22.0039 11.8245 21.9577 11.652 21.8699 11.5ZM12.2199 17C7.90995 17.1 5.09995 13.41 4.21995 12C5.21995 10.39 7.82995 7.09999 11.8299 6.99999C16.1199 6.88999 18.9399 10.59 19.8299 12C18.7999 13.61 16.2199 16.9 12.2199 17Z"
                              fill="#B6C1C8"/>
                        <path [style.fill]="passwordMode == 'password' ? '#B6C1C8' : '#0A76BA'"
                              d="M12 8.5C11.3078 8.5 10.6311 8.70527 10.0555 9.08986C9.47993 9.47444 9.03133 10.0211 8.76642 10.6606C8.50152 11.3001 8.4322 12.0039 8.56725 12.6828C8.7023 13.3618 9.03564 13.9854 9.52513 14.4749C10.0146 14.9644 10.6383 15.2977 11.3172 15.4327C11.9961 15.5678 12.6999 15.4985 13.3394 15.2336C13.9789 14.9687 14.5256 14.5201 14.9101 13.9445C15.2947 13.3689 15.5 12.6922 15.5 12C15.5 11.0717 15.1313 10.1815 14.4749 9.52513C13.8185 8.86875 12.9283 8.5 12 8.5ZM12 13.5C11.7033 13.5 11.4133 13.412 11.1666 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5007 12.2999 10.4709 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5006 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z"
                              fill="#B6C1C8"/>
                    </svg>
                </button>
                <label>{{ 'partner.Password' | translate }}</label>
                <div class="validation"
                     *ngIf="formRegistrationA.get('password').invalid && formRegistrationA.get('password').touched">
                    <small *ngIf="formRegistrationA.get('password').errors.required">
                        {{ 'partner.Please enter password' | translate }}
                    </small>
                    <small *ngIf="formRegistrationA.get('password').errors.minlength">
                        {{ 'partner.Please enter minimum' | translate }} {{formRegistrationA.get('password').errors.minlength.requiredLength}} {{ 'partner.characters' | translate }}
                    </small>
                </div>
            </div>
            <div [className]="this.passwordNotSame ? 'invalid form-control':'form-control'">
                <input type="{{passwordMode}}" formControlName="confirmPassword" autocomplete="off"
                       (focus)="this.passwordNotSame = false">
                <div class="strongest">
                    <span [style.background]="formRegistrationA.controls['confirmPassword'].value.length > 2 ? '#0A76BA' : '#32363F'"></span>
                    <span [style.background]="formRegistrationA.controls['confirmPassword'].value.length > 5 ? '#0A76BA' : '#32363F'"></span>
                    <span [style.background]="formRegistrationA.controls['confirmPassword'].value.length > 8 ? '#0A76BA' : '#32363F'"></span>
                    <span [style.background]="formRegistrationA.controls['confirmPassword'].value.length > 11 ? '#0A76BA' : '#32363F'"></span>
                    <span [style.background]="formRegistrationA.controls['confirmPassword'].value.length > 14 ? '#0A76BA' : '#32363F'"></span>
                </div>
                <button type="button" class="p_icon"
                        (click)="passwordMode == 'password' ? passwordMode='text': passwordMode='password'">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path [style.fill]="passwordMode == 'password' ? '#B6C1C8' : '#0A76BA'"
                              d="M21.8699 11.5C21.2299 10.39 17.7099 4.81999 11.7299 4.99999C6.19995 5.13999 2.99995 9.99999 2.12995 11.5C2.04218 11.652 1.99597 11.8245 1.99597 12C1.99597 12.1755 2.04218 12.348 2.12995 12.5C2.75995 13.59 6.12995 19 12.0199 19H12.2699C17.7999 18.86 21.0099 14 21.8699 12.5C21.9577 12.348 22.0039 12.1755 22.0039 12C22.0039 11.8245 21.9577 11.652 21.8699 11.5ZM12.2199 17C7.90995 17.1 5.09995 13.41 4.21995 12C5.21995 10.39 7.82995 7.09999 11.8299 6.99999C16.1199 6.88999 18.9399 10.59 19.8299 12C18.7999 13.61 16.2199 16.9 12.2199 17Z"
                              fill="#B6C1C8"/>
                        <path [style.fill]="passwordMode == 'password' ? '#B6C1C8' : '#0A76BA'"
                              d="M12 8.5C11.3078 8.5 10.6311 8.70527 10.0555 9.08986C9.47993 9.47444 9.03133 10.0211 8.76642 10.6606C8.50152 11.3001 8.4322 12.0039 8.56725 12.6828C8.7023 13.3618 9.03564 13.9854 9.52513 14.4749C10.0146 14.9644 10.6383 15.2977 11.3172 15.4327C11.9961 15.5678 12.6999 15.4985 13.3394 15.2336C13.9789 14.9687 14.5256 14.5201 14.9101 13.9445C15.2947 13.3689 15.5 12.6922 15.5 12C15.5 11.0717 15.1313 10.1815 14.4749 9.52513C13.8185 8.86875 12.9283 8.5 12 8.5ZM12 13.5C11.7033 13.5 11.4133 13.412 11.1666 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5007 12.2999 10.4709 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5006 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z"
                              fill="#B6C1C8"/>
                    </svg>
                </button>
                <label>{{ 'partner.Confirm password' | translate }}</label>
                <div class="validation" *ngIf="this.passwordNotSame">
                    <small *ngIf="this.passwordNotSame">
                        {{ 'partner.Passwords are not the same' | translate }}
                    </small>
                </div>
            </div>
            <div class="form-control">
                <input type="text" formControlName="promocode">
                <label>{{ 'partner.Promocode' | translate }}</label>
            </div>
            <button class="btn" type="submit"
                    [disabled]="formRegistrationA.invalid">{{ 'partner.Create account' | translate }}</button>

            <p style="font-size: 16px; text-align: left; margin: 20px 0 10px;">{{ 'partner.Vous avez déjà un compte' | translate }}
                ?
                <button type="button" class="link"
                        (click)="mode = 'login'">{{ 'partner.Connectez-vous' | translate }}
                </button>
            </p>

            <div class="form-control">
                <div style="display: flex; align-items: center;">
                    <span style="text-align: left;">{{ 'partner.I accept the privacy policy' | translate }}<a
                            target="_blank"
                            href="https://1eyeonu.com/privacy-policy-en/">{{ 'partner.Terms and Conditions' | translate }}</a> {{ 'partner.and' | translate }}
                        <a target="_blank"
                           href="https://1eyeonu.com/privacy-policy-en/">{{ 'partner.Privacy Policy' | translate }}</a></span>
                </div>
            </div>
        </form>

        <form *ngIf="mode == 'registration' && SubdomainsService.subdomain == 'pro'" class="loginForm"
              [formGroup]="formRegistration"
              (ngSubmit)="submitRegistration()">
            <div class="form-control">
                <input type="text" formControlName="companyName">
                <label>{{ 'partner.Company name' | translate }}</label>
                <div class="validation"
                     *ngIf="formRegistration.get('companyName').invalid && formRegistration.get('companyName').touched">
                    <small *ngIf="formRegistration.get('companyName').errors.required">
                        {{ 'partner.Please enter company name' | translate }}
                    </small>
                </div>
            </div>
            <div [className]="this.emailReservedState ? 'reserved form-control' : 'form-control'">
                <input type="email" formControlName="email" autocomplete="email"
                       (focus)="this.emailReservedState = false">
                <label>{{ 'partner.Company Email' | translate }}</label>
                <div class="validation validationEmail" *ngIf="this.emailReservedState">
                    <small>
                        {{ 'partner.' + this.emailReserved | translate }}
                    </small>
                </div>
                <div class="validation"
                     *ngIf="formRegistration.get('email').invalid && formRegistration.get('email').touched">
                    <small *ngIf="formRegistration.get('email').errors.required">
                        {{ 'partner.Please enter eMail' | translate }}
                    </small>
                    <small *ngIf="formRegistration.get('email').errors.email">
                        {{ 'partner.Please enter correct eMail' | translate }}
                    </small>
                </div>
            </div>
            <div class="flex" style="padding: 0;">
                <div class="form-control">
                    <input type="text" formControlName="name" autocomplete="given-name">
                    <label>{{ 'partner.Name' | translate }}</label>
                    <div class="validation"
                         *ngIf="formRegistration.get('name').invalid && formRegistration.get('name').touched">
                        <small *ngIf="formRegistration.get('name').errors.required">
                            {{ 'partner.Please enter your name' | translate }}
                        </small>
                    </div>
                </div>
                <div class="form-control">
                    <input type="text" formControlName="surname" autocomplete="family-name">
                    <label>{{ 'users.Surname' | translate }}</label>
                    <div class="validation"
                         *ngIf="formRegistration.get('surname').invalid && formRegistration.get('surname').touched">
                        <small *ngIf="formRegistration.get('surname').errors.required">
                            {{ 'partner.Please enter your surname' | translate }}
                        </small>
                    </div>
                </div>
            </div>
            <div [className]="this.phonelReservedState ? 'reserved form-control' : 'form-control'">
                <div [className]="!formRegistration.get('phone').invalid && formRegistration.get('phone').touched ? 'valid value phoneFix':'value phoneFix'">
                    <mat-select formControlName="flag" (selectionChange)="flagChanged()" class="flagSelect"
                                [className]="formRegistration.get('phone').invalid && formRegistration.get('phone').touched ? 'invalid':''">
                        <mat-option value="fr"><img class="telflag" src="assets/img/flags/flag_fr.png"> +33</mat-option>
                        <mat-option value="ru"><img class="telflag" src="assets/img/flags/flag_ru.png"> +7</mat-option>
                        <mat-option value="usa"><img class="telflag" src="assets/img/flags/flag_usa.png"> +1
                        </mat-option>
                        <mat-option value="gb"><img class="telflag" src="assets/img/flags/flag_gb.png"> +44</mat-option>
                        <mat-option value="belg"><img class="telflag" src="assets/img/flags/flag_belg.png"> +32
                        </mat-option>
                        <mat-option value="est"><img class="telflag" src="assets/img/flags/flag_est.png"> +372
                        </mat-option>
                        <mat-option value="ger"><img class="telflag" src="assets/img/flags/flag_ger.png"> +49
                        </mat-option>
                        <mat-option value="it"><img class="telflag" src="assets/img/flags/flag_it.png"> +39</mat-option>
                        <mat-option value="port"><img class="telflag" src="assets/img/flags/flag_port.png"> +351
                        </mat-option>
                        <mat-option value="sp"><img class="telflag" src="assets/img/flags/flag_sp.png"> +34</mat-option>
                        <mat-option value="swi"><img class="telflag" src="assets/img/flags/flag_swi.png"> +41
                        </mat-option>
                        <mat-option value="ukr"><img class="telflag" src="assets/img/flags/flag_ukr.png"> +380
                        </mat-option>
                    </mat-select>
                    <div class="validation validationEmail" *ngIf="this.phonelReservedState">
                        <small>
                            {{ 'partner.' + this.phoneReserved | translate }}
                        </small>
                    </div>
                    <input class="input flagSelectInput" type="tel" autocomplete="new-password"
                           (focus)="this.phonelReservedState = false"
                           formControlName="phone" mask="{{this.PhoneFlagsService.phoneMask}}"
                           placeholder="{{this.PhoneFlagsService.phonePlaceholder}}">
                    <label>{{ 'partner.Phone' | translate }}</label>
                    <div class="validation"
                         *ngIf="formRegistration.get('phone').invalid && formRegistration.get('phone').touched">
                        <small *ngIf="formRegistration.get('phone').errors">
                            {{ 'partner.Please add correct number' | translate }}
                        </small>
                    </div>
                </div>
            </div>
            <p style="font-size: 12px; line-height: 14px; text-align: center; margin: -10px 0 20px; color: #c7c7c7;">{{ 'partner.Please add mobile phone number' | translate }}</p>
            <div class="form-control">
                <input type="{{passwordMode}}" formControlName="password" autocomplete="off" id="password">
                <div class="strongest">
                    <span [style.background]="formRegistration.controls['password'].value.length > 2 ? '#0A76BA' : '#32363F'"></span>
                    <span [style.background]="formRegistration.controls['password'].value.length > 5 ? '#0A76BA' : '#32363F'"></span>
                    <span [style.background]="formRegistration.controls['password'].value.length > 8 ? '#0A76BA' : '#32363F'"></span>
                    <span [style.background]="formRegistration.controls['password'].value.length > 11 ? '#0A76BA' : '#32363F'"></span>
                    <span [style.background]="formRegistration.controls['password'].value.length > 14 ? '#0A76BA' : '#32363F'"></span>
                </div>
                <button type="button" class="p_icon"
                        (click)="passwordMode == 'password' ? passwordMode='text': passwordMode='password'">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path [style.fill]="passwordMode == 'password' ? '#B6C1C8' : '#0A76BA'"
                              d="M21.8699 11.5C21.2299 10.39 17.7099 4.81999 11.7299 4.99999C6.19995 5.13999 2.99995 9.99999 2.12995 11.5C2.04218 11.652 1.99597 11.8245 1.99597 12C1.99597 12.1755 2.04218 12.348 2.12995 12.5C2.75995 13.59 6.12995 19 12.0199 19H12.2699C17.7999 18.86 21.0099 14 21.8699 12.5C21.9577 12.348 22.0039 12.1755 22.0039 12C22.0039 11.8245 21.9577 11.652 21.8699 11.5ZM12.2199 17C7.90995 17.1 5.09995 13.41 4.21995 12C5.21995 10.39 7.82995 7.09999 11.8299 6.99999C16.1199 6.88999 18.9399 10.59 19.8299 12C18.7999 13.61 16.2199 16.9 12.2199 17Z"
                              fill="#B6C1C8"/>
                        <path [style.fill]="passwordMode == 'password' ? '#B6C1C8' : '#0A76BA'"
                              d="M12 8.5C11.3078 8.5 10.6311 8.70527 10.0555 9.08986C9.47993 9.47444 9.03133 10.0211 8.76642 10.6606C8.50152 11.3001 8.4322 12.0039 8.56725 12.6828C8.7023 13.3618 9.03564 13.9854 9.52513 14.4749C10.0146 14.9644 10.6383 15.2977 11.3172 15.4327C11.9961 15.5678 12.6999 15.4985 13.3394 15.2336C13.9789 14.9687 14.5256 14.5201 14.9101 13.9445C15.2947 13.3689 15.5 12.6922 15.5 12C15.5 11.0717 15.1313 10.1815 14.4749 9.52513C13.8185 8.86875 12.9283 8.5 12 8.5ZM12 13.5C11.7033 13.5 11.4133 13.412 11.1666 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5007 12.2999 10.4709 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5006 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z"
                              fill="#B6C1C8"/>
                    </svg>
                </button>
                <label>{{ 'partner.Password' | translate }}</label>
                <div class="validation"
                     *ngIf="formRegistration.get('password').invalid && formRegistration.get('password').touched">
                    <small *ngIf="formRegistration.get('password').errors.required">
                        {{ 'partner.Please enter password' | translate }}
                    </small>
                    <small *ngIf="formRegistration.get('password').errors.minlength">
                        {{ 'partner.Please enter minimum' | translate }} {{formRegistration.get('password').errors.minlength.requiredLength}} {{ 'partner.characters' | translate }}
                    </small>
                </div>
            </div>
            <div [className]="this.passwordNotSame ? 'invalid form-control':'form-control'">
                <input type="{{passwordMode}}" formControlName="confirmPassword" autocomplete="new-password"
                       id="confirmPassword"
                       (focus)="this.passwordNotSame = false">
                <div class="strongest">
                    <span [style.background]="formRegistration.controls['confirmPassword'].value.length > 2 ? '#0A76BA' : '#32363F'"></span>
                    <span [style.background]="formRegistration.controls['confirmPassword'].value.length > 5 ? '#0A76BA' : '#32363F'"></span>
                    <span [style.background]="formRegistration.controls['confirmPassword'].value.length > 8 ? '#0A76BA' : '#32363F'"></span>
                    <span [style.background]="formRegistration.controls['confirmPassword'].value.length > 11 ? '#0A76BA' : '#32363F'"></span>
                    <span [style.background]="formRegistration.controls['confirmPassword'].value.length > 14 ? '#0A76BA' : '#32363F'"></span>
                </div>
                <button type="button" class="p_icon"
                        (click)="passwordMode == 'password' ? passwordMode='text': passwordMode='password'">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path [style.fill]="passwordMode == 'password' ? '#B6C1C8' : '#0A76BA'"
                              d="M21.8699 11.5C21.2299 10.39 17.7099 4.81999 11.7299 4.99999C6.19995 5.13999 2.99995 9.99999 2.12995 11.5C2.04218 11.652 1.99597 11.8245 1.99597 12C1.99597 12.1755 2.04218 12.348 2.12995 12.5C2.75995 13.59 6.12995 19 12.0199 19H12.2699C17.7999 18.86 21.0099 14 21.8699 12.5C21.9577 12.348 22.0039 12.1755 22.0039 12C22.0039 11.8245 21.9577 11.652 21.8699 11.5ZM12.2199 17C7.90995 17.1 5.09995 13.41 4.21995 12C5.21995 10.39 7.82995 7.09999 11.8299 6.99999C16.1199 6.88999 18.9399 10.59 19.8299 12C18.7999 13.61 16.2199 16.9 12.2199 17Z"
                              fill="#B6C1C8"/>
                        <path [style.fill]="passwordMode == 'password' ? '#B6C1C8' : '#0A76BA'"
                              d="M12 8.5C11.3078 8.5 10.6311 8.70527 10.0555 9.08986C9.47993 9.47444 9.03133 10.0211 8.76642 10.6606C8.50152 11.3001 8.4322 12.0039 8.56725 12.6828C8.7023 13.3618 9.03564 13.9854 9.52513 14.4749C10.0146 14.9644 10.6383 15.2977 11.3172 15.4327C11.9961 15.5678 12.6999 15.4985 13.3394 15.2336C13.9789 14.9687 14.5256 14.5201 14.9101 13.9445C15.2947 13.3689 15.5 12.6922 15.5 12C15.5 11.0717 15.1313 10.1815 14.4749 9.52513C13.8185 8.86875 12.9283 8.5 12 8.5ZM12 13.5C11.7033 13.5 11.4133 13.412 11.1666 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5007 12.2999 10.4709 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5006 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z"
                              fill="#B6C1C8"/>
                    </svg>
                </button>
                <label>{{ 'partner.Confirm password' | translate }}</label>
                <div class="validation" *ngIf="this.passwordNotSame">
                    <small *ngIf="this.passwordNotSame">
                        {{ 'partner.Passwords are not the same' | translate }}
                    </small>
                </div>
            </div>
            <button class="btn" type="submit"
                    [disabled]="formRegistration.invalid">{{ 'partner.Create account' | translate }}</button>
            <p *ngIf="mode == 'registration'"
               style="font-size: 16px; text-align: center; margin: 20px 0 10px;">{{ 'partner.Vous avez déjà un compte' | translate }}
                ?
                <button type="button" class="link"
                        (click)="mode = 'login'">{{ 'partner.Connectez-vous' | translate }}
                </button>
            </p>
            <div class="form-control">
                <div style="display: flex; align-items: center;">
                    <span style="text-align: left;">{{ 'partner.I accept the privacy policy' | translate }}<a
                            target="_blank"
                            href="https://1eyeonu.com/privacy-policy-en/">{{ 'partner.Terms and Conditions' | translate }}</a> {{ 'partner.and' | translate }}
                        <a target="_blank"
                           href="https://1eyeonu.com/privacy-policy-en/">{{ 'partner.Privacy Policy' | translate }}</a></span>
                </div>
            </div>
        </form>

        <form *ngIf="mode == 'login'" class="loginForm" [formGroup]="form" (ngSubmit)="submit()">
            <div class="form-control">
                <input type="email" formControlName="email" autocomplete="email" (focus)="notRegisteredError = false"
                       [style.border-color]="notRegisteredError ? '#B23F43' : ''">
                <label>{{ 'partner.Email' | translate }}</label>
                <div class="validation" *ngIf="form.get('email').invalid && form.get('email').touched">
                    <small *ngIf="form.get('email').errors.required">
                        {{ 'partner.Please enter eMail' | translate }}
                    </small>
                    <small *ngIf="form.get('email').errors.email">
                        {{ 'partner.Please enter correct eMail' | translate }}
                    </small>
                </div>
                <div class="validation" *ngIf="this.notRegisteredError">
                    <small>
                        {{ 'partner.User is not registered' | translate }}
                    </small>
                </div>
            </div>
            <div class="form-control">
                <div class="strongest">
                    <span [style.background]="form.controls['password'].value.length > 2 ? '#0A76BA' : '#32363F'"></span>
                    <span [style.background]="form.controls['password'].value.length > 5 ? '#0A76BA' : '#32363F'"></span>
                    <span [style.background]="form.controls['password'].value.length > 8 ? '#0A76BA' : '#32363F'"></span>
                    <span [style.background]="form.controls['password'].value.length > 11 ? '#0A76BA' : '#32363F'"></span>
                    <span [style.background]="form.controls['password'].value.length > 14 ? '#0A76BA' : '#32363F'"></span>
                </div>
                <input type="{{passwordMode}}" formControlName="password"
                       (focus)="wrongPasswordError = false"
                       [style.border-color]="wrongPasswordError ? '#B23F43' : ''">
                <button type="button" class="p_icon"
                        (click)="passwordMode == 'password' ? passwordMode='text': passwordMode='password'">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path [style.fill]="passwordMode == 'password' ? '#B6C1C8' : '#0A76BA'"
                              d="M21.8699 11.5C21.2299 10.39 17.7099 4.81999 11.7299 4.99999C6.19995 5.13999 2.99995 9.99999 2.12995 11.5C2.04218 11.652 1.99597 11.8245 1.99597 12C1.99597 12.1755 2.04218 12.348 2.12995 12.5C2.75995 13.59 6.12995 19 12.0199 19H12.2699C17.7999 18.86 21.0099 14 21.8699 12.5C21.9577 12.348 22.0039 12.1755 22.0039 12C22.0039 11.8245 21.9577 11.652 21.8699 11.5ZM12.2199 17C7.90995 17.1 5.09995 13.41 4.21995 12C5.21995 10.39 7.82995 7.09999 11.8299 6.99999C16.1199 6.88999 18.9399 10.59 19.8299 12C18.7999 13.61 16.2199 16.9 12.2199 17Z"
                              fill="#B6C1C8"/>
                        <path [style.fill]="passwordMode == 'password' ? '#B6C1C8' : '#0A76BA'"
                              d="M12 8.5C11.3078 8.5 10.6311 8.70527 10.0555 9.08986C9.47993 9.47444 9.03133 10.0211 8.76642 10.6606C8.50152 11.3001 8.4322 12.0039 8.56725 12.6828C8.7023 13.3618 9.03564 13.9854 9.52513 14.4749C10.0146 14.9644 10.6383 15.2977 11.3172 15.4327C11.9961 15.5678 12.6999 15.4985 13.3394 15.2336C13.9789 14.9687 14.5256 14.5201 14.9101 13.9445C15.2947 13.3689 15.5 12.6922 15.5 12C15.5 11.0717 15.1313 10.1815 14.4749 9.52513C13.8185 8.86875 12.9283 8.5 12 8.5ZM12 13.5C11.7033 13.5 11.4133 13.412 11.1666 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5007 12.2999 10.4709 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5006 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z"
                              fill="#B6C1C8"/>
                    </svg>
                </button>
                <label>{{ 'partner.Password' | translate }}</label>
                <div class="validation" *ngIf="form.get('password').invalid && form.get('password').touched">
                    <small *ngIf="form.get('password').errors.required">
                        {{ 'partner.Please enter password' | translate }}
                    </small>
                    <small *ngIf="form.get('password').errors.minlength">
                        {{ 'partner.Please enter minimum' | translate }} {{form.get('password').errors.minlength.requiredLength}} {{ 'partner.characters' | translate }}
                    </small>
                </div>
                <div class="validation" *ngIf="this.wrongPasswordError">
                    <small>
                        {{ 'partner.Password is wrong' | translate }}
                    </small>
                </div>
            </div>
            <div class="form-control">
                <div style="display: flex; align-items: center;">
                    <mat-checkbox style="position: relative; margin-right: 10px; top: 3px;"></mat-checkbox>
                    <span>{{ 'partner.Remember me' | translate }}</span>
                </div>
                <div style="text-align: right;">
                    <button type="button" class="forgot" (click)="this.SubdomainsService.gotoRestorePage();">
                        {{ 'partner.Forgot password' | translate }}?
                    </button>
                </div>
            </div>
            <button class="btn" type="submit" [disabled]="form.invalid">
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                     xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 54 54"
                     style="enable-background:new 0 0 54 54;" xml:space="preserve">
<g>
	<path d="M43,20.113V14.5C43,6.505,35.822,0,27,0S11,6.505,11,14.5v5.613c-3.401,0.586-6,3.55-6,7.117v19.542
		C5,50.757,8.243,54,12.229,54h29.542C45.757,54,49,50.757,49,46.771V27.229C49,23.663,46.401,20.699,43,20.113z M13,14.5
		C13,7.607,19.28,2,27,2s14,5.607,14,12.5V20H13V14.5z M47,46.771C47,49.654,44.654,52,41.771,52H12.229C9.346,52,7,49.654,7,46.771
		V27.229C7,24.346,9.346,22,12.229,22h29.542C44.654,22,47,24.346,47,27.229V46.771z"/>
    <path d="M27,28c-2.206,0-4,1.794-4,4v6c0,2.206,1.794,4,4,4s4-1.794,4-4v-6C31,29.794,29.206,28,27,28z M29,38c0,1.103-0.897,2-2,2
		s-2-0.897-2-2v-6c0-1.103,0.897-2,2-2s2,0.897,2,2V38z"/>
</g>
</svg>
                {{ 'partner.LOGIN' | translate }}</button>

            <div *ngIf="SubdomainsService.subdomain == 'pro' || SubdomainsService.subdomain == 'agent'">
                <p *ngIf="mode == 'login'"
                   style="font-size: 16px; text-align: center; margin: 20px 0 0;">{{ 'partner.Vous n’êtes pas encore inscrit' | translate }}
                    ?
                    <button type="button" class="link"
                            (click)="mode = 'registration'">{{ 'partner.Enregistrez-vous' | translate }}
                    </button>
                </p>
            </div>
        </form>

        <!--        <div *ngIf="SubdomainsService.subdomain == 'admin'" class="social">-->
        <!--            {{ 'partner.Or continue with these social profile' | translate }}-->
        <!--            <div class="buttons">-->
        <!--                <button type="button">-->
        <!--                    <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--                        <circle cx="23.5" cy="23.5" r="22.5" stroke="#32363F" stroke-width="2"/>-->
        <!--                        <path d="M23.5227 18.7273C25.0937 18.7273 26.5148 19.5359 27.3293 20.65L29.2597 18.6999C28.1052 17.1941 25.883 16 23.5227 16C19.3898 16 16 19.3546 16 23.5C16 27.6454 19.3898 31 23.5227 31C26.9487 31 29.8308 28.6955 30.7145 25.5455C30.9048 24.8908 31 24.209 31 23.5V22.8182H24.2024V25.5449H27.8051C27.0574 27.1541 25.4124 28.2727 23.5227 28.2727C20.8989 28.2727 18.719 26.1317 18.719 23.5C18.719 20.8683 20.8989 18.7273 23.5227 18.7273Z"-->
        <!--                              fill="white"/>-->
        <!--                    </svg>-->
        <!--                </button>-->
        <!--                <button type="button">-->
        <!--                    <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--                        <circle cx="23.5" cy="23.5" r="22.5" stroke="#32363F" stroke-width="2"/>-->
        <!--                        <path d="M30.3633 15H17.6367C16.183 15 15 16.183 15 17.6367V30.3633C15 31.817 16.183 33 17.6367 33H22.9453V26.6367H20.8359V23.4727H22.9453V21.3281C22.9453 19.5834 24.3646 18.1641 26.1094 18.1641H29.3086V21.3281H26.1094V23.4727H29.3086L28.7812 26.6367H26.1094V33H30.3633C31.817 33 33 31.817 33 30.3633V17.6367C33 16.183 31.817 15 30.3633 15Z"-->
        <!--                              fill="white"/>-->
        <!--                    </svg>-->
        <!--                </button>-->
        <!--            </div>-->
        <!--        </div>-->

    </div>
</div>

