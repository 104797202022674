<app-header></app-header>
<app-topheader></app-topheader>
<div class="main">
    <div class="loader" [@hidepreloader]="hidepreloaderState">
        <img class="loaderImg" src="assets/img/loader.gif">
    </div>
    <div class="flex subheader">
        <button class="back" (click)="back()"><img class="loaderImg" src="assets/img/back.svg"></button>
    </div>
    <div *ngIf="this.showImg" class="imgToShowWrap">
        <button (click)="this.showImg=false"><img class="close" src="assets/img/close.png"></button>
        <img class="imgToShow" [src]="'https://1eyeonu.com/images/?id='+imgIdToShow">
    </div>
    <div class="flex">
        <div class="left">
            <div *ngIf="im != null" class="headOfChat" style="margin: 0; border: none;">
                <div class="headImg" style="width: 37px; height: 37px; background-size: cover; border-radius: 25px;"
                     [style.background]="'url(https://1eyeonu.com/images/?id='+myPhoto+')'">
                </div>
                <div class="headName">
                    <span>{{im.full_name}} {{im.family_name}}</span>
                    <span>{{roleNameIm}}</span>
                </div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-settings icon-lg text-muted pb-3px settings" data-toggle="tooltip" title=""
                     data-original-title="Settings">
                    <circle cx="12" cy="12" r="3"></circle>
                    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                </svg>
            </div>
            <div class="serch">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     stroke="#32373f" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                     class="feather feather-search icon-md cursor-pointer">
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
                <input placeholder="Search here...">
            </div>
            <div class="tabs">
                <button type="button" class="tab active">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-message-square icon-sm mr-sm-2 mr-lg-0 mr-xl-2 mb-md-1 mb-xl-0">
                        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                    </svg>
                    Chats
                </button>
                <button type="button" class="tab">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-phone-call icon-sm mr-sm-2 mr-lg-0 mr-xl-2 mb-md-1 mb-xl-0">
                        <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                    </svg>
                    Calls
                </button>
                <button type="button" class="tab">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-users icon-sm mr-sm-2 mr-lg-0 mr-xl-2 mb-md-1 mb-xl-0">
                        <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                        <circle cx="9" cy="7" r="4"></circle>
                        <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                        <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    Contacts
                </button>
            </div>
            <p class="smallSH">Recent chats</p>
            <a class="active" *ngIf="SubdomainsService.subdomain != 'admin'">Chat support</a>
            <div *ngIf="SubdomainsService.subdomain == 'admin'"
                 style="overflow: auto; max-height: calc(100vh - 355px);">
                <button *ngFor="let chat of chats"
                        [className]="this.activeToUser == chat.from_user ? 'active goToChat' : 'goToChat'"
                        (click)="getMessages(chat.from_user); this.activeToUser = chat.from_user;">
                    <div>
                        <div class="headImg"
                             style="width: 37px; height: 37px; background-size: cover; border-radius: 25px;"
                             [style.background]="chat.fromImg ?'url(https://1eyeonu.com/images/?id='+chat.fromImg+')' : 'url(https://1eyeonu.com/images/?id=1409)'">
                        </div>
                        <div class="nameAndMess">
                            <div class="name">{{chat.full_name}} {{chat.family_name}}</div>
                            <div class="lastMessage">{{chat.lastMessage.message | slice:0:70}}{{chat.lastMessage.message.length > 70 ? '...' : ''}}</div>
                        </div>
                    </div>
                    <div class="time">
                        <span>{{chat.lastMessage.created.replace(' ', 'T') | date: 'dd MMM HH:mm' : '' : this.cookieService.get('selectedLng') }}</span>
                        <span *ngIf="chat.totalNew.count > 0">{{chat.totalNew.count}}</span>
                    </div>
                </button>
            </div>
        </div>
        <div class="right" *ngIf="messages.length > 0">
            <div>
                <div *ngIf="to != null" class="headOfChat" style="justify-content: space-between;">
                    <div class="headOfChat" style="margin: 0; border: none;">
                        <div class="headImg"
                             style="width: 37px; height: 37px; background-size: cover; border-radius: 25px;"
                             [style.background]="'url(https://1eyeonu.com/images/?id='+toPhoto+')'">
                        </div>
                        <div class="headName">
                            <span>{{to.full_name}} {{to.family_name}}</span>
                            <span>{{roleName}}</span>
                        </div>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                             class="feather feather-video icon-lg text-muted mr-3" data-toggle="tooltip" title=""
                             data-original-title="Start video call">
                            <polygon points="23 7 16 12 23 17 23 7"></polygon>
                            <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                             class="feather feather-phone-call icon-lg text-muted mr-0 mr-sm-3" data-toggle="tooltip"
                             title="" data-original-title="Start voice call">
                            <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                             stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                             class="feather feather-user-plus icon-lg text-muted" data-toggle="tooltip" title=""
                             data-original-title="Add to contacts">
                            <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                            <circle cx="8.5" cy="7" r="4"></circle>
                            <line x1="20" y1="8" x2="20" y2="14"></line>
                            <line x1="23" y1="11" x2="17" y2="11"></line>
                        </svg>
                    </div>
                </div>
                <div class="bodyMessages" *ngIf="SubdomainsService.subdomain == 'admin'" #scrollMe
                     [scrollTop]="scrollMe.scrollHeight">
                    <div style="position: relative;"
                         [className]="1 != message.to_user ? 'message rightM' : 'message leftM'"
                         *ngFor="let message of messages">
                        <div *ngIf="message.message?.includes('creenshot_')" class="messageImg">
                            <img (click)="this.showMsgImg(message.message.replace('screenshot_', ''))"
                                 [src]="'https://1eyeonu.com/images/?id='+ message.message.replace('screenshot_', '')">
                        </div>
                        {{message.message}}
                        <div style="width: 37px; height: 37px; background-size: cover;"
                             [style.background]="1 != message.to_user ? 'url(https://1eyeonu.com/images/?id=' + myPhoto + ')' : 'url(https://1eyeonu.com/images/?id=' + toPhoto + ')'"
                             [className]="1 != message.to_user ? 'rightImg' : 'leftImg'"
                        >
                        </div>
                        <div class="messageTime">{{message.created.replace(' ', 'T') | date: 'dd MMM HH:mm' : '' : this.cookieService.get('selectedLng') }}</div>
                        <!--                <img class="messageNotif" [src]="message.sent == 1 ? 'assets/img/messS.png' : 'assets/img/messW.png'">-->
                    </div>
                </div>
                <div class="bodyMessages" *ngIf="SubdomainsService.subdomain != 'admin'" #scrollMe
                     [scrollTop]="scrollMe.scrollHeight">
                    <div style="position: relative;"
                         [className]="currentUser != message.to_user ? 'message rightM' : 'message leftM'"
                         *ngFor="let message of messages">
                        {{message.message}}
                        <div style="width: 37px; height: 37px; background-size: cover;"
                             [style.background]="currentUser != message.to_user ? 'url(https://1eyeonu.com/images/?id=' + myPhoto + ')' : 'url(https://1eyeonu.com/images/?id=' + toPhoto + ')'"
                             [className]="currentUser != message.to_user ? 'rightImg' : 'leftImg'"
                        >
                        </div>
                        <div class="messageTime">{{message.created.replace(' ', 'T') | date: 'shortTime'}}</div>
                        <!--                <img class="messageNotif" [src]="message.sent == 1 ? 'assets/img/messS.png' : 'assets/img/messW.png'">-->
                    </div>
                </div>
            </div>
            <div class="actionLine" *ngIf="to != null">
                <button type="button" class="sendMessage transparent" style="margin-left: 0;">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="#687078" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-smile text-muted">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                        <line x1="9" y1="9" x2="9.01" y2="9"></line>
                        <line x1="15" y1="9" x2="15.01" y2="9"></line>
                    </svg>
                </button>
                <button type="button" class="sendMessage transparent" (click)="triggerFile()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="#687078" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-paperclip text-muted">
                        <path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path>
                    </svg>
                </button>
                <button type="button" class="sendMessage transparent">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                         stroke="#687078" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                         class="feather feather-mic text-muted">
                        <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>
                        <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                        <line x1="12" y1="19" x2="12" y2="23"></line>
                        <line x1="8" y1="23" x2="16" y2="23"></line>
                    </svg>
                </button>
                <form>
                    <input type="file" #file style="display: none;" (change)="uploadPhoto($event.target.files, '1')">
                    <input class="newMessage" (input)="messageEdit($event)" [value]="this.newMessage">
                    <button type="submit" class="sendMessage" (click)="send()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                             stroke="#efefef" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                             class="feather feather-send">
                            <line x1="22" y1="2" x2="11" y2="13"></line>
                            <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                        </svg>
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
