<app-header></app-header>
<app-topheader></app-topheader>
<div class="main">
    <div class="loader" [@hidepreloader]="hidepreloaderState">
        <img class="loaderImg" src="assets/img/loader.gif">
    </div>
    <div class="salesTable">
        <div class="table">
            <div class="tableHead">
                <div>Sales by month</div>
                <div>January</div>
                <div>February</div>
                <div>March</div>
                <div>April</div>
                <div>May</div>
                <div>June</div>
                <div>July</div>
                <div>August</div>
                <div>September</div>
                <div>October</div>
                <div>November</div>
                <div>December</div>
                <div>Total</div>
            </div>
            <div class="tableRow" *ngFor="let year of sales;">
                <div class="month">
                    {{year.year}}
                </div>
                <div class="month">
                    {{year.month['01']?.totalPaid ? year.month['01']?.totalPaid.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
                <div class="month">
                    {{year.month['02']?.totalPaid ? year.month['02']?.totalPaid.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
                <div class="month">
                    {{year.month['03']?.totalPaid ? year.month['03']?.totalPaid.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
                <div class="month">
                    {{year.month['04']?.totalPaid ? year.month['04']?.totalPaid.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
                <div class="month">
                    {{year.month['05']?.totalPaid ? year.month['05']?.totalPaid.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
                <div class="month">
                    {{year.month['06']?.totalPaid ? year.month['06']?.totalPaid.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
                <div class="month">
                    {{year.month['07']?.totalPaid ? year.month['07']?.totalPaid.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
                <div class="month">
                    {{year.month['08']?.totalPaid ? year.month['08']?.totalPaid.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
                <div class="month">
                    {{year.month['09']?.totalPaid ? year.month['09']?.totalPaid.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
                <div class="month">
                    {{year.month['10']?.totalPaid ? year.month['10']?.totalPaid.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
                <div class="month">
                    {{year.month['11']?.totalPaid ? year.month['11']?.totalPaid.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
                <div class="month">
                    {{year.month['12']?.totalPaid ? year.month['12']?.totalPaid.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
                <div class="month">
                    {{year.totalPaidYear ? year.totalPaidYear.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
            </div>
        </div>
    </div>


    <div class="salesTable byPM">
        <div class="table">
            <div class="tableHead">
                <div>Sales by payment</div>
                <div>Cash</div>
                <div>Bank transfer</div>
                <div>Credit card</div>
                <div>Account</div>
                <div>Check</div>
                <div>PayPal</div>
                <div>Cryptocurrency</div>
                <div>Total</div>
            </div>
            <div class="tableRow" *ngFor="let year of sales;">
                <div class="month">
                    {{year.year}}
                </div>
                <div class="month">
                    {{year.totalPaidYearCash ? year.totalPaidYearCash.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
                <div class="month">
                    {{year.totalPaidYearBank ? year.totalPaidYearBank.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
                <div class="month">
                    {{year.totalPaidYearCc ? year.totalPaidYearCc.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
                <div class="month">
                    {{year.totalPaidYearAccount ? year.totalPaidYearAccount.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
                <div class="month">
                    {{year.totalPaidYearCheck ? year.totalPaidYearCheck.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
                <div class="month">
                    {{year.totalPaidYearPaypal ? year.totalPaidYearPaypal.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
                <div class="month">
                    {{year.totalPaidYearCryptocurrency ? year.totalPaidYearCryptocurrency.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
                <div class="month">
                    {{year.totalPaidYear ? year.totalPaidYear.toLocaleString('en-EN', {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0
                }) + ' €' : '0 €'}}
                </div>
            </div>
        </div>
    </div>
</div>
