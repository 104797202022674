import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthResponse} from './interfaces';
import {Booking} from './bookings.service';
import {FormControl} from '@angular/forms';

const devMode = '&Session=1';

export interface User {
    id: string;
    status: string;
    role: string;
    full_name: string;
    phone: string;
    email: string;
    error: any;
}

@Injectable({providedIn: 'root'})

export class UsersService {

    shouldUpdate = false;

    constructor(private http: HttpClient) {

    }

    getCurrentUserInfo(): Observable<any> {
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=getCurrentUserInfo', {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    getUsers(roles, parameters): Observable<any> {
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=getUsers&roles=' + roles + parameters, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    getUser(id): Observable<User[]> {
        return this.http.get<User[]>('https://1eyeonu.com/APIv2/?action=getUser' + '&id=' + id, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    getPartnerDetails(): Observable<any> {
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=getPartnerDetails', {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    getAgentsSalary(month, year): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=getUsersSalary', {
            month,
            year,
        }, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    // OLD getAgentsSalary(month, year): Observable<any> {
    //     return this.http.post<any>('https://1eyeonu.com/APIv2/?action=getAgentsSalary', {
    //         month,
    //         year,
    //     }, {
    //         headers: new HttpHeaders({
    //             authorization: localStorage.getItem('access_key')
    //         })
    //     });
    // OLD }

    //
    sendMessage(id, email, phone, transport, message) {
        return this.http.post('https://1eyeonu.com/APIv2/?action=sendMessage', {
            id,
            email,
            phone,
            transport,
            message,
        }, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    updateUser(id, status, role, isAllodriver, name, surname, phone, email, password, priceRate = 12): Observable<User[]> {
        return this.http.post<User[]>('https://1eyeonu.com/APIv2/?action=updateUser', {
            id,
            status,
            role,
            isAllodriver,
            name,
            surname,
            phone,
            email,
            password,
            priceRate,
        }, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    updateDocs(userId, idNumber, idExpired, ssnNumber, ssnExpired, profCardNumber, profCardExpired, medicalExpired, driverLicenseNumber, driverLicenseExpired): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=updateDocs&user_id=' + userId, {
            idNumber,
            idExpired,
            ssnNumber,
            ssnExpired,
            profCardNumber,
            profCardExpired,
            medicalExpired,
            driverLicenseNumber,
            driverLicenseExpired,
        }, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    uploadImage(fileToUpload: File, type, userId): any {
        const formData: FormData = new FormData();
        formData.append('photos', fileToUpload, fileToUpload.name);
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=uploadImage&type=' + type + '&user_id=' + userId, formData, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    uploadLogo(fileToUpload: File, type, userId): any {
        const formData: FormData = new FormData();
        formData.append('photos', fileToUpload, fileToUpload.name);
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=uploadLogo&type=' + type + '&user_id=' + userId, formData, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    updateFull(id, about, address, agent1, agent2, agent3, agent4, agent5, dateOfBirth, gender, height, language1, language2, language3, language4, language5, language6, language7, language8, placeOfBirth, relationship, weight): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=updateFullInfo', {
            id,
            about,
            address,
            agent1,
            agent2,
            agent3,
            agent4,
            agent5,
            dateOfBirth,
            gender,
            height,
            language1,
            language2,
            language3,
            language4,
            language5,
            language6,
            language7,
            language8,
            placeOfBirth,
            relationship,
            weight,
        }, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    deleteUser(id): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=deleteUser', {
            id,
        }, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    deleteBooking(id): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=deleteBooking', {
            id,
        }, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    updateBodyguardStatus(id, status): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=updateBodyguardStatus', {
            id,
            status,
        }, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    createUser(id, status, role, name, surname, phone, email, passwordCreate, confirmPassword, source = null): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=createUser', {
            id,
            status,
            role,
            name,
            surname,
            phone,
            email,
            passwordCreate,
            confirmPassword,
            source,
        }, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    saveCompanySettings(companyName, companyAddress, licence1, licence2, licence3, licence4): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=saveCompanySettings', {
            companyName, companyAddress, licence1, licence2, licence3, licence4
        }, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    saveEmailSettings(emailService, host, login, password, encryption, port): Observable<any> {
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=saveEmailSettings', {
            emailService, host, login, password, encryption, port
        }, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

}
