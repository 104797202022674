<app-header></app-header>
<app-topheader></app-topheader>
<div class="main">
    <div class="loader" [@hidepreloader]="hidepreloaderState">
        <img class="loaderImg" src="assets/img/loader.gif">
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3">
                <div class="boxM">
                    <small>{{ 'dashboard.New messages' | translate }}</small>
                    <h1>{{newMessages}}</h1>
                </div>
            </div>
            <div class="col-md-3">
                <div class="boxM">
                    <small>{{ 'dashboard.New users' | translate }}</small>
                    <h1>{{newUsers}}</h1>
                </div>
            </div>
            <div class="col-md-3">
                <div class="boxM">
                    <small>{{ 'dashboard.Sales per week' | translate }}</small>
                    <h1>{{bookingsPerWeekCount}}</h1>
                </div>
            </div>
            <div class="col-md-3">
                <div class="boxM">
                    <small>{{ 'dashboard.Sales per month' | translate }}</small>
                    <h1>{{bookingsPerMonthCount}}</h1>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="boxM"></div>
            </div>
            <div class="col-md-6">
                <div class="boxM"></div>
            </div>
        </div>
    </div>
</div>
