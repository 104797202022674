import {BrowserModule} from '@angular/platform-browser';
import {NgModule, LOCALE_ID} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HeaderComponent} from './parts/header.component';
import {TopheaderComponent} from './parts/topheader.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {BookingsComponent} from './bookings/bookings.component';
import {BookingComponent, MY_FORMATS} from './booking/booking.component';
import {UsersComponent} from './users/users.component';
import {SalaryComponent} from './salary/salary.component';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {MatSliderModule} from '@angular/material/slider';
import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatInputModule} from '@angular/material/input';
import {GooglePlaceModule} from 'ngx-google-places-autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {SatDatepickerModule, SatNativeDateModule, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from 'saturn-datepicker';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {UserComponent} from './user/user.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {NgxMaskModule} from 'ngx-mask';
import {DragDropDirective} from './drag-drop.directive';
import {NgbCalendar, NgbDatepicker, NgbInputDatepicker, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {MatRadioModule} from '@angular/material/radio';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import {ConfirmCodeComponent} from './confirmCode/confirmCode.component';
import {SettingsComponent} from './settings/settings.component';
import {CookieService} from 'ngx-cookie-service';
import {RestoreComponent} from './restore/restore.component';
import {AlarmsComponent} from './alarms/alarms.component';
import {AlarmComponent} from './alarm/alarm.component';
import {ChatComponent} from './chat/chat.component';

import {CodeInputModule} from 'angular-code-input';

import {GoogleMapsModule} from '@angular/google-maps';

import {registerLocaleData} from '@angular/common';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr);
import localeRu from '@angular/common/locales/ru';
import {SwiperModule} from 'swiper/angular';
import {SalesComponent} from './sales/sales.component';

registerLocaleData(localeRu);

export const COMMISSION_DATE_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MMM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HeaderComponent,
        TopheaderComponent,
        DashboardComponent,
        BookingsComponent,
        BookingComponent,
        UsersComponent,
        UserComponent,
        ConfirmCodeComponent,
        SettingsComponent,
        DragDropDirective,
        SalaryComponent,
        RestoreComponent,
        AlarmsComponent,
        AlarmComponent,
        ChatComponent,
        SalesComponent,
    ],
    imports: [
        BrowserModule,
        GoogleMapsModule,
        AppRoutingModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatSliderModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatInputModule,
        GooglePlaceModule,
        MatCheckboxModule,
        SatDatepickerModule,
        SatNativeDateModule,
        MatDatepickerModule,
        MatAutocompleteModule,
        MatSlideToggleModule,
        NgxMaskModule.forRoot(),
        NgbModule,
        MatRadioModule,
        HttpClientModule,
        BrowserModule,
        BrowserAnimationsModule,
        BsDatepickerModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        CodeInputModule,
        SwiperModule,
        ReactiveFormsModule,
        NgxIntlTelInputModule,
        BrowserAnimationsModule,
        TooltipModule.forRoot(),
    ],
    providers: [
        MatDatepickerModule,
        MatNativeDateModule,
        CookieService,
        // {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
    }
}
