import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {BookingsComponent} from './bookings/bookings.component';
import {BookingComponent} from './booking/booking.component';
import {UsersComponent} from './users/users.component';
import {UserComponent} from './user/user.component';
import {ConfirmCodeComponent} from './confirmCode/confirmCode.component';
import {SettingsComponent} from './settings/settings.component';
import {SalaryComponent} from './salary/salary.component';
import {RestoreComponent} from './restore/restore.component';
import {AlarmsComponent} from './alarms/alarms.component';
import {AlarmComponent} from './alarm/alarm.component';
import {ChatComponent} from './chat/chat.component';
import {SalesComponent} from './sales/sales.component';


const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'dashboard', component: DashboardComponent},
    {path: 'confirmation', component: ConfirmCodeComponent},
    {
        path: 'bookings', component: BookingsComponent, children: [
            {path: ':id', component: BookingComponent}
        ]
    },
    {
        path: 'users', component: UsersComponent, children: [
            {path: ':id', component: UserComponent},
        ]
    },
    {path: 'salary', component: SalaryComponent},
    {path: 'settings', component: SettingsComponent},
    {path: 'restore', component: RestoreComponent},
    {
        path: 'alarms', component: AlarmsComponent, children: [
            {path: ':id', component: AlarmComponent},
        ]
    },
    {path: 'chat', component: ChatComponent},
    {path: 'sales', component: SalesComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})

export class AppRoutingModule {

}
