import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})

export class SubdomainsService {

    constructor(private http: HttpClient, private router: Router) {

    }

    goToPage(url) {
        this.router.navigate([url]);
    }

    gotoHomePage() {
        this.router.navigate(['/']);
    }

    gotoDashboardPage() {
        this.router.navigate(['/dashboard']);
    }

    gotoBookingsPage() {
        this.router.navigate(['/bookings']);
    }

    gotoConfirmCodePage() {
        this.router.navigate(['/confirmation']);
    }

    gotoRestorePage() {
        this.router.navigate(['/restore']);
    }

    aythWithAccessKey() {
        const user_id = localStorage.getItem('user_id');
        const access_key = localStorage.getItem('auth_key');
        return this.http.post<any>('https://1eyeonu.com/APIv2/?action=authByAccessKey', {
            user_id,
            access_key,
        }, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    isAdmin() {
        if (this.subdomain != 'admin') {
            this.gotoBookingsPage();
        }
    }

    subdomain = window.location.host.split('.')[1] ? window.location.host.split('.')[0] : false;
    // subdomain = 'pro';
    // subdomain = 'admin';
    // subdomain = 'agent';
}
