import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SubdomainsService} from '../subdomain.service';
import {Validators} from '@angular/forms';
import {formatDate} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-dashboard',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.css'],
    animations: [
        trigger('hidepreloader', [
            state('start', style({
                display: 'flex',
                opacity: 1
            })),
            state('end', style({
                display: 'none',
                opacity: 0
            })),
            transition('start => end', animate(500))
        ])
    ],
})

export class ChatComponent implements OnInit, OnDestroy {

    hidepreloaderState = 'start';
    chats = [];
    messages = [];
    messageToSend = [];
    newMessage = '';
    currentUser = null;
    im = null;
    to = null;
    activeToUser = 1;
    myPhoto = 1409;
    toPhoto = 1409;
    roleName = '';
    roleNameIm = '';
    interval: any;
    intervalChats: any;
    currentDialog = null;
    files: any = [];
    showImg = false;
    imgIdToShow = null;

    @ViewChild('scrollMe') scrollMe;
    @ViewChild('file') file;

    constructor(private http: HttpClient, public SubdomainsService: SubdomainsService, private route: ActivatedRoute, public cookieService: CookieService) {
    }

    triggerFile() {
        this.file.nativeElement.click();
    }

    ngOnDestroy() {
        console.log('OnDestroy');
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    getNewMessages(id): Observable<any> {
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=getMessagesToUser&source=bo' + '&to=' + id, {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    getChats(): Observable<any> {
        return this.http.get<any>('https://1eyeonu.com/APIv2/?action=getChatsByUser', {
            headers: new HttpHeaders({
                authorization: localStorage.getItem('access_key')
            })
        });
    }

    messageEdit(text) {
        console.log(text.target.value);
        this.newMessage = text.target.value;
    }

    send() {
        if (this.newMessage == '') {
            return;
        }
        this.messageToSend = [new Date(), this.activeToUser, this.newMessage, 0];
        this.messages.push({
            'created': new Date().toISOString(),
            'to_user': this.activeToUser,
            'message': this.newMessage,
            'sent': 0,
            'viewed': 0
        });
        this.newMessage = '';
        // send to server
        this.fetchMessage();
    }

    fetchMessage() {
        if (this.messageToSend.length > 0) {
            fetch('https://1eyeonu.com/APIv2/?action=addMessage', {
                method: 'POST',
                headers: {
                    authorization: localStorage.getItem('access_key')
                },
                body: JSON.stringify({
                    created: this.messageToSend[0].getFullYear() +
                        '-' +
                        (this.messageToSend[0].getMonth() + 1) +
                        '-' +
                        this.messageToSend[0].getDate() +
                        ' ' +
                        this.messageToSend[0].getHours() +
                        ':' +
                        this.messageToSend[0].getMinutes(),
                    to: this.messageToSend[1],
                    message: this.messageToSend[2],
                }),
            })
                .then(response => response.json())
                .then(responseJson => {
                    console.log('saved message succes', responseJson);
                    this.messageToSend = [];
                })
                .catch(error => {
                    console.log('upload error', error);
                });
        } else {
            setTimeout(
                function() {
                    this.fetchMessage();
                }.bind(this),
                50,
            );
        }
    }

    back() {
        this.messages = [];
        this.currentDialog = null;
        clearInterval(this.interval);
    }

    getMessages(id) {
        if (this.interval) {
            clearInterval(this.interval);
        }
        if (this.currentDialog != id) {
            this.messages = [];
        }

        setTimeout(() => {
            this.currentDialog = id;
        }, 500);
        this.getNewMessages(id).subscribe(response => {
            console.log('Response messages', response);
            // this.messages = response.messages;
            console.log('this.messages', this.messages);
            response.messages.forEach(function(value) {
                console.log('value - ', value);
                console.log('value - ', this.messages.findIndex(x => x.id === value.id));

                // this.messages.findIndex(x => x.id === value.id);
                if (this.messages.findIndex(x => x.id === value.id || x.message === value.message && x.id == null) != -1) {

                } else {
                    this.messages.push(value);
                }
            }.bind(this));

            if (response.myPhoto != null) {
                this.myPhoto = response.myPhoto;
            } else {
                this.myPhoto = 1409;
            }
            if (response.toPhoto != null) {
                this.toPhoto = response.toPhoto;
            } else {
                this.toPhoto = 1409;
            }
            this.im = response.im;
            if (this.SubdomainsService.subdomain != 'admin') {
                switch (response.im.role) {
                    case '1':
                        this.roleNameIm = 'Client';
                        break;
                    case '2':
                        this.roleNameIm = 'Agent';
                        break;
                    case '3':
                        this.roleNameIm = 'Admin';
                        break;
                    case '4':
                        this.roleNameIm = 'Partner';
                        break;
                }
            }
            this.to = response.to;
            switch (response.to.role) {
                case '1':
                    this.roleName = 'Client';
                    break;
                case '2':
                    this.roleName = 'Agent';
                    break;
                case '3':
                    this.roleName = 'Admin';
                    break;
                case '4':
                    this.roleName = 'Partner';
                    break;
            }

            this.scrollMe.nativeElement.scrollTop(this.scrollMe.nativeElement.scrollHeight);
        });

        if (this.messageToSend.length == 0) {
            this.interval = setInterval(() => {
                this.getMessages(id);
            }, 5000);
        }
    }

    showMsgImg(id) {
        this.showImg = true;
        this.imgIdToShow = id;
    }

    getAllChatsForAdmin() {

        if (this.intervalChats) {
            clearInterval(this.intervalChats);
        }

        this.getChats().subscribe(response => {
            console.log('Response getAllChatsForAdmin', response);
            response.chats.forEach(function(value) {
                if (this.chats.findIndex(x => x.from_user === value.from_user) != -1) {
                    // var index = findIndex(x => x.from_user === value.from_user)
                    this.chats[this.chats.findIndex(x => x.from_user === value.from_user)].lastMessage.message = value.lastMessage.message;
                    this.chats[this.chats.findIndex(x => x.from_user === value.from_user)].totalNew.count = value.totalNew.count;
                } else {
                    this.chats.push(value);
                }
            }.bind(this));

            if (response.myPhoto != null) {
                this.myPhoto = response.myPhoto;
            }
            this.im = response.im;
            switch (response.im.role) {
                case '1':
                    this.roleNameIm = 'Client';
                    break;
                case '2':
                    this.roleNameIm = 'Agent';
                    break;
                case '3':
                    this.roleNameIm = 'Admin';
                    break;
                case '4':
                    this.roleNameIm = 'Partner';
                    break;
            }
        });

        this.intervalChats = setInterval(() => {
            this.getAllChatsForAdmin();
        }, 5000);
    }

    ngOnInit() {
        this.SubdomainsService.isAdmin();
        setTimeout(() => {
            this.animateLoader();
        }, 500);
        if (this.SubdomainsService.subdomain == 'admin') {
            this.getAllChatsForAdmin();
            this.route.queryParams.subscribe(params => {
                console.log('params.fromId', params.fromId);
                this.getMessages(params.fromId);
            });
        } else {
            this.getMessages('1');
        }
        this.currentUser = localStorage.getItem('user_id');
    }

    animateLoader() {
        this.hidepreloaderState = 'end';
    }


    uploadPhoto(event, type) {
        console.log('uploadPhoto');
        this.files = [];
        // for (let index = 0; index < event.length; index++) {
        //     const element = event[index];
        //     this.files.push(element.name);
        //     console.log('file', element, element.name);
        //     setTimeout (() => {
        //         this.upload();
        //     }, 100);
        //     // upload here
        //     this.UsersService.uploadImage(element, type, this.currentUserId).subscribe(response => {
        //         console.log('response file upload', response);
        //         this.isUploaded = true;
        //         this.UsersService.shouldUpdate = true;
        //         this.uploadedImg = response.user_image;
        //         setTimeout (() => {
        //             this.UsersService.shouldUpdate = false;
        //         }, 1100);
        //         this.files = [];
        //         this.uploadState = 'start';
        //     });
        // }
    }
}
