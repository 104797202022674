import {Component, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BookingsService} from '../bookings.service';
import {CookieService} from 'ngx-cookie-service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    animations: [
        trigger('hidepreloader', [
            state('start', style({
                display: 'flex',
                opacity: 1
            })),
            state('end', style({
                display: 'none',
                opacity: 0
            })),
            transition('start => end', animate(500))
        ])
    ],
})

export class DashboardComponent implements OnInit {
    constructor(public bookingsService: BookingsService, private translateService: TranslateService, private cookieService: CookieService) {
    }

    hidepreloaderState = 'start';
    newMessages = this.cookieService.get('new_messages');
    newUsers = this.cookieService.get('new_users');
    bookingsPerWeekCount = 0;
    bookingsPerMonthCount = 0;

    ngOnInit() {
        setTimeout(() => {
            this.animateLoader();
        }, 500);
        this.bookingsService.getDashboardData().subscribe(response => {
            console.log('Response getDashboardData', response);
            this.bookingsPerWeekCount = response.bookingsPerWeekCount;
            this.bookingsPerMonthCount = response.bookingsPerMonthCount;
        });
    }

    animateLoader() {
        this.hidepreloaderState = 'end';
    }
}
