<div *ngIf="newMode" style="background: #141618; min-width: 85%;">
    <div class="loader" [@hidepreloader]="hidepreloaderState">
        <img class="loaderImg" src="assets/img/loader.gif">
    </div>
    <div class="userHeader">
        <div>
            {{ 'users.Add new user' | translate }}
        </div>
    </div>
    <div class="userMode">
        <div class="relative">
            <div class="typeOfU">
                <button type="button" [class]="userMode == 'general' ? 'general' : '' "
                        (click)="userMode = 'general'">{{ 'users.General' | translate }}</button>
                <button type="button" [class]="userMode == 'photos' ? 'general' : '' " (click)="userMode = 'photos'"
                        [disabled]="true">{{ 'users.Photos' | translate }}</button>
                <button type="button" [class]="userMode == 'documents' ? 'general' : '' "
                        (click)="userMode = 'documents'" [disabled]="true">{{ 'users.Docs' | translate }}</button>
                <button type="button" [class]="userMode == 'full' ? 'general' : '' " (click)="userMode = 'full'"
                        disabled="true" [disabled]="true">{{ 'users.Info' | translate }}</button>
            </div>
        </div>
        <div class="userBody">
            <form class="loginForm generalForm" [formGroup]="formNew" (ngSubmit)="submitNewUser()">
                <div class="user">
                    <div #general [style.display]="userMode == 'general' ? 'block' : 'none'">
                        <div class="option">
                            <div class="key">{{ 'users.Role' | translate }}</div>
                            <div class="value">
                                <mat-select formControlName="role">
                                    <mat-option *ngIf="SubdomainsService.subdomain != 'pro'"
                                                value="1">{{ 'menu.Client' | translate }}</mat-option>
                                    <mat-option value="2">{{ 'menu.Agent' | translate }}</mat-option>
                                    <mat-option *ngIf="SubdomainsService.subdomain != 'pro'"
                                                value="3">{{ 'menu.Admin' | translate }}</mat-option>
                                    <mat-option *ngIf="SubdomainsService.subdomain != 'pro'"
                                                value="4">{{ 'menu.Partner' | translate }}</mat-option>
                                    <!--                                    <mat-option *ngIf="SubdomainsService.subdomain != 'pro'"-->
                                    <!--                                                value="5">{{ 'menu.Manager' | translate }}</mat-option>-->
                                    <!--                                    <mat-option *ngIf="SubdomainsService.subdomain == 'pro'"-->
                                    <!--                                                value="6">{{ 'menu.Assistant' | translate }}</mat-option>-->
                                </mat-select>
                                <div class="validation"
                                     *ngIf="formNew.get('role').invalid && formNew.get('role').touched">
                                    <small *ngIf="formNew.get('role').errors.required">
                                        {{ 'users.Please select role' | translate }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="option">
                            <div class="key">{{ 'users.Status' | translate }}</div>
                            <div class="value">
                                <mat-select formControlName="status">
                                    <mat-option value="1">{{ 'users.Registered' | translate }}</mat-option>
                                    <mat-option value="2">{{ 'users.Verified' | translate }}</mat-option>
                                    <mat-option value="3">{{ 'users.Deactivated' | translate }}</mat-option>
                                </mat-select>
                                <div class="validation"
                                     *ngIf="formNew.get('status').invalid && formNew.get('status').touched">
                                    <small *ngIf="formNew.get('status').errors.required">
                                        {{ 'users.Please select status' | translate }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="option">
                            <div class="key">{{ 'users.Full name' | translate }}</div>
                            <div class="value">
                                <input class="input" type="text" formControlName="name"
                                       placeholder="{{ 'users.Name' | translate }}">
                                <div class="validation"
                                     *ngIf="formNew.get('name').invalid && formNew.get('name').touched">
                                    <small *ngIf="formNew.get('name').errors.required">
                                        {{ 'users.Please add name' | translate }}
                                    </small>
                                </div>
                                <input class="input" type="text" formControlName="surname"
                                       placeholder="{{ 'users.Surname' | translate }}">
                                <div class="validation"
                                     *ngIf="formNew.get('surname').invalid && formNew.get('surname').touched">
                                    <small *ngIf="formNew.get('surname').errors.required">
                                        {{ 'users.Please add name' | translate }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="option">
                            <div class="key">{{ 'users.Phone' | translate }}</div>
                            <div class="value phoneFix">
                                <div class="validation validationEmail" *ngIf="this.phonelReservedState">
                                    <small>
                                        {{ 'users.' + this.phoneReserved | translate }}
                                    </small>
                                </div>
                                <!--                                <input class="input flagSelectInput" type="text"-->
                                <!--                                       (focus)="this.phonelReservedState = false" formControlName="phone"-->
                                <!--                                       mask="{{this.PhoneFlagsService.phoneMask}}"-->
                                <!--                                       placeholder="{{this.PhoneFlagsService.phonePlaceholder}}">-->
                                <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                                                    [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                                                    [searchCountryFlag]="true"
                                                    [searchCountryField]="[SearchCountryField.Iso2,SearchCountryField.Name]"
                                                    [selectFirstCountry]="false"
                                                    [selectedCountryISO]="CountryISO.France"
                                                    [phoneValidation]="false"
                                                    [numberFormat]="PhoneNumberFormat.International" name="phone"
                                                    (countryChange)="changeCountry($event, true)"
                                                    maxLength="18"
                                                    formControlName="phone"
                                                    (focus)="this.phonelReservedState = false" formControlName="phone">
                                </ngx-intl-tel-input>
                                <div class="validation"
                                     *ngIf="formNew.get('phone').invalid && formNew.get('phone').touched">
                                    <small *ngIf="formNew.get('phone').errors">
                                        {{ 'users.Please add correct number' | translate }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="option">
                            <div class="key">Email</div>
                            <div class="value">
                                <div class="validation validationEmail" *ngIf="this.emailReservedState">
                                    <small>
                                        {{ 'users.' + this.emailReserved | translate }}
                                    </small>
                                </div>
                                <input class="input" type="email" formControlName="email" autocomplete="new-password"
                                       (focus)="this.emailReservedState = false">
                                <div class="validation"
                                     *ngIf="formNew.get('email').invalid && formNew.get('email').touched">
                                    <small *ngIf="formNew.get('email').errors.required">
                                        {{ 'users.Please enter eMail' | translate }}
                                    </small>
                                    <small *ngIf="formNew.get('email').errors.email">
                                        {{ 'users.Please enter correct eMail' | translate }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="option">
                            <div class="key">{{ 'users.Password' | translate }}</div>
                            <div class="value">
                                <input class="input" type="password" autocomplete="new-password" formControlName="passwordCreate">
                                <div class="validation"
                                     *ngIf="formNew.get('passwordCreate').invalid && formNew.get('passwordCreate').touched">
                                    <small *ngIf="formNew.get('passwordCreate').errors.required">
                                        {{ 'users.Please enter password' | translate }}
                                    </small>
                                    <small *ngIf="formNew.get('passwordCreate').errors.minlength">
                                        {{ 'users.Please enter minimum' | translate }} {{formNew.get('passwordCreate').errors.minlength.requiredLength}} {{ 'users.characters' | translate }}
                                    </small>
                                </div>
                            </div>
                        </div>
                        <div class="option">
                            <div class="key">{{ 'users.Confirm password' | translate }}</div>
                            <div class="value">
                                <input class="input" type="password" autocomplete="new-password" formControlName="confirmPassword">
                                <div class="validation"
                                     *ngIf="formNew.get('confirmPassword').invalid && formNew.get('confirmPassword').touched">
                                    <small *ngIf="formNew.get('confirmPassword').errors.required">
                                        {{ 'users.Please repeat password' | translate }}
                                    </small>
                                    <small *ngIf="formNew.get('confirmPassword').errors.minlength">
                                        {{ 'users.Please enter minimum' | translate }} {{formNew.get('confirmPassword').errors.minlength.requiredLength}} {{ 'users.characters' | translate }}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--          <div #phopos [style.display]="userMode == 'photos' ? 'block' : 'none'">-->
                    <!--            <div *ngIf="currentUser.image_id" class="userImgFull" [style.background]="'url(https://1eyeonu.com/images/?id=' + currentUser.image_id + ')'"></div>-->
                    <!--            <div *ngIf="!currentUser.image_id" class="userImgFull" style="background: url('assets/img/default_user.png')"></div>-->
                    <!--          </div>-->
                </div>
                <div class="saveWrap">
                    <button class="btn" type="submit"
                            [disabled]="formNew.invalid">{{ 'users.' + saveSaved | translate }}</button>
                </div>
            </form>
        </div>
    </div>
</div>
<div *ngIf="user && user.length != 0">
    <div *ngFor="let currentUser of user" style="background: #141618; min-width: 85%;">
        <div class="loader" [@hidepreloader]="hidepreloaderState">
            <img class="loaderImg" src="assets/img/loader.gif">
        </div>
        <div class="userHeader">
            <div>
                <div [style.display]="currentUser.image_id && !uploadMode ? 'block' : 'none'" class="userImg"
                     [style.background]="'url(https://1eyeonu.com/images/?id=' + currentUser.image_id + ')'"></div>
                <div [style.display]="isUploaded ? 'block' : 'none'" class="userImg"
                     [style.background]="'url(https://1eyeonu.com/images/?id=' + this.uploadedImg + ')'"></div>
                <div [style.display]="!currentUser.image_id && !isUploaded || uploadMode && !isUploaded ? 'block' : 'none'"
                     class="userImg" style="background: url('assets/img/default_user.png')"></div>
                {{currentUser.full_name}}
            </div>
            <div class="delete" *ngIf="SubdomainsService.subdomain != 'agent'">
                <mat-slide-toggle *ngIf="currentUser.role == 2 && SubdomainsService.subdomain != 'pro'"
                                  [checked]="bstatus"
                                  (change)="bodyguardStstusChange(currentUser.id)"></mat-slide-toggle>
                <button type="button" (click)="deleteUser(currentUser.id)">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.5003 5.00001H13.3337V3.60834C13.3141 3.07486 13.0838 2.57089 12.6933 2.20692C12.3028 1.84295 11.7839 1.64867 11.2503 1.66668H8.75033C8.21679 1.64867 7.69788 1.84295 7.30735 2.20692C6.91682 2.57089 6.68654 3.07486 6.66699 3.60834V5.00001H2.50033C2.27931 5.00001 2.06735 5.08781 1.91107 5.24409C1.75479 5.40037 1.66699 5.61233 1.66699 5.83334C1.66699 6.05436 1.75479 6.26632 1.91107 6.4226C2.06735 6.57888 2.27931 6.66668 2.50033 6.66668H3.33366V15.8333C3.33366 16.4964 3.59705 17.1323 4.06589 17.6011C4.53473 18.07 5.17062 18.3333 5.83366 18.3333H14.167C14.83 18.3333 15.4659 18.07 15.9348 17.6011C16.4036 17.1323 16.667 16.4964 16.667 15.8333V6.66668H17.5003C17.7213 6.66668 17.9333 6.57888 18.0896 6.4226C18.2459 6.26632 18.3337 6.05436 18.3337 5.83334C18.3337 5.61233 18.2459 5.40037 18.0896 5.24409C17.9333 5.08781 17.7213 5.00001 17.5003 5.00001ZM8.33366 13.3333C8.33366 13.5544 8.24586 13.7663 8.08958 13.9226C7.9333 14.0789 7.72134 14.1667 7.50033 14.1667C7.27931 14.1667 7.06735 14.0789 6.91107 13.9226C6.75479 13.7663 6.66699 13.5544 6.66699 13.3333V10C6.66699 9.77899 6.75479 9.56703 6.91107 9.41075C7.06735 9.25447 7.27931 9.16668 7.50033 9.16668C7.72134 9.16668 7.9333 9.25447 8.08958 9.41075C8.24586 9.56703 8.33366 9.77899 8.33366 10V13.3333ZM8.33366 3.60834C8.33366 3.47501 8.50866 3.33334 8.75033 3.33334H11.2503C11.492 3.33334 11.667 3.47501 11.667 3.60834V5.00001H8.33366V3.60834ZM13.3337 13.3333C13.3337 13.5544 13.2459 13.7663 13.0896 13.9226C12.9333 14.0789 12.7213 14.1667 12.5003 14.1667C12.2793 14.1667 12.0674 14.0789 11.9111 13.9226C11.7548 13.7663 11.667 13.5544 11.667 13.3333V10C11.667 9.77899 11.7548 9.56703 11.9111 9.41075C12.0674 9.25447 12.2793 9.16668 12.5003 9.16668C12.7213 9.16668 12.9333 9.25447 13.0896 9.41075C13.2459 9.56703 13.3337 9.77899 13.3337 10V13.3333Z"
                              fill="#0A76BA"/>
                    </svg>
                </button>
            </div>
        </div>
        <div class="userMode">
            <div class="relative">
                <div class="typeOfU">
                    <button type="button" [class]="userMode == 'general' ? 'general' : '' "
                            (click)="userMode = 'general'">{{ 'users.General' | translate }}</button>
                    <button type="button" [class]="userMode == 'photos' ? 'general' : '' "
                            (click)="userMode = 'photos'">{{ 'users.Photos' | translate }}</button>
                    <button type="button" [class]="userMode == 'documents' ? 'general' : '' "
                            (click)="userMode = 'documents'" [disabled]="currentUser.role != '2'">Docs
                    </button>
                    <button type="button" [class]="userMode == 'full' ? 'general' : '' " (click)="userMode = 'full'"
                            [disabled]="currentUser.role != '2'">Info
                    </button>
                </div>
            </div>
            <div class="userBody">
                <form class="loginForm generalForm" [formGroup]="form" (ngSubmit)="submit()">
                    <div class="user">
                        <div #general [style.display]="userMode == 'general' ? 'block' : 'none'">
                            <div class="option" *ngIf="SubdomainsService.subdomain == 'admin'">
                                <div class="key">For allodriver</div>
                                <div class="value">
                                    <mat-slide-toggle [checked]="isAllodriver"
                                                      (change)="changeIsAllodriver()"></mat-slide-toggle>
                                </div>
                            </div>
                            <div class="option" *ngIf="SubdomainsService.subdomain == 'admin'">
                                <div class="key">Rate per hour</div>
                                <div class="value">
                                    <input class="input" type="number" formControlName="priceRate" (blur)="submit()">
                                </div>
                            </div>
                            <div class="option">
                                <div class="key">{{ 'booking.Created' | translate }}</div>
                                <div class="value">{{currentUser.created | date: 'MMM dd, yyyy HH:mm' : 'UTC'}}</div>
                            </div>
                            <div class="option">
                                <div class="key">{{ 'users.Role' | translate }}</div>
                                <div class="value">
                                    <mat-select formControlName="role" (closed)="submit()"
                                                [disabled]="SubdomainsService.subdomain == 'pro' && form.get('role').value == '4'">
                                        <mat-option *ngIf="SubdomainsService.subdomain != 'pro'"
                                                    value="1">{{ 'menu.Client' | translate }}</mat-option>
                                        <mat-option value="2">{{ 'menu.Agent' | translate }}</mat-option>
                                        <mat-option *ngIf="SubdomainsService.subdomain != 'pro'"
                                                    value="3">{{ 'menu.Admin' | translate }}</mat-option>
                                        <mat-option value="4">{{ 'menu.Partner' | translate }}</mat-option>
                                        <mat-option *ngIf="SubdomainsService.subdomain != 'pro'"
                                                    value="5">{{ 'menu.Manager' | translate }}</mat-option>
                                        <mat-option *ngIf="SubdomainsService.subdomain == 'pro'"
                                                    value="6">{{ 'menu.Assistant' | translate }}</mat-option>
                                    </mat-select>
                                    <div class="validation"
                                         *ngIf="form.get('role').invalid && form.get('role').touched">
                                        <small *ngIf="form.get('role').errors.required">
                                            {{ 'menu.Please select role' | translate }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="option">
                                <div class="key">{{ 'users.Status' | translate }}</div>
                                <div class="value">
                                    <mat-select formControlName="status" (closed)="submit()">
                                        <mat-option value="1">{{ 'users.Registered' | translate }}</mat-option>
                                        <mat-option value="2">{{ 'users.Verified' | translate }}</mat-option>
                                        <mat-option value="3">{{ 'users.Deactivated' | translate }}</mat-option>
                                    </mat-select>
                                    <div class="validation"
                                         *ngIf="form.get('status').invalid && form.get('status').touched">
                                        <small *ngIf="form.get('status').errors.required">
                                            {{ 'menu.Please select status' | translate }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="option">
                                <div class="key">{{ 'users.Full name' | translate }}</div>
                                <div class="value">
                                    <input class="input" type="text" formControlName="name" (blur)="submit()"
                                           placeholder="{{ 'users.Name' | translate }}">
                                    <div class="validation"
                                         *ngIf="form.get('name').invalid && form.get('name').touched">
                                        <small *ngIf="form.get('name').errors.required">
                                            {{ 'users.Please add name' | translate }}
                                        </small>
                                    </div>
                                    <input class="input" type="text" formControlName="surname" (blur)="submit()"
                                           placeholder="{{ 'users.Surname' | translate }}">
                                    <div class="validation"
                                         *ngIf="form.get('surname').invalid && form.get('surname').touched">
                                        <small *ngIf="form.get('surname').errors.required">
                                            {{ 'users.Please add name' | translate }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="option">
                                <div class="key">{{ 'users.Phone' | translate }}</div>
                                <div class="value">
                                    <!--                                    <input class="input" type="text" formControlName="phone" (blur)="submit()">-->
                                    <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries"
                                                        [enableAutoCountrySelect]="true" [enablePlaceholder]="true"
                                                        [searchCountryFlag]="true"
                                                        [searchCountryField]="[SearchCountryField.Iso2,SearchCountryField.Name]"
                                                        [selectFirstCountry]="false"
                                                        [selectedCountryISO]="CountryISO.France"
                                                        [phoneValidation]="false"
                                                        [numberFormat]="PhoneNumberFormat.International" name="phone"
                                                        (countryChange)="changeCountry($event)"
                                                        maxLength="18"
                                                        formControlName="phone"
                                                        (change)="submit()">
                                    </ngx-intl-tel-input>
                                    <div class="validation"
                                         *ngIf="form.get('phone').invalid && form.get('phone').touched">
                                        <small *ngIf="form.get('phone').errors.required">
                                            {{ 'users.Please add phone' | translate }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div class="option">
                                <div class="key">{{ 'users.Email' | translate }}</div>
                                <div class="value">
                                    <input class="input" type="email"  autocomplete="new-password" formControlName="email" (blur)="submit()">
                                    <div class="validation"
                                         *ngIf="form.get('email').invalid && form.get('email').touched">
                                        <small *ngIf="form.get('email').errors.required">
                                            {{ 'users.Please enter eMail' | translate }}
                                        </small>
                                        <small *ngIf="form.get('email').errors.email">
                                            {{ 'users.Please enter correct eMail' | translate }}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="option">
                                <div class="key">{{ 'users.Set new password' | translate }}</div>
                                <div class="value">
                                    <input class="input" type="password" autocomplete="new-password" formControlName="password" (blur)="submit()">
                                </div>
                            </div>
                        </div>
                        <div #phopos [style.display]="userMode == 'photos' ? 'block' : 'none'">
                            <div [style.display]="currentUser.image_id && !uploadMode ? 'block' : 'none'"
                                 class="userImgFull"
                                 [style.background]="'url(https://1eyeonu.com/images/?id=' + currentUser.image_id + ')'">
                                <button class="delete-file" type="button" (click)="deletePhoto()">
                                    <img src="assets/img/icon_delete.svg">
                                </button>
                            </div>
                            <div [style.display]="isUploaded ? 'block' : 'none'" class="userImgFull"
                                 [style.background]="'url(https://1eyeonu.com/images/?id=' + this.uploadedImg + ')'">
                                <button class="delete-file" type="button" (click)="deletePhoto()">
                                    <img src="assets/img/icon_delete.svg">
                                </button>
                            </div>
                            <div [style.display]="!currentUser.image_id && !isUploaded || uploadMode && !isUploaded ? 'block' : 'none'"
                                 class="userImgFull">
                                <div class="uploadfilecontainer" (click)="fileInput.click()" appDragDrop
                                     (onFileDropped)="uploadPhoto($event, '1')">
                                    <input hidden type="file" #fileInput
                                           (change)="uploadPhoto($event.target.files, '1')">
                                </div>
                                <div class="files-list" *ngFor="let file of files;let i= index">
                                    <p><span class="progress" [@upload]="uploadState"></span><span
                                            class="fileName">{{ file }}</span></p>
                                    <!--                <button class="delete-file" (click)="deleteAttachment(i)">-->
                                    <!--                  <img src="assets/img/icon_delete.svg">-->
                                    <!--                </button>-->
                                </div>
                            </div>
                            <div [style.display]="currentUser.video ? 'block' : 'none'">
                                <video class="video" preload="none" controls=""
                                       [src]="'https://1eyeonu.com/u_videos/'+currentUser.video" type="video/mp4"
                                       poster>
                                </video>
                            </div>
                        </div>
                        <form class="loginForm docsForm" [formGroup]="formDocs">
                            <div #docs [style.display]="userMode == 'documents' ? 'block' : 'none'">
                                <ngb-accordion #acc="ngbAccordion" [closeOthers]="true">
                                    <ngb-panel id="toggle-1">
                                        <ng-template ngbPanelTitle>
                                            <span>{{ 'users.Passport or id' | translate }}</span><img
                                                class="plus toggleimg" src="assets/img/plus_white.svg"><img
                                                class="minus toggleimg" src="assets/img/minus.svg">
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div [style.display]="currentUser.uidimg && !uploadModeId ? 'block' : 'none'"
                                                 class="userDocImgFull">
                                                <img [src]="'https://1eyeonu.com/images/?id=' + currentUser.uidimg + '&PHPSESSID=' + session">
                                                <button class="delete-file" type="button" (click)="deleteId()">
                                                    <img src="assets/img/icon_delete.svg">
                                                </button>
                                            </div>
                                            <div [style.display]="isUploadedId ? 'block' : 'none'"
                                                 class="userDocImgFull">
                                                <img [src]="'https://1eyeonu.com/images/?id=' + this.uploadedImgId">
                                                <button class="delete-file" type="button" (click)="deleteId()">
                                                    <img src="assets/img/icon_delete.svg">
                                                </button>
                                            </div>
                                            <div [style.display]="!currentUser.uidimg && !isUploadedId || uploadModeId && !isUploadedId ? 'block' : 'none'"
                                                 class="userDocImgFull">
                                                <div class="uploadfilecontainer" (click)="fileInputId.click()"
                                                     appDragDrop (onFileDropped)="uploadFileId($event, '2')">
                                                    <input hidden type="file" #fileInputId
                                                           (change)="uploadFileId($event.target.files, '2')">
                                                </div>
                                                <div class="files-list" *ngFor="let file of files;let i= index">
                                                    <p><span class="progress" [@upload]="uploadState"></span><span
                                                            class="fileName">{{ file }}</span></p>
                                                </div>
                                            </div>
                                            <div class="option">
                                                <div class="key">{{ 'users.ID number' | translate }}</div>
                                                <div class="value">
                                                    <input class="input" type="text" (blur)="saveDocs()"
                                                           formControlName="idNumber">
                                                </div>
                                            </div>
                                            <div class="option">
                                                <div class="key">{{ 'users.ID expire' | translate }}</div>
                                                <div class="value">
                                                    <!--                          <mat-form-field style="width: 120px;">-->
                                                    <!--                            &lt;!&ndash;                    {{currentBooking.b_start_date | date: 'MM/dd/yyyy'}}&ndash;&gt;-->
                                                    <!--                            <input style="height: 42px; "matInput [matDatepicker]="idExpired" readonly (click)="idExpired.open()" (dateChange)="saveDocs()" formControlName="idExpired">-->
                                                    <!--                            <mat-datepicker #idExpired></mat-datepicker>-->
                                                    <!--                          </mat-form-field>-->

                                                    <input class="form-control" forma="yyyy-mm-dd"
                                                           (dateSelect)="idExpiredChanged()"
                                                           name="dp" formControlName="idExpired"
                                                           style="width: 150px; opacity: 0;" ngbDatepicker
                                                           dataformatas="" #idExpired="ngbDatepicker"
                                                           (focus)="idExpired.toggle()">
                                                    <button class="dateBtn"
                                                            (click)="idExpired.toggle()">{{idExpiredVal}}</button>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                    <ngb-panel id="toggle-2">
                                        <ng-template ngbPanelTitle>
                                            <span>{{ 'users.SSN' | translate }}</span><img class="plus toggleimg"
                                                                                           src="assets/img/plus_white.svg"><img
                                                class="minus toggleimg" src="assets/img/minus.svg">
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div [style.display]="currentUser.ussnimg && !uploadModeSsn ? 'block' : 'none'"
                                                 class="userDocImgFull">
                                                <img [src]="'https://1eyeonu.com/images/?id=' + currentUser.ussnimg + '&PHPSESSID=' + session">
                                                <button class="delete-file" type="button" (click)="deleteSsn()">
                                                    <img src="assets/img/icon_delete.svg">
                                                </button>
                                            </div>
                                            <div [style.display]="isUploadedSsn ? 'block' : 'none'"
                                                 class="userDocImgFull">
                                                <img [src]="'https://1eyeonu.com/images/?id=' + this.uploadedImgSsn">
                                                <button class="delete-file" type="button" (click)="deleteSsn()">
                                                    <img src="assets/img/icon_delete.svg">
                                                </button>
                                            </div>
                                            <div [style.display]="!currentUser.ussnimg && !isUploadedSsn || uploadModeSsn && !isUploadedSsn ? 'block' : 'none'"
                                                 class="userDocImgFull">
                                                <div class="uploadfilecontainer" (click)="fileInputSsn.click()"
                                                     appDragDrop (onFileDropped)="uploadFileSsn($event, '3')">
                                                    <input hidden type="file" #fileInputSsn
                                                           (change)="uploadFileSsn($event.target.files, '3')">
                                                </div>
                                                <div class="files-list" *ngFor="let file of files;let i= index">
                                                    <p><span class="progress" [@upload]="uploadState"></span><span
                                                            class="fileName">{{ file }}</span></p>
                                                </div>
                                            </div>
                                            <div class="option">
                                                <div class="key">{{ 'users.SSN number' | translate }}</div>
                                                <div class="value">
                                                    <input class="input" type="text" formControlName="ssnNumber"
                                                           (blur)="saveDocs()">
                                                </div>
                                            </div>
                                            <div class="option">
                                                <div class="key">{{ 'users.SSN expire' | translate }}</div>
                                                <div class="value">
                                                    <!--                          <mat-form-field style="width: 120px;">-->
                                                    <!--                            &lt;!&ndash;                    {{currentBooking.b_start_date | date: 'MM/dd/yyyy'}}&ndash;&gt;-->
                                                    <!--                            <input style="height: 42px; "matInput [matDatepicker]="ssnExpired" readonly (click)="ssnExpired.open()" (dateChange)="saveDocs()" formControlName="ssnExpired">-->
                                                    <!--                            <mat-datepicker #ssnExpired></mat-datepicker>-->
                                                    <!--                          </mat-form-field>-->

                                                    <input class="form-control" forma="yyyy-mm-dd"
                                                           (dateSelect)="ssnExpiredValChanged()"
                                                           name="dp" formControlName="ssnExpired"
                                                           style="width: 150px; opacity: 0;" ngbDatepicker
                                                           dataformatas="" #ssnExpired="ngbDatepicker"
                                                           (focus)="ssnExpired.toggle()">
                                                    <button class="dateBtn"
                                                            (click)="ssnExpired.toggle()">{{ssnExpiredVal}}</button>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>


                                    <ngb-panel id="toggle-3">
                                        <ng-template ngbPanelTitle>
                                            <span>{{ 'users.Professional card' | translate }}</span><img
                                                class="plus toggleimg" src="assets/img/plus_white.svg"><img
                                                class="minus toggleimg" src="assets/img/minus.svg">
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div [style.display]="currentUser.uprofimg && !uploadModePC ? 'block' : 'none'"
                                                 class="userDocImgFull">
                                                <img [src]="'https://1eyeonu.com/images/?id=' + currentUser.uprofimg + '&PHPSESSID=' + session">
                                                <button class="delete-file" type="button" (click)="deletePC()">
                                                    <img src="assets/img/icon_delete.svg">
                                                </button>
                                            </div>
                                            <div [style.display]="isUploadedPC ? 'block' : 'none'"
                                                 class="userDocImgFull">
                                                <img [src]="'https://1eyeonu.com/images/?id=' + this.uploadedImgPC">
                                                <button class="delete-file" type="button" (click)="deletePC()">
                                                    <img src="assets/img/icon_delete.svg">
                                                </button>
                                            </div>
                                            <div [style.display]="!currentUser.uprofimg && !isUploadedPC || uploadModePC && !isUploadedPC ? 'block' : 'none'"
                                                 class="userDocImgFull">
                                                <div class="uploadfilecontainer" (click)="fileInputPC.click()"
                                                     appDragDrop (onFileDropped)="uploadFilePC($event, '4')">
                                                    <input hidden type="file" #fileInputPC
                                                           (change)="uploadFilePC($event.target.files, '4')">
                                                </div>
                                                <div class="files-list" *ngFor="let file of files;let i= index">
                                                    <p><span class="progress" [@upload]="uploadState"></span><span
                                                            class="fileName">{{ file }}</span></p>
                                                </div>
                                            </div>
                                            <div class="option">
                                                <div class="key">{{ 'users.Card number' | translate }}</div>
                                                <div class="value">
                                                    <input class="input" type="text" formControlName="profCardNumber"
                                                           (blur)="saveDocs()">
                                                </div>
                                            </div>
                                            <div class="option">
                                                <div class="key">{{ 'users.Card expire' | translate }}</div>
                                                <div class="value">
                                                    <!--                          <mat-form-field style="width: 120px;">-->
                                                    <!--                            <input style="height: 42px; "matInput [matDatepicker]="profCardExpired" readonly (click)="profCardExpired.open()" (dateChange)="saveDocs()" formControlName="profCardExpired">-->
                                                    <!--                            <mat-datepicker #profCardExpired></mat-datepicker>-->
                                                    <!--                          </mat-form-field>-->

                                                    <input class="form-control" forma="yyyy-mm-dd"
                                                           (dateSelect)="profCardExpiredValChanged()"
                                                           name="dp" formControlName="profCardExpired"
                                                           style="width: 150px; opacity: 0;" ngbDatepicker
                                                           dataformatas="" #profCardExpired="ngbDatepicker"
                                                           (focus)="profCardExpired.toggle()">
                                                    <button class="dateBtn"
                                                            (click)="profCardExpired.toggle()">{{profCardExpiredVal}}</button>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>


                                    <ngb-panel id="toggle-4">
                                        <ng-template ngbPanelTitle>
                                            <span>{{ 'users.Visit medical' | translate }}</span><img
                                                class="plus toggleimg" src="assets/img/plus_white.svg"><img
                                                class="minus toggleimg" src="assets/img/minus.svg">
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div [style.display]="currentUser.umedimg && !uploadModeMed ? 'block' : 'none'"
                                                 class="userDocImgFull">
                                                <img [src]="'https://1eyeonu.com/images/?id=' + currentUser.umedimg + '&PHPSESSID=' + session">
                                                <button class="delete-file" type="button" (click)="deleteMed()">
                                                    <img src="assets/img/icon_delete.svg">
                                                </button>
                                            </div>
                                            <div [style.display]="isUploadedMed ? 'block' : 'none'"
                                                 class="userDocImgFull">
                                                <img [src]="'https://1eyeonu.com/images/?id=' + this.uploadedImgMed">
                                                <button class="delete-file" type="button" (click)="deleteMed()">
                                                    <img src="assets/img/icon_delete.svg">
                                                </button>
                                            </div>
                                            <div [style.display]="!currentUser.umedimg && !isUploadedMed || uploadModeMed && !isUploadedMed ? 'block' : 'none'"
                                                 class="userDocImgFull">
                                                <div class="uploadfilecontainer" (click)="fileInputMed.click()"
                                                     appDragDrop (onFileDropped)="uploadFileMed($event, '7')">
                                                    <input hidden type="file" #fileInputMed
                                                           (change)="uploadFileMed($event.target.files, '7')">
                                                </div>
                                                <div class="files-list" *ngFor="let file of files;let i= index">
                                                    <p><span class="progress" [@upload]="uploadState"></span><span
                                                            class="fileName">{{ file }}</span></p>
                                                </div>
                                            </div>
                                            <div class="option">
                                                <div class="key">{{ 'users.Visit medical expire' | translate }}</div>
                                                <div class="value">
                                                    <!--                          <mat-form-field style="width: 120px;">-->
                                                    <!--                            <input style="height: 42px; "matInput [matDatepicker]="medExpired" readonly (click)="medExpired.open()" (dateChange)="saveDocs()" formControlName="medicalExpired">-->
                                                    <!--                            <mat-datepicker #medExpired></mat-datepicker>-->
                                                    <!--                          </mat-form-field>-->
                                                    <input class="form-control" forma="yyyy-mm-dd"
                                                           (dateSelect)="medicalExpiredValChanged()"
                                                           name="dp" formControlName="medicalExpired"
                                                           style="width: 150px; opacity: 0;" ngbDatepicker
                                                           dataformatas="" #medicalExpired="ngbDatepicker"
                                                           (focus)="medicalExpired.toggle()">
                                                    <button class="dateBtn"
                                                            (click)="medicalExpired.toggle()">{{medicalExpiredVal}}</button>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>

                                    <ngb-panel id="toggle-5">
                                        <ng-template ngbPanelTitle>
                                            <span>{{ 'users.Driver license' | translate }}</span><img
                                                class="plus toggleimg" src="assets/img/plus_white.svg"><img
                                                class="minus toggleimg" src="assets/img/minus.svg">
                                        </ng-template>
                                        <ng-template ngbPanelContent>
                                            <div [style.display]="currentUser.udrivimg && !uploadModeDriv ? 'block' : 'none'"
                                                 class="userDocImgFull">
                                                <img [src]="'https://1eyeonu.com/images/?id=' + currentUser.udrivimg + '&PHPSESSID=' + session">
                                                <button class="delete-file" type="button" (click)="deleteDriv()">
                                                    <img src="assets/img/icon_delete.svg">
                                                </button>
                                            </div>
                                            <div [style.display]="isUploadedDriv ? 'block' : 'none'"
                                                 class="userDocImgFull">
                                                <img [src]="'https://1eyeonu.com/images/?id=' + this.uploadedImgDriv">
                                                <button class="delete-file" type="button" (click)="deleteDriv()">
                                                    <img src="assets/img/icon_delete.svg">
                                                </button>
                                            </div>
                                            <div [style.display]="!currentUser.udrivimg && !isUploadedDriv || uploadModeDriv && !isUploadedDriv ? 'block' : 'none'"
                                                 class="userDocImgFull">
                                                <div class="uploadfilecontainer" (click)="fileInputDriv.click()"
                                                     appDragDrop (onFileDropped)="uploadFileDriv($event, '5')">
                                                    <input hidden type="file" #fileInputDriv
                                                           (change)="uploadFileDriv($event.target.files, '5')">
                                                </div>
                                                <div class="files-list" *ngFor="let file of files;let i= index">
                                                    <p><span class="progress" [@upload]="uploadState"></span><span
                                                            class="fileName">{{ file }}</span></p>
                                                </div>
                                            </div>
                                            <div class="option">
                                                <div class="key">{{ 'users.License number' | translate }}</div>
                                                <div class="value">
                                                    <input class="input" type="text"
                                                           formControlName="driverLicenseNumber" (blur)="saveDocs()">
                                                </div>
                                            </div>
                                            <div class="option">
                                                <div class="key">{{ 'users.License expire' | translate }}</div>
                                                <div class="value">
                                                    <!--                          <mat-form-field style="width: 120px;">-->
                                                    <!--                            <input style="height: 42px; "matInput [matDatepicker]="profCardExpired" readonly (click)="profCardExpired.open()" (dateChange)="saveDocs()" formControlName="driverLicenseExpired">-->
                                                    <!--                            <mat-datepicker #profCardExpired></mat-datepicker>-->
                                                    <!--                          </mat-form-field>-->

                                                    <input class="form-control" forma="yyyy-mm-dd"
                                                           (dateSelect)="driverLicenseExpiredValChanged()"
                                                           name="dp" formControlName="driverLicenseExpired"
                                                           style="width: 150px; opacity: 0;" ngbDatepicker
                                                           dataformatas="" #driverLicenseExpired="ngbDatepicker"
                                                           (focus)="driverLicenseExpired.toggle()">
                                                    <button class="dateBtn"
                                                            (click)="driverLicenseExpired.toggle()">{{driverLicenseExpiredVal}}</button>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ngb-panel>
                                </ngb-accordion>
                            </div>
                        </form>


                        <form class="loginForm fullForm" [formGroup]="formFull" (scroll)="onScroll()">
                            <div #full [style.display]="userMode == 'full' ? 'block' : 'none'">
                                <div class="option">
                                    <div class="key">{{ 'users.Date of birth' | translate }}</div>
                                    <div class="value">
                                        <mat-form-field style="width: 120px;">
                                            <input style="height: 42px; " matInput [matDatepicker]="dateOfBirth"
                                                   readonly (click)="dateOfBirth.open()" (dateChange)="saveFull()"
                                                   formControlName="dateOfBirth">
                                            <mat-datepicker #dateOfBirth></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="option">
                                    <div class="key">{{ 'users.Place of birth' | translate }}</div>
                                    <div class="value">
                                        <input class="address" ngx-google-places-autocomplete
                                               formControlName="placeOfBirth" #place #placesRef="ngx-places"
                                               (blur)="saveFull()" (onAddressChange)="handleAddressChange($event)"/>
                                    </div>
                                </div>

                                <div class="option">
                                    <div class="key">{{ 'users.Address' | translate }}</div>
                                    <div class="value">
                                        <input class="address" ngx-google-places-autocomplete formControlName="address"
                                               #placeAddress #placeAddress="ngx-places" (blur)="saveFull()"
                                               (onAddressChange)="handleAddressChangeAddress($event)"/>
                                    </div>
                                </div>

                                <div class="option" style="padding: 20px 0 0;">
                                    <div class="key">{{ 'users.Relationship' | translate }}</div>
                                    <div class="value">
                                        <mat-radio-group aria-label="Select an option" formControlName="relationship">
                                            <mat-radio-button (click)="saveFull()"
                                                              value="1">{{ 'users.Single' | translate }}</mat-radio-button>
                                            <mat-radio-button (click)="saveFull()"
                                                              value="2">{{ 'users.Married' | translate }}</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>

                                <div class="option" style="padding: 40px 0 20px;">
                                    <div class="key">{{ 'users.Gender' | translate }}</div>
                                    <div class="value">
                                        <mat-radio-group aria-label="Select an option" formControlName="gender">
                                            <mat-radio-button (click)="saveFull()"
                                                              value="1">{{ 'users.Male' | translate }}</mat-radio-button>
                                            <mat-radio-button (click)="saveFull()"
                                                              value="2">{{ 'users.Female' | translate }}</mat-radio-button>
                                        </mat-radio-group>
                                    </div>
                                </div>

                                <div class="option">
                                    <div class="key">{{ 'users.Height' | translate }}</div>
                                    <div class="value">
                                        <mat-select (closed)="saveFull()" formControlName="height">
                                            <mat-option value="170">170 cm</mat-option>
                                            <mat-option value="171">171 cm</mat-option>
                                            <mat-option value="172">172 cm</mat-option>
                                            <mat-option value="173">173 cm</mat-option>
                                            <mat-option value="174">174 cm</mat-option>
                                            <mat-option value="175">175 cm</mat-option>
                                            <mat-option value="176">176 cm</mat-option>
                                            <mat-option value="177">177 cm</mat-option>
                                            <mat-option value="178">178 cm</mat-option>
                                            <mat-option value="179">179 cm</mat-option>
                                            <mat-option value="180">180 cm</mat-option>
                                            <mat-option value="181">181 cm</mat-option>
                                            <mat-option value="182">182 cm</mat-option>
                                            <mat-option value="183">183 cm</mat-option>
                                            <mat-option value="184">184 cm</mat-option>
                                            <mat-option value="185">185 cm</mat-option>
                                            <mat-option value="186">186 cm</mat-option>
                                            <mat-option value="187">187 cm</mat-option>
                                            <mat-option value="188">188 cm</mat-option>
                                            <mat-option value="189">189 cm</mat-option>
                                            <mat-option value="190">190 cm</mat-option>
                                            <mat-option value="191">191 cm</mat-option>
                                            <mat-option value="192">192 cm</mat-option>
                                            <mat-option value="193">193 cm</mat-option>
                                            <mat-option value="194">194 cm</mat-option>
                                            <mat-option value="195">195 cm</mat-option>
                                            <mat-option value="196">196 cm</mat-option>
                                            <mat-option value="197">197 cm</mat-option>
                                            <mat-option value="198">198 cm</mat-option>
                                            <mat-option value="199">199 cm</mat-option>
                                            <mat-option value="200">200 cm</mat-option>
                                            <mat-option value="201">201 cm</mat-option>
                                            <mat-option value="202">202 cm</mat-option>
                                            <mat-option value="203">203 cm</mat-option>
                                            <mat-option value="204">204 cm</mat-option>
                                            <mat-option value="205">205 cm</mat-option>
                                            <mat-option value="206">206 cm</mat-option>
                                            <mat-option value="207">207 cm</mat-option>
                                            <mat-option value="208">208 cm</mat-option>
                                            <mat-option value="209">209 cm</mat-option>
                                            <mat-option value="210">210 cm</mat-option>
                                        </mat-select>
                                    </div>
                                </div>

                                <div class="option">
                                    <div class="key">{{ 'users.Weight' | translate }}</div>
                                    <div class="value">
                                        <mat-select (closed)="saveFull()" formControlName="weight">
                                            <mat-option value="70">70 kg</mat-option>
                                            <mat-option value="71">71 kg</mat-option>
                                            <mat-option value="72">72 kg</mat-option>
                                            <mat-option value="73">73 kg</mat-option>
                                            <mat-option value="74">74 kg</mat-option>
                                            <mat-option value="75">75 kg</mat-option>
                                            <mat-option value="76">76 kg</mat-option>
                                            <mat-option value="77">77 kg</mat-option>
                                            <mat-option value="78">78 kg</mat-option>
                                            <mat-option value="78">79 kg</mat-option>
                                            <mat-option value="80">80 kg</mat-option>
                                            <mat-option value="81">81 kg</mat-option>
                                            <mat-option value="82">82 kg</mat-option>
                                            <mat-option value="83">83 kg</mat-option>
                                            <mat-option value="84">84 kg</mat-option>
                                            <mat-option value="85">85 kg</mat-option>
                                            <mat-option value="86">86 kg</mat-option>
                                            <mat-option value="87">87 kg</mat-option>
                                            <mat-option value="88">88 kg</mat-option>
                                            <mat-option value="89">89 kg</mat-option>
                                            <mat-option value="90">90 kg</mat-option>
                                            <mat-option value="91">91 kg</mat-option>
                                            <mat-option value="92">92 kg</mat-option>
                                            <mat-option value="93">93 kg</mat-option>
                                            <mat-option value="94">94 kg</mat-option>
                                            <mat-option value="95">95 kg</mat-option>
                                            <mat-option value="96">96 kg</mat-option>
                                            <mat-option value="97">97 kg</mat-option>
                                            <mat-option value="98">98 kg</mat-option>
                                            <mat-option value="99">99 kg</mat-option>
                                            <mat-option value="100">100 kg</mat-option>
                                            <mat-option value="101">101 kg</mat-option>
                                            <mat-option value="102">102 kg</mat-option>
                                            <mat-option value="103">103 kg</mat-option>
                                            <mat-option value="104">104 kg</mat-option>
                                            <mat-option value="105">105 kg</mat-option>
                                            <mat-option value="106">106 kg</mat-option>
                                            <mat-option value="107">107 kg</mat-option>
                                            <mat-option value="108">108 kg</mat-option>
                                            <mat-option value="109">109 kg</mat-option>
                                            <mat-option value="110">110 kg</mat-option>
                                            <mat-option value="111">111 kg</mat-option>
                                            <mat-option value="112">112 kg</mat-option>
                                            <mat-option value="113">113 kg</mat-option>
                                            <mat-option value="114">114 kg</mat-option>
                                            <mat-option value="115">115 kg</mat-option>
                                            <mat-option value="116">116 kg</mat-option>
                                            <mat-option value="117">117 kg</mat-option>
                                            <mat-option value="118">118 kg</mat-option>
                                            <mat-option value="119">119 kg</mat-option>
                                            <mat-option value="120">120 kg</mat-option>
                                            <mat-option value="121">121 kg</mat-option>
                                            <mat-option value="122">122 kg</mat-option>
                                            <mat-option value="123">123 kg</mat-option>
                                            <mat-option value="124">124 kg</mat-option>
                                            <mat-option value="125">125 kg</mat-option>
                                            <mat-option value="126">126 kg</mat-option>
                                            <mat-option value="127">127 kg</mat-option>
                                            <mat-option value="128">128 kg</mat-option>
                                            <mat-option value="129">129 kg</mat-option>
                                            <mat-option value="130">130 kg</mat-option>
                                        </mat-select>
                                    </div>
                                </div>

                                <div class="option" style="align-items: flex-start; padding: 20px 0;">
                                    <div class="key">{{ 'users.Services' | translate }}</div>
                                    <div class="value">
                                        <section class="example-section">
                                            <mat-checkbox class="example-margin" (click)="saveFull()"
                                                          formControlName="agent1">{{ 'booking.Agent de sécurité' | translate }}</mat-checkbox>
                                            <mat-checkbox class="example-margin" (click)="saveFull()"
                                                          formControlName="agent2">{{ 'booking.Agent de sécurité Premium' | translate }}</mat-checkbox>
                                            <mat-checkbox class="example-margin" (click)="saveFull()"
                                                          formControlName="agent3">{{ 'booking.Agent de sécurité Canine' | translate }}</mat-checkbox>
                                            <mat-checkbox class="example-margin" (click)="saveFull()"
                                                          formControlName="agent4">{{ 'booking.Chauffeur Sécurité' | translate }}</mat-checkbox>
                                            <mat-checkbox class="example-margin" (click)="saveFull()"
                                                          formControlName="agent5">{{ 'booking.Garde du corps' | translate }}</mat-checkbox>
                                        </section>
                                    </div>
                                </div>

                                <div class="option" style="align-items: flex-start;">
                                    <div class="key">{{ 'users.Languages' | translate }}</div>
                                    <div class="value">
                                        <section class="example-section">
                                            <mat-checkbox class="example-margin" (click)="saveFull()"
                                                          formControlName="language1">{{ 'users.English' | translate }}</mat-checkbox>
                                            <mat-checkbox class="example-margin" (click)="saveFull()"
                                                          formControlName="language2">{{ 'users.French' | translate }}</mat-checkbox>
                                            <mat-checkbox class="example-margin" (click)="saveFull()"
                                                          formControlName="language3">{{ 'users.Russian' | translate }}</mat-checkbox>
                                            <mat-checkbox class="example-margin" (click)="saveFull()"
                                                          formControlName="language4">{{ 'users.Italian' | translate }}</mat-checkbox>
                                            <mat-checkbox class="example-margin" (click)="saveFull()"
                                                          formControlName="language5">{{ 'users.Spanish' | translate }}</mat-checkbox>
                                            <mat-checkbox class="example-margin" (click)="saveFull()"
                                                          formControlName="language6">{{ 'users.Portugal' | translate }}</mat-checkbox>
                                            <mat-checkbox class="example-margin" (click)="saveFull()"
                                                          formControlName="language7">{{ 'users.German' | translate }}</mat-checkbox>
                                            <mat-checkbox class="example-margin" (click)="saveFull()"
                                                          formControlName="language8">{{ 'users.Polonais' | translate }}</mat-checkbox>
                                        </section>
                                    </div>
                                </div>


                                <div class="option">
                                    <div class="key">{{ 'users.About agent' | translate }}</div>
                                    <div class="value">
                                        <textarea ng-model="myTextarea" formControlName="about"
                                                  (blur)="saveFull()"></textarea>
                                    </div>
                                </div>

                            </div>
                        </form>


                    </div>
                    <div class="saveWrap">
                        <button class="btn" type="submit" [disabled]="form.invalid"
                                [style.display]="userMode == 'general' ? 'block' : 'none'">{{ 'booking.' + saveSaved | translate }}</button>
                        <button class="btn" type="button" [style.display]="userMode == 'documents' ? 'block' : 'none'"
                                (click)="saveDocs()">{{ 'booking.' + saveSaved | translate }}</button>
                        <button class="btn" type="button" [style.display]="userMode == 'full' ? 'block' : 'none'"
                                (click)="saveFull()">{{ 'booking.' + saveSaved | translate }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
