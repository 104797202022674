import {Component, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {BookingsService} from '../bookings.service';

@Component({
    selector: 'app-dashboard',
    templateUrl: './sales.component.html',
    styleUrls: ['./sales.component.css'],
    animations: [
        trigger('hidepreloader', [
            state('start', style({
                display: 'flex',
                opacity: 1
            })),
            state('end', style({
                display: 'none',
                opacity: 0
            })),
            transition('start => end', animate(500))
        ])
    ],
})

export class SalesComponent implements OnInit {
    constructor(private bookingsService: BookingsService) {
    }

    hidepreloaderState = 'start';
    sales: any;

    ngOnInit() {
        setTimeout(() => {
            this.animateLoader();
        }, 500);
        this.getSales();
    }

    animateLoader() {
        this.hidepreloaderState = 'end';
    }

    getSales() {
        this.bookingsService.getSales().subscribe(response => {
            console.log('Sales', response);
            this.sales = Object.values(response);
        });
    }
}
