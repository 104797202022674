import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})


export class PhoneFlagsService {
    phoneDialcode = '+33';
    phoneMask = '0 00 00 00 00';
    phonePlaceholder = '6 23 65 45 65';

    fr = ['+33', '0 00 00 00 00', '6 23 65 45 65'];
    ru = ['+7', '(000) 000 00 00', '999 123 23 45'];
    usa = ['+1', '000 000 0000', '407 543 2345'];
    gb = ['+44', '0000 000000', '1234 789546'];
    belg = ['+32', '000 00 00 00', '123 76 56 34'];
    est = ['+372', '000 0000', '123 4567'];
    ger = ['+49', '0000 0000000', '5678 1234654'];
    it = ['+39', '000 000 0000', '543 875 1276'];
    port = ['+351', '000 000 000', '987 456 762'];
    sp = ['+34', '000 00 00 00', '875 78 45 34'];
    swi = ['+41', '00 000 00 00', '78 345 76 17'];
    ukr = ['+380', '00 000 0000', '65 453 8724'];

    constructor(private http: HttpClient) {

    }

    patchPhone(phone) {
        if (phone != null) {
            if (phone.substring(0, 3) == '+33') {
                return phone.slice(3);
            } else if (phone.substring(0, 2) == '+7') {
                return phone.slice(2);
            } else if (phone.substring(0, 2) == '+1') {
                return phone.slice(2);
            }
        }
    }

    patchFlag(phone) {
        if (phone != null) {
            if (phone.substring(0, 3) == '+33') {
                this.setFlag('fr');
                return 'fr';
            } else if (phone.substring(0, 2) == '+7') {
                this.setFlag('ru');
                return 'ru';
            } else if (phone.substring(0, 2) == '+1') {
                this.setFlag('usa');
                return 'usa';
            } else {
                this.setFlag('fr');
                return 'fr';
            }
        }
    }

    setFlag(value) {
        console.log('value', value);
        if (value == 'fr') {
            this.phoneDialcode = this.fr[0];
            this.phoneMask = this.fr[1];
            this.phonePlaceholder = this.fr[2];
        }
        if (value == 'ru') {
            this.phoneDialcode = this.ru[0];
            this.phoneMask = this.ru[1];
            this.phonePlaceholder = this.ru[2];
        }
        if (value == 'usa') {
            this.phoneDialcode = this.usa[0];
            this.phoneMask = this.usa[1];
            this.phonePlaceholder = this.usa[2];
        }

        if (value == 'gb') {
            this.phoneDialcode = this.gb[0];
            this.phoneMask = this.gb[1];
            this.phonePlaceholder = this.gb[2];
        }
        if (value == 'belg') {
            this.phoneDialcode = this.belg[0];
            this.phoneMask = this.belg[1];
            this.phonePlaceholder = this.belg[2];
        }
        if (value == 'est') {
            this.phoneDialcode = this.est[0];
            this.phoneMask = this.est[1];
            this.phonePlaceholder = this.est[2];
        }

        if (value == 'ger') {
            this.phoneDialcode = this.ger[0];
            this.phoneMask = this.ger[1];
            this.phonePlaceholder = this.ger[2];
        }
        if (value == 'it') {
            this.phoneDialcode = this.it[0];
            this.phoneMask = this.it[1];
            this.phonePlaceholder = this.it[2];
        }
        if (value == 'port') {
            this.phoneDialcode = this.port[0];
            this.phoneMask = this.port[1];
            this.phonePlaceholder = this.port[2];
        }

        if (value == 'sp') {
            this.phoneDialcode = this.sp[0];
            this.phoneMask = this.sp[1];
            this.phonePlaceholder = this.sp[2];
        }
        if (value == 'swi') {
            this.phoneDialcode = this.swi[0];
            this.phoneMask = this.swi[1];
            this.phonePlaceholder = this.swi[2];
        }
        if (value == 'ukr') {
            this.phoneDialcode = this.ukr[0];
            this.phoneMask = this.ukr[1];
            this.phonePlaceholder = this.ukr[2];
        }
    }
}
