<div class="main restore">
    <div class="loader" [@hidepreloader]="hidepreloaderState">
        <img class="loaderImg" src="assets/img/loader.gif">
    </div>
    <div class="row">
        <div class="col-md-12" style="display: flex; align-items: center; height: 100vh;">
            <div class="form" *ngIf="this.mode=='tel'">
                <h2>{{ 'partner.Enter your email' | translate }}</h2>
                <form class="loginForm" [formGroup]="form" (ngSubmit)="submit()">
                    <div [className]="!form.get('email').invalid && form.get('email').touched && !userNoExist ? 'valid value phoneFix':'invalid value phoneFix'">
                        <input class="input" type="text"
                               formControlName="email"
                               autocomplete="email"
                               (focus)="userNoExist = false"
                        />
                        <label>Email</label>
                        <div class="validation"
                             *ngIf="form.get('email').invalid && form.get('email').touched">
                            <small *ngIf="form.get('email').errors.required">
                                {{ 'partner.Please enter eMail' | translate }}
                            </small>
                            <small *ngIf="form.get('email').errors.email">
                                {{ 'partner.Please enter correct eMail' | translate }}
                            </small>
                        </div>
                        <div class="validation"
                             *ngIf="userNoExist">
                            <small>
                                {{ 'partner.User not found' | translate }}
                            </small>
                        </div>
                    </div>
                    <button class="btn" type="submit"
                            [disabled]="form.invalid">{{ 'confirmation.Confirm' | translate }}</button>
                </form>

            </div>
            <div class="form" *ngIf="this.mode=='code'">
                <h2>{{ 'confirmation.We sent sms to your phone number1' | translate }}</h2>
                <form class="loginFormConfirmation" [formGroup]="formConf">
                    <div [class]="wrongCode == 1 ? 'red form-control' : 'form-control'">
                        <code-input
                            #code
                            initialFocusField="0"
                            (codeChanged)="onCodeChanged()"
                            [codeLength]="4"
                            (codeCompleted)="submitCode($event)">
                        </code-input>
                    </div>
                    <p *ngIf="wrongCode == 1" class="red">{{ 'confirmation.Incorrect verification code' | translate }}</p>

                    <button class="btn" type="submit" >{{ 'confirmation.Confirm' | translate }}</button>
                    <button class="resend" type="button"
                            (click)="resend()">{{ 'confirmation.' + buttonText | translate }}</button>
                </form>
            </div>
            <div class="form" *ngIf="this.mode=='password'">
                <h2>{{ 'confirmation.Enter new password' | translate }}</h2>
                <form class="loginFormPassword" [formGroup]="formPassword" (ngSubmit)="submitPassword()">
                    <div class="form-control">
                        <input type="{{passwordMode}}" formControlName="password">
                        <div class="strongest">
                            <span [style.background]="formPassword.controls['password'].value.length > 2 ? '#0A76BA' : '#32363F'"></span>
                            <span [style.background]="formPassword.controls['password'].value.length > 5 ? '#0A76BA' : '#32363F'"></span>
                            <span [style.background]="formPassword.controls['password'].value.length > 8 ? '#0A76BA' : '#32363F'"></span>
                            <span [style.background]="formPassword.controls['password'].value.length > 11 ? '#0A76BA' : '#32363F'"></span>
                            <span [style.background]="formPassword.controls['password'].value.length > 14 ? '#0A76BA' : '#32363F'"></span>
                        </div>
                        <button type="button" class="p_icon"
                                (click)="passwordMode == 'password' ? passwordMode='text': passwordMode='password'">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path [style.fill]="passwordMode == 'password' ? '#B6C1C8' : '#0A76BA'"
                                      d="M21.8699 11.5C21.2299 10.39 17.7099 4.81999 11.7299 4.99999C6.19995 5.13999 2.99995 9.99999 2.12995 11.5C2.04218 11.652 1.99597 11.8245 1.99597 12C1.99597 12.1755 2.04218 12.348 2.12995 12.5C2.75995 13.59 6.12995 19 12.0199 19H12.2699C17.7999 18.86 21.0099 14 21.8699 12.5C21.9577 12.348 22.0039 12.1755 22.0039 12C22.0039 11.8245 21.9577 11.652 21.8699 11.5ZM12.2199 17C7.90995 17.1 5.09995 13.41 4.21995 12C5.21995 10.39 7.82995 7.09999 11.8299 6.99999C16.1199 6.88999 18.9399 10.59 19.8299 12C18.7999 13.61 16.2199 16.9 12.2199 17Z"
                                      fill="#B6C1C8"/>
                                <path [style.fill]="passwordMode == 'password' ? '#B6C1C8' : '#0A76BA'"
                                      d="M12 8.5C11.3078 8.5 10.6311 8.70527 10.0555 9.08986C9.47993 9.47444 9.03133 10.0211 8.76642 10.6606C8.50152 11.3001 8.4322 12.0039 8.56725 12.6828C8.7023 13.3618 9.03564 13.9854 9.52513 14.4749C10.0146 14.9644 10.6383 15.2977 11.3172 15.4327C11.9961 15.5678 12.6999 15.4985 13.3394 15.2336C13.9789 14.9687 14.5256 14.5201 14.9101 13.9445C15.2947 13.3689 15.5 12.6922 15.5 12C15.5 11.0717 15.1313 10.1815 14.4749 9.52513C13.8185 8.86875 12.9283 8.5 12 8.5ZM12 13.5C11.7033 13.5 11.4133 13.412 11.1666 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5007 12.2999 10.4709 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5006 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z"
                                      fill="#B6C1C8"/>
                            </svg>
                        </button>
                        <label>{{ 'partner.Password' | translate }}</label>
                        <div class="validation"
                             *ngIf="formPassword.get('password').invalid && formPassword.get('password').touched">
                            <small *ngIf="formPassword.get('password').errors.required">
                                {{ 'partner.Please enter password' | translate }}
                            </small>
                            <small *ngIf="formPassword.get('password').errors.minlength">
                                {{ 'partner.Please enter minimum' | translate }} {{formPassword.get('password').errors.minlength.requiredLength}} {{ 'partner.characters' | translate }}
                            </small>
                        </div>
                    </div>
                    <div [className]="this.passwordNotSame ? 'invalid form-control':'form-control'">
                        <input type="{{passwordMode}}" formControlName="confirmPassword"
                               (focus)="this.passwordNotSame = false">
                        <div class="strongest">
                            <span [style.background]="formPassword.controls['confirmPassword'].value.length > 2 ? '#0A76BA' : '#32363F'"></span>
                            <span [style.background]="formPassword.controls['confirmPassword'].value.length > 5 ? '#0A76BA' : '#32363F'"></span>
                            <span [style.background]="formPassword.controls['confirmPassword'].value.length > 8 ? '#0A76BA' : '#32363F'"></span>
                            <span [style.background]="formPassword.controls['confirmPassword'].value.length > 11 ? '#0A76BA' : '#32363F'"></span>
                            <span [style.background]="formPassword.controls['confirmPassword'].value.length > 14 ? '#0A76BA' : '#32363F'"></span>
                        </div>
                        <button type="button" class="p_icon"
                                (click)="passwordMode == 'password' ? passwordMode='text': passwordMode='password'">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path [style.fill]="passwordMode == 'password' ? '#B6C1C8' : '#0A76BA'"
                                      d="M21.8699 11.5C21.2299 10.39 17.7099 4.81999 11.7299 4.99999C6.19995 5.13999 2.99995 9.99999 2.12995 11.5C2.04218 11.652 1.99597 11.8245 1.99597 12C1.99597 12.1755 2.04218 12.348 2.12995 12.5C2.75995 13.59 6.12995 19 12.0199 19H12.2699C17.7999 18.86 21.0099 14 21.8699 12.5C21.9577 12.348 22.0039 12.1755 22.0039 12C22.0039 11.8245 21.9577 11.652 21.8699 11.5ZM12.2199 17C7.90995 17.1 5.09995 13.41 4.21995 12C5.21995 10.39 7.82995 7.09999 11.8299 6.99999C16.1199 6.88999 18.9399 10.59 19.8299 12C18.7999 13.61 16.2199 16.9 12.2199 17Z"
                                      fill="#B6C1C8"/>
                                <path [style.fill]="passwordMode == 'password' ? '#B6C1C8' : '#0A76BA'"
                                      d="M12 8.5C11.3078 8.5 10.6311 8.70527 10.0555 9.08986C9.47993 9.47444 9.03133 10.0211 8.76642 10.6606C8.50152 11.3001 8.4322 12.0039 8.56725 12.6828C8.7023 13.3618 9.03564 13.9854 9.52513 14.4749C10.0146 14.9644 10.6383 15.2977 11.3172 15.4327C11.9961 15.5678 12.6999 15.4985 13.3394 15.2336C13.9789 14.9687 14.5256 14.5201 14.9101 13.9445C15.2947 13.3689 15.5 12.6922 15.5 12C15.5 11.0717 15.1313 10.1815 14.4749 9.52513C13.8185 8.86875 12.9283 8.5 12 8.5ZM12 13.5C11.7033 13.5 11.4133 13.412 11.1666 13.2472C10.92 13.0824 10.7277 12.8481 10.6142 12.574C10.5007 12.2999 10.4709 11.9983 10.5288 11.7074C10.5867 11.4164 10.7296 11.1491 10.9393 10.9393C11.1491 10.7296 11.4164 10.5867 11.7074 10.5288C11.9983 10.4709 12.2999 10.5006 12.574 10.6142C12.8481 10.7277 13.0824 10.92 13.2472 11.1666C13.412 11.4133 13.5 11.7033 13.5 12C13.5 12.3978 13.342 12.7794 13.0607 13.0607C12.7794 13.342 12.3978 13.5 12 13.5Z"
                                      fill="#B6C1C8"/>
                            </svg>
                        </button>
                        <label>{{ 'partner.Confirm password' | translate }}</label>
                        <div class="validation" *ngIf="this.passwordNotSame">
                            <small *ngIf="this.passwordNotSame">
                                {{ 'partner.Passwords are not the same' | translate }}
                            </small>
                        </div>
                    </div>
                    <button class="btn" type="submit"
                            [disabled]="formPassword.invalid">{{ 'confirmation.Save' | translate }}</button>
                </form>
            </div>
        </div>
    </div>
</div>
