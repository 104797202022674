<app-header></app-header>
<app-topheader></app-topheader>
<div class="main bookingsComponent">
    <div class="flex subheader">
        <h2></h2>
        <a routerLink="new" class="button">+ Ajouter</a>
    </div>

    <div class="block bookingSection">
        <!--    <form class="filtersForm" [formGroup]="form" *ngIf="SubdomainsService.subdomain != 'agent'">-->
        <!--      <div class="filters">-->
        <!--        <button class="clearFilters addBooking" type="button" (click)="toggleFilters()">-->
        <!--          <svg width="11" height="12" class="newPlus" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--            <path d="M10.5262 9.03547e-07H0.473891C0.296483 -0.000356014 0.133943 0.105053 0.0528966 0.272923C-0.0292677 0.443053 -0.014847 0.647923 0.0901221 0.8033L3.77287 6.32468C3.7741 6.32658 3.77544 6.32836 3.77667 6.33027C3.91048 6.52253 3.98292 6.75547 3.98326 6.99473V11.4963C3.98248 11.6295 4.03166 11.7577 4.11986 11.8522C4.20818 11.9467 4.32824 12 4.45344 12C4.51705 11.9999 4.58009 11.9864 4.6389 11.9604L6.70832 11.1207C6.89366 11.0604 7.01674 10.8742 7.01674 10.65V6.99473C7.01708 6.75547 7.08952 6.52253 7.22322 6.33027C7.22444 6.32836 7.22579 6.32658 7.22702 6.32468L10.9099 0.803181C11.0148 0.647923 11.0293 0.443172 10.9471 0.273042C10.8662 0.105053 10.7035 -0.000356014 10.5262 9.03547e-07Z" fill="white"/>-->
        <!--          </svg>-->
        <!--          {{ 'info.filters' | translate }}-->
        <!--        </button>-->
        <!--          <div class="hidedFilters" [@showFilter]="showFilterState">-->
        <!--            <div class="headerFilters">-->
        <!--              <button class="clearFilters" type="button" (click)="clearFilters()">-->
        <!--                {{ 'filters.Clear' | translate }}-->
        <!--            </button>-->
        <!--            <span>{{ 'info.filters' | translate }}</span>-->
        <!--              <button class="clearFilters addBooking" type="button" (click)="toggleFilters()">-->
        <!--                {{ 'filters.Done' | translate }}-->
        <!--              </button>-->
        <!--            </div>-->
        <!--          <div class="relative">-->
        <!--            <mat-form-field class="filterPeriod">-->
        <!--              <input matInput-->
        <!--                     placeholder="{{ 'filters.Date' | translate }}"-->
        <!--                     [satDatepicker]="picker"-->
        <!--                     (click)="picker.open()" readonly-->
        <!--                     (dateChange)="saveDate($event)"-->
        <!--                     [value]="dateRangeDisp">-->
        <!--              <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>-->
        <!--            </mat-form-field>-->
        <!--          </div>-->
        <!--          <div class="relative">-->
        <!--            <mat-select class="userFilter" formControlName="duration" (selectionChange)="submit()">-->
        <!--              <mat-option value="0" >{{ 'filters.Duration' | translate }}</mat-option>-->
        <!--              <mat-option value="1" >1 {{ 'filters.hour' | translate }}</mat-option>-->
        <!--              <mat-option value="2" >2 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="3" >3 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="4" >4 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="5" >5 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="6" >6 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="7" >7 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="8" >8 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="9" >9 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="10" >10 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="11" >11 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="12" >12 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="13" >13 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="14" >14 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="15" >15 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="16" >16 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="17" >17 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="18" >18 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="19" >19 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="20" >20 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="21" >21 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="22" >22 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="23" >23 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--              <mat-option value="24" >24 {{ 'filters.hours' | translate }}</mat-option>-->
        <!--            </mat-select>-->
        <!--          </div>-->
        <!--          <div class="relative bdgFilterField">-->
        <!--            <mat-form-field class="example-full-width">-->
        <!--              <input type="text"-->
        <!--                     placeholder="Agent"-->
        <!--                     aria-label="Number"-->
        <!--                     (change)="submit()"-->
        <!--                     matInput-->
        <!--                     [formControl]="agent"-->
        <!--                     [matAutocomplete]="auto"-->
        <!--              >-->
        <!--              <mat-autocomplete (optionSelected)="submit()" #auto="matAutocomplete">-->
        <!--                <mat-option *ngFor="let option of filteredOptions | async" [value]="option">-->
        <!--                  {{option}}-->
        <!--                </mat-option>-->
        <!--              </mat-autocomplete>-->
        <!--            </mat-form-field>-->

        <!--          </div>-->
        <!--          <div class="relative">-->
        <!--            <input placeholder="{{ 'filters.Client' | translate }}" formControlName="client" class="filterInput" (change)="submit()">-->
        <!--          </div>-->
        <!--          <div class="relative">-->
        <!--            <input placeholder="{{ 'filters.Pick up' | translate }}" formControlName="pickup" class="filterInput" (change)="submit()">-->
        <!--          </div>-->
        <!--          <div class="relative">-->
        <!--            <mat-select class="userFilter" formControlName="payment" (selectionChange)="submit()">-->
        <!--              <mat-option value="0" >{{ 'filters.Payment' | translate }}</mat-option>-->
        <!--              <mat-option value="1" >{{ 'filters.Cash' | translate }}</mat-option>-->
        <!--              <mat-option value="2" >{{ 'filters.Bank transfer' | translate }}</mat-option>-->
        <!--              <mat-option value="3" >{{ 'filters.Credit card' | translate }}</mat-option>-->
        <!--              <mat-option value="4" >{{ 'filters.PayPal' | translate }}</mat-option>-->
        <!--              <mat-option value="5" >{{ 'filters.Check' | translate }}</mat-option>-->
        <!--              <mat-option value="6" >{{ 'filters.Account' | translate }}</mat-option>-->
        <!--            </mat-select>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <a class="clearFilters addBooking" type="button" [routerLink]="['/bookings', 'new']">-->
        <!--          <svg width="12" height="14" class="newPlus" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--            <path d="M11.1601 3.8867L8.20008 0.553372C8.13775 0.48431 8.06166 0.429058 7.97669 0.391169C7.89173 0.35328 7.79978 0.333592 7.70675 0.333372H2.37341C2.15191 0.33073 1.93206 0.371745 1.72641 0.454076C1.52077 0.536407 1.33335 0.658441 1.17487 0.813208C1.01639 0.967975 0.889944 1.15245 0.80276 1.35608C0.715576 1.55972 0.669359 1.77854 0.666748 2.00004V12C0.669359 12.2215 0.715576 12.4404 0.80276 12.644C0.889944 12.8476 1.01639 13.0321 1.17487 13.1869C1.33335 13.3416 1.52077 13.4637 1.72641 13.546C1.93206 13.6283 2.15191 13.6693 2.37341 13.6667H9.62675C9.84825 13.6693 10.0681 13.6283 10.2737 13.546C10.4794 13.4637 10.6668 13.3416 10.8253 13.1869C10.9838 13.0321 11.1102 12.8476 11.1974 12.644C11.2846 12.4404 11.3308 12.2215 11.3334 12V4.33337C11.333 4.16815 11.2712 4.00897 11.1601 3.8867ZM4.00008 7.00004H6.00008C6.17689 7.00004 6.34646 7.07028 6.47149 7.1953C6.59651 7.32032 6.66675 7.48989 6.66675 7.66671C6.66675 7.84352 6.59651 8.01309 6.47149 8.13811C6.34646 8.26313 6.17689 8.33337 6.00008 8.33337H4.00008C3.82327 8.33337 3.6537 8.26313 3.52868 8.13811C3.40365 8.01309 3.33341 7.84352 3.33341 7.66671C3.33341 7.48989 3.40365 7.32032 3.52868 7.1953C3.6537 7.07028 3.82327 7.00004 4.00008 7.00004ZM8.00008 11H4.00008C3.82327 11 3.6537 10.9298 3.52868 10.8048C3.40365 10.6798 3.33341 10.5102 3.33341 10.3334C3.33341 10.1566 3.40365 9.98699 3.52868 9.86197C3.6537 9.73694 3.82327 9.66671 4.00008 9.66671H8.00008C8.17689 9.66671 8.34646 9.73694 8.47149 9.86197C8.59651 9.98699 8.66675 10.1566 8.66675 10.3334C8.66675 10.5102 8.59651 10.6798 8.47149 10.8048C8.34646 10.9298 8.17689 11 8.00008 11ZM7.80675 4.33337C7.66931 4.31984 7.54269 4.25286 7.45415 4.14687C7.36562 4.04088 7.32226 3.90436 7.33341 3.76671V1.66671L9.82675 4.33337H7.80675Z" fill="#EEEEEE"/>-->
        <!--          </svg>-->
        <!--          {{ 'info.new' | translate }}-->
        <!--        </a>-->
        <!--      </div>-->
        <!--    </form>-->
        <div class="bookingsAll noBookings"
             *ngIf="this.bookings.length === 0">{{ 'info.There are no bookings' | translate }}</div>
        <div class="bookingsAll">
            <div class="headerBookings" *ngIf="this.bookings.length != 0">
                <div class="bookingN">№</div>
                <div class="bookingDate">{{ 'bookings.Date' | translate }}</div>
                <div class="bookingDur">{{ 'filters.Duration' | translate }}</div>
                <div class="bookingDate">{{ 'booking.Client' | translate }}</div>
                <div class="bookingDate">{{ 'users.Address' | translate }}</div>
                <div class="bookingDate">{{ 'booking.Price' | translate }}</div>
            </div>
            <div class="all">
                <div style="position: relative;" *ngFor="let booking of this.bookings">
                    <a class="booking" [routerLink]="['/alarms', booking.project_id]" routerLinkActive="active">
                        <div class="bookingN">{{booking.project_id}}</div>
                        <div class="bookingDate">{{booking.start_date | date: 'MMM, dd yyyy HH:mm'}}</div>
                        <div class="bookingDur">
                            {{booking.duration / 60}}
                            <span>{{booking.duration / 60 > 1 ? 'hours' : 'hour'}}</span>
                        </div>
                        <div class="bookingDate">
                            {{booking.clientName}} {{booking.clientSurname}}
                        </div>
                        <div class="bookingDate">
                            {{booking.clientAddress}}
                        </div>
                        <div class="bookingDate">
                            {{booking.price}}
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <router-outlet class="oneBooking"></router-outlet>
</div>
