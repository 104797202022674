import {Component, OnInit, AfterViewInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Booking, BookingsService} from '../bookings.service';
import {SubdomainsService} from '../subdomain.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {MY_FORMATS} from '../booking/booking.component';
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';
import {first, map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {CookieService} from 'ngx-cookie-service';

const moment = _rollupMoment || _moment;

export interface SatDatepickerRangeValue<D> {
    begin: D | null;
    end: D | null;
}

@Component({
    selector: 'app-bookings',
    templateUrl: './bookings.component.html',
    styleUrls: ['./bookings.component.css'],

    animations: [
        trigger('hidepreloader', [
            state('start', style({
                display: 'flex',
                opacity: 1
            })),
            state('end', style({
                display: 'none',
                opacity: 0
            })),
            transition('start => end', animate(500)),
            transition('end => start', animate(500))
        ]),
        trigger('showFilter', [
            state('start', style({
                display: 'none',
                opacity: 0
            })),
            state('end', style({
                display: 'block',
                opacity: 1
            })),
            transition('start => end', animate(50)),
            transition('end => start', animate(300))
        ])
    ],
})

export class BookingsComponent implements OnInit {
    constructor(public bookingsService: BookingsService, private router: Router, private route: ActivatedRoute, private translateService: TranslateService, public SubdomainsService: SubdomainsService, private dateAdapter: DateAdapter<Date>,) {
        this.dateAdapter.setLocale('en');
        this.route.queryParams.subscribe(params => {
            console.log('params.update', params.update);
            if (params.update == 1) {
                // this.justClearFilters();
                // this.getBookings();
                this.submit();
                this.SubdomainsService.gotoBookingsPage();
            }
        });
    }

    showModalState = false;

    form: FormGroup;
    formDriver: FormGroup;
    bookings: Booking[] = [];
    hidepreloaderState = 'start';
    showFilterState = 'start';
    drivers: any;
    partners: any;
    cars: any;
    filterDateStart = 'Start date';
    filterDateEnd = 'End date';

    totalRevenue = 0;
    totalRevenueDecimal = '00';
    dollarUSLocale = Intl.NumberFormat('en-US');
    // totalBookings = 0;
    areNeedUpddate = 0;
    dateRangeDisp = {begin: Date, end: Date};

    agent = new FormControl();
    options: string[] = [];
    filteredOptions: Observable<string[]>;

    currentDate = '';
    showLoader = false;

    bookingsForInvoice = [];

    showTomorrow = false;
    showDate = true;
    sortType = 1;
    sortName = '';


    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    }

    sortBookings(name) {
        // this.showDate = false;
        // this.bookings.sort(this.sortFunction);
        if (name == this.sortName) {
            this.sortType = this.sortType == 1 ? 0 : 1;
        }
        this.sortName = name;
        this.submit();
    }

    sortFunction(a, b) {
        if (parseInt(a['price_god']) == parseInt(b['price_god'])) {
            return 0;
        } else {
            return (parseInt(a['price_god']) > parseInt(b['price_god'])) ? -1 : 1;
        }
    }

    checkIfCurrentDate(date) {
        // console.log('this.currentDate', this.currentDate, date, this.currentDate != date.slice(0, 10))
        if (this.currentDate != date.slice(0, 10)) {
            this.currentDate = date.slice(0, 10);
            return true;
        } else {
            return false;
        }
    }

    gettotalRevenue(bookings: Booking[]) {
        this.totalRevenue = 0;
        bookings.forEach(function(value) {
            if (value.status > 0) {
                this.totalRevenue = +value.real_price > 0 || +value.full_disc == 1 ? +this.totalRevenue + (+value.real_price) : +this.totalRevenue + (+value.price);
            }
        }.bind(this));
        var totalRevenueDecimal_start = this.totalRevenue.toFixed(2);
        this.totalRevenue = parseInt(totalRevenueDecimal_start.split('.')[0]);
        this.totalRevenueDecimal = totalRevenueDecimal_start.split('.')[1];
    }

    ngOnInit() {
        this.SubdomainsService.aythWithAccessKey().subscribe(response => {
            console.log('Response aythWithAccessKey', response);
            if (response.status === 'ERROR') {
                this.SubdomainsService.gotoHomePage();
            }
            if (response.user_id > 0 && response.status === '1') {
                this.SubdomainsService.gotoConfirmCodePage();
            }
        });

        this.form = new FormGroup({
            duration: new FormControl('0'),
            // agent: new FormControl(),
            client: new FormControl(),
            pickup: new FormControl(),
            partner: new FormControl('0'),
            car: new FormControl('0'),
            payment: new FormControl('0'),
            paid: new FormControl('-1'),
            startDate: new FormControl('Start date'),
            endDate: new FormControl('End date')
        });

        this.formDriver = new FormGroup({
            bookingId: new FormControl(),
            driverToChange: new FormControl(),
        });

        this.getBookings();
        this.getBodyguards();
    }

    getBodyguards() {
        if (this.SubdomainsService.subdomain == 'admin') {
            this.bookingsService.getBodyguardsForNewBooking(4).subscribe(response => {
                console.log('Response bodyguards', response);
                if (response.status == 'ERROR' && response.error.code == 133 && localStorage.getItem('access_key').length > 0 && localStorage.getItem('auth_key').length > 0) {
                    window.location.reload();
                }
                this.drivers = response.bodyguards;
                this.partners = response.partners;
                this.cars = response.cars;
                this.showTomorrow = response.showTomorrow == 1 ? true : false;
            });
        }
    }

    changeShowTomorrow() {
        this.showTomorrow = !this.showTomorrow;
        const value = this.showTomorrow ? 1 : 0;
        console.log(this.showTomorrow, value);
        this.saveSetting(value);
    }

    saveSetting(value) {
        this.bookingsService.saveSetting(value).subscribe(response => {
            console.log('Response save setting', response);
        });
    }

    saveNewDriver() {
        const formData = {...this.formDriver.value};
        this.bookingsService.updateAgent(formData.bookingId, formData.driverToChange).subscribe(response => {
            if (response.status == 'ERROR' && response.error.code == 133 && localStorage.getItem('access_key').length > 0 && localStorage.getItem('auth_key').length > 0) {
                window.location.reload();
            }
            this.showLoader = true;
            if (response) {
                this.submit();
                setTimeout(() => {
                    this.showLoader = false;
                }, 1000);
            }
        });
    }

    getBookings() {
        this.bookingsService.getBookings().subscribe(response => {
            console.log('Response bookings', response);
            this.bookingsService.bookings = response;
            this.bookings = this.bookingsService.bookings;
            // this.totalBookings = this.bookings.length;
            this.gettotalRevenue(response);
            if (response.status == 'ERROR' && response.error.code == 133 && localStorage.getItem('access_key').length > 0 && localStorage.getItem('auth_key').length > 0) {
                window.location.reload();
            } else {
                setTimeout(() => {
                    this.animateLoader();
                }, 2500);
            }
            this.bookingsForInvoice.splice(0, this.bookingsForInvoice.length);
        });
    }

    dateSelected() {
        this.filterDateStart = new Date((this.form.get('startDate').value.year + '-' + this.form.get('startDate').value.month + '-' + this.form.get('startDate').value.day).replace(/-/g, '/')).toLocaleString(this.translateService.instant('info.lng'), {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        });
        this.submit();
    }

    dateEndSelected() {
        this.filterDateEnd = new Date((this.form.get('endDate').value.year + '-' + this.form.get('endDate').value.month + '-' + this.form.get('endDate').value.day).replace(/-/g, '/')).toLocaleString(this.translateService.instant('info.lng'), {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        });
        this.submit();
    }

    submit() {
        this.currentDate = '';
        const formData = {...this.form.value};
        console.log('Value - ', this.filterDateStart, this.filterDateEnd, formData, this.agent.value);
        let parameters = '';
        if (formData.duration != null) {
            parameters = parameters + '&duration=' + formData.duration;
        }
        if (formData.pickup?.length > 0) {
            parameters = parameters + '&pickup=' + formData.pickup;
        }
        if (this.agent.value?.length > 0) {
            parameters = parameters + '&agent=' + this.agent.value.trim();
        }
        if (formData.client?.length > 0) {
            parameters = parameters + '&client=' + formData.client.trim();
        }
        if (formData.payment != null) {
            parameters = parameters + '&payment=' + formData.payment;
        }
        if (formData.paid != null) {
            parameters = parameters + '&paid=' + formData.paid;
        }
        if (formData.partner != null) {
            parameters = parameters + '&partner=' + formData.partner;
        }
        if (formData.car != null) {
            parameters = parameters + '&car=' + formData.car;
        }

        if (this.filterDateStart != null) {
            console.log('startDate', formData.startDate.year + '-' + formData.startDate.month + '-' + formData.startDate.day);
            parameters = parameters + '&startDate=' + formData.startDate.year + '-' + formData.startDate.month + '-' + formData.startDate.day;
        }

        if (this.filterDateEnd != null) {
            console.log('endDate', +formData.endDate.year + '-' + formData.endDate.month + '-' + formData.endDate.day);
            parameters = parameters + '&endDate=' + formData.endDate.year + '-' + formData.endDate.month + '-' + formData.endDate.day;
        }

        parameters = parameters + '&sortType=' + this.sortType + '&sortName=' + this.sortName;

        console.log('parameters', parameters);
        this.bookingsService.getBookingsWithParameters(parameters).subscribe(response => {
            console.log('Response bookings getBookingsWithParameters', response);
            this.bookingsService.bookings = response;
            this.gettotalRevenue(response);
            setTimeout(() => {
                this.animateLoader();
            }, 2500);
            this.bookingsForInvoice.splice(0, this.bookingsForInvoice.length);
        });
    }

    goToBooking(event, url, agent, bookingId) {
        console.log(event.target.className);
        if (event.target.tagName == 'BUTTON') {
            this.showModalState = true;
            this.formDriver.patchValue({
                bookingId,
                driverToChange: agent,
            });
        } else if (event.target.className.includes('mat-checkbox-inner-container') || event.target.className.includes('invoiceSection') || event.target.className.includes('mat-checkbox')) {
            // prevent click
        } else {
            this.SubdomainsService.goToPage(url);
        }
    }

    markBooking(event, booking) {
        console.log(event.checked, booking);
        if (event.checked) {
            this.bookingsForInvoice.push(booking);
        } else {
            const index = this.bookingsForInvoice.indexOf(booking);
            if (index !== -1) {
                this.bookingsForInvoice.splice(index, 1);
            }
        }
        console.log(this.bookingsForInvoice);
    }

    gen() {
        if (this.bookingsForInvoice.length == 0) {
            return;
        }
        this.animateLoader();
        let invoiceId: any;
        this.bookingsService.saveInvoice(this.bookingsForInvoice[0].id, 1, this.bookingsForInvoice[0].client_name, this.bookingsForInvoice[0].client_phone,
            this.bookingsForInvoice[0].client_email, '', '', this.bookingsForInvoice[0].paid,
            this.bookingsForInvoice[0].payment_method_id, '0', '0', null).subscribe(response => {
            console.log('first invoice generated', response);
            invoiceId = +response.invoice_id;
            this.bookingsForInvoice.forEach((element, index) => {
                if (index != 0) {
                    this.bookingsService.addBookingInExistInvoice(element.id, invoiceId).subscribe(response => {
                        console.log('added to invoice', response);
                        if (index == (this.bookingsForInvoice.length - 1)) {
                            this.submit();
                        }
                    });
                }
            });

        });

        // setTimeout(() => {
        //     this.showLoader = false;
        // }, 1000);
    }

    toggleFilters() {
        this.showFilterState = this.showFilterState === 'end' ? 'start' : 'end';
    }

    clearFilters() {
        this.form.patchValue({
            startDate: '',
            endDate: '',
            // duration: '0',
            // agent: new FormControl(),
            client: '',
            pickup: '',
            payment: '0',
            partner: '0',
        });
        this.filterDateStart = 'Start date';
        this.filterDateEnd = 'End date';
        this.agent.patchValue('');
        // this.dateRangeDisp = {begin: Date, end: Date};
        this.getBookings();
    }

    justClearFilters() {
        this.form.patchValue({
            duration: '0',
            // agent: new FormControl(),
            client: '',
            pickup: '',
            payment: '0',
            partner: '0',
        });
        this.agent.patchValue('');
        this.dateRangeDisp = {begin: Date, end: Date};
    }

    animateLoader() {
        this.hidepreloaderState = 'end';
    }
}
