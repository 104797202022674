import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {BookingsService} from '../bookings.service';
import {SubdomainsService} from '../subdomain.service';
import {UsersService} from '../users.service';
import {PhoneFlagsService} from '../phoneFlags.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {formatDate} from '@angular/common';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
// @ts-ignore
import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {TranslateService} from '@ngx-translate/core';
import {CountryISO, PhoneNumberFormat, SearchCountryField} from 'ngx-intl-tel-input';

const moment = _rollupMoment || _moment;

export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'MMM DD, YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'll',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-dashboard',
    templateUrl: './booking.component.html',
    styleUrls: ['./booking.component.css'],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },

        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
    animations: [
        trigger('hidepreloader', [
            state('start', style({
                display: 'flex',
                opacity: 1
            })),
            state('end', style({
                display: 'none',
                opacity: 0
            })),
            transition('start => end', animate(100))
        ]),
        trigger('showInvoiceMode', [
            state('start', style({
                display: 'none',
                opacity: 0
            })),
            state('end', style({
                display: 'block',
                opacity: 1
            })),
            transition('end => start', animate(400)),
            transition('start => end', animate(50))
        ])
    ],
})

export class BookingComponent implements OnInit {
    constructor(private _adapter: DateAdapter<any>, private route: ActivatedRoute, private translate: TranslateService, private bookingsService: BookingsService, public PhoneFlagsService: PhoneFlagsService, private router: Router, public SubdomainsService: SubdomainsService, private UsersService: UsersService) {

    }

    regEx = /[^\d]/g;
    separateDialCode = true;
    SearchCountryField = SearchCountryField;
    CountryISO = CountryISO;
    PhoneNumberFormat = PhoneNumberFormat;

    preferredCountries: CountryISO[] = [CountryISO.France, CountryISO.UnitedStates,
        CountryISO.UnitedKingdom, CountryISO.Russia];

    isChoosenBodyguard = false;
    locale = 'fr';
    booking: any;
    currentBooking: any;
    bodyguards: any;
    partners: any;
    allBodyguardsCi: any;
    placesCi: any;
    places: any;
    form: FormGroup;
    formExistInvoice: FormGroup;
    formNewInvoice: FormGroup;
    formNew: FormGroup;

    isPhoneFocused = false;
    currentBookingId: string;
    canClose = false;
    dats: any;
    dats1: any;
    dats2: any;
    hour1: any;
    hour2: any;
    min1: any;
    saveSaved = 'Save';
    addInExistInvoiceText = 'Add booking in this invoice';
    addNewInvoiceText = 'Generate invoice';
    updateInvoiceText = 'Update';
    sendInvoiceText = 'Send';
    viewInvoiceText = 'View';

    commentInvoice = '';
    createdInvoice = '';
    isSentInvoice = '';

    @ViewChild('opened') opened: ElementRef;
    @ViewChild('place') placesRef: ElementRef;
    @ViewChild('placeDrop') placesDropRef: ElementRef;
    @ViewChild('clientIAddress') clientIAddress: ElementRef;
    @ViewChild('placePickUp') placePickUp: ElementRef;

    hidepreloaderState = 'start';
    showModalState = false;
    showDeleteWindow = false;
    bookingToDelete = '';
    showBookingState = 'end';
    showBookingBackState = 'end';
    showInvoiceModeState = 'start';
    showAddBodyguardState = 'start';
    invoiceMode = '';
    invoiceNotExistMessage = false;
    currentInvoiceId = '0';

    newAgentId = '0';
    newAgentName = '';
    newAgentType = '4';
    newAgentImg = '';
    globBodyguardType = '';

    clientPhone = '';

    // message
    messageForm: FormGroup;
    messageShow = false;
    messageId = '0';
    messageUserName = '0';
    messageUserEmail = '0';
    messageUserPhone = '0';
    sendSmsText = 'Send SMS';
    sendEmailText = 'Send eMail';

    showLoader = false;

    today = new Date();

    selectedDate = '';

    selectedDateBack = '';

    showAgentList = false;
    showPickUpList = false;
    showDropOffList = false;
    driverAlertShow = false;
    driverAlertTxt = '';
    delPl = false;

    driverBtnText = ['I\'m on way', 'Start ride', 'Finish ride'];

    cars = [];
    carsArray = [];

    showName = 0;

    updateDriverStatus(bookingId, currentStatus) {
        this.animateLoader();
        this.bookingsService.updateDriverStatus(bookingId, currentStatus).subscribe(response => {
            console.log('Response update status', response);
            if (response == false) {
                this.animateLoader();
                this.driverAlertShow = true;
                this.driverAlertTxt = 'Something went wrong.\nPlease try again.';
            } else {
                if (currentStatus == 2 && this.currentBooking.payment_method_id == 1) {
                    this.driverAlertShow = true;
                    var p = this.currentBooking.real_price > 0 || this.currentBooking.full_disc == 1 ? this.currentBooking.real_price : this.currentBooking.price;
                    this.driverAlertTxt = 'You need take cash for this ride\n\n' + p + '€';
                }
                currentStatus == 3 ? this.closeBooking() : this.getBooking(bookingId);
            }
        });

    }

    dateSelected() {
        this.selectedDate = new Date((this.form.get('startDate').value.year + '-' + this.form.get('startDate').value.month + '-' + this.form.get('startDate').value.day).replace(/-/g, '/')).toLocaleString(this.translate.instant('info.lng'), {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        });
        this.submit();
    }

    dateSelectedBackAction() {
        this.selectedDateBack = new Date(this.form.get('backDate').value.year + '-' + this.form.get('backDate').value.month + '-' + this.form.get('backDate').value.day).toLocaleString(this.translate.instant('info.lng'), {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        });
        this.submit();
    }

    public handleclientIAddressChange(address: Address) {
        this.formNewInvoice.patchValue({
            client_address: address.formatted_address
        });
        // setTimeout(() => {
        //     this.submit();
        // }, 300);
    }

    closeBooking() {
        this.router.navigate(['/bookings'], {queryParams: {update: '1'}});
    }

    triggerDeleteBookingModal(id) {
        this.showModalState = true;
        this.showDeleteWindow = true;
        this.bookingToDelete = id;
    }

    deleteBooking(id) {
        this.animateLoaderBack();
        this.UsersService.deleteBooking(id).subscribe(response => {
            console.log('deleteBooking', response);
            if (response.res) {
                setTimeout(() => {
                    this.router.navigate(['/bookings'], {queryParams: {update: '1'}});
                }, 500);
            } else {
                this.animateLoader();
            }
        });
    }

    messageClicked(e, id, name, email, phone) {
        this.messageForm.patchValue({
            message: this.translate.instant('booking.Hello') + ' ' + name + '! ',
        });
        console.log(id + ' + ' + name);
        this.messageId = id;
        this.messageUserName = name;
        this.messageUserEmail = email;
        this.messageUserPhone = phone;
        this.messageShow = true;
    }

    closeMessage() {
        this.messageShow = false;
    }

    messageFormSubmit(transport) {
        if (transport == 'sms') {
            this.sendSmsText = 'Sending...';
        } else {
            this.sendEmailText = 'Sending...';
        }
        const messageFormData = {...this.messageForm.value};
        console.log('messageFormData - ', messageFormData);
        this.UsersService.sendMessage(this.messageId, this.messageUserEmail, this.messageUserPhone, transport, messageFormData.message).subscribe(response => {
            console.log('Response message', response);
            if (transport == 'sms') {
                this.sendSmsText = 'Sent';
            } else {
                this.sendEmailText = 'Sent';
            }
            setTimeout(() => {
                if (transport == 'sms') {
                    this.sendSmsText = 'Send SMS';
                } else {
                    this.sendEmailText = 'Send eMail';
                }
            }, 1000);
        });
    }

    calcPrice() {
        if (this.form.get('bookType').value == 3) {
            var d = this.form.get('duration').value;
            var dm = this.form.get('durationMin').value;
            var c = this.form.get('car').value;
            var pph = this.cars[c].price_per_hour;
            var value = pph * d + pph * dm / 60;
            this.form.get('price').setValue(value.toFixed(2));
            this.submit();
        } else {
            this.submit();
        }
    }

    ngOnInit() {
        this.getCars();
        // if (this.SubdomainsService.subdomain == 'agent') {
        //     this.router.navigate(['/bookings']);
        // }
        this.translate.stream('info.lng').subscribe((value) => {
            this._adapter.setLocale(value);
        });

        this.messageForm = new FormGroup({
            message: new FormControl('Hello ' + this.messageUserName),
        });

        this.form = new FormGroup({
            bookType: new FormControl(1),
            status: new FormControl(1),
            sourceId: new FormControl('1'),
            partner: new FormControl(''),
            startDate: new FormControl(moment.locale('fr'), [Validators.required]),
            backDate: new FormControl(moment.locale('fr'), [Validators.required]),
            timeHourBack: new FormControl('', [Validators.required]),
            timeMinBack: new FormControl('', [Validators.required]),
            timeHour: new FormControl('', [Validators.required]),
            timeMin: new FormControl('', [Validators.required]),
            endDate: new FormControl(moment.utc(), [Validators.required]),
            timeHourEnd: new FormControl('', [Validators.required]),
            duration: new FormControl('', [Validators.required]),
            durationMin: new FormControl(''),
            placePickUp: new FormControl('', [Validators.required]),
            pickup: new FormControl(''),
            placeDropOff: new FormControl('', [Validators.required]),
            dropoff: new FormControl(''),
            paymentMethod: new FormControl('', [Validators.required]),
            currency: new FormControl('1'),
            price: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]+$')]),
            realPrice: new FormControl(null),
            tips: new FormControl(null, [Validators.required, Validators.pattern('^[0-9]+$')]),
            fullDisc: new FormControl(null),
            info: new FormControl(),
            clientName: new FormControl('', [Validators.required]),
            clientEmail: new FormControl('', [Validators.required]),
            clientPhone: new FormControl('', [Validators.required]),
            agentType: new FormControl('1', [Validators.required]),
            workHour: new FormControl(1),
            workMin: new FormControl(30),
            car: new FormControl('1'),
            passengers: new FormControl('1'),
            agent: new FormControl(''),
            agentName: new FormControl(''),
        });
        this.formExistInvoice = new FormGroup({
            existINumber: new FormControl('', [Validators.required]),
        });
        this.formNewInvoice = new FormGroup({
            client_name: new FormControl('', [Validators.required]),
            client_phone: new FormControl('', [Validators.required]),
            client_email: new FormControl('', [Validators.required]),
            client_address: new FormControl(''),
            comment: new FormControl(''),
            paid: new FormControl(''),
            currency: new FormControl(''),
            payment_method: new FormControl('', [Validators.required]),
        });
        setTimeout(() => {
            this.animateLoaderBack();
        }, 100);
        setTimeout(() => {
            this.canClose = true;
            this.checkDats();
        }, 1000);

        this.getBodyguardsForNewBooking('4');
        this.getPlaces();

        this.route.params.subscribe((params: Params) => {
            if (params.id == 'new') {
                // save new booking
                this.animateLoader();
                this.submitNewBooking();
            } else {
                this.currentBookingId = params.id;
                this.getBooking(params.id);
            }
        });

    }

    checkDats() {
        this.dats1 = moment(this.form.get('startDate').value).format('DD-MM-YYYY');
        this.hour1 = this.form.get('timeHour').value;
        this.hour2 = this.form.get('timeHourEnd').value;
        this.min1 = this.form.get('timeMin').value;
        this.dats2 = moment(this.form.get('endDate').value).format('DD-MM-YYYY');
        this.dats = this.dats1 == this.dats2;
        setTimeout(() => {
            if (this.dats && this.hour2 <= this.hour1) {
                this.form.get('timeHourEnd').setValue('');
            }
        }, 100);
    }

    flagChanged() {
        const formData = {...this.formNew.value};
        this.PhoneFlagsService.setFlag(formData.flag);
    }

    changeBookingType(type) {
        this.form.patchValue({
            bookType: type,
        });
        this.submit();
    }

    changeCountry(e) {
        this.form.patchValue({
            clientPhone: '+' + e.dialCode,
        });
    }

    changeCountryInvoice(e) {
        this.formNewInvoice.patchValue({
            client_phone: '+' + e.dialCode,
        });
    }

    getBooking(id, animate = true) {
        this.bookingsService.getBooking(id).subscribe(response => {
            console.log('Response booking', response, response[0], response.status);
            if (response.status == 'ERROR' && response.error.code == 133 && localStorage.getItem('access_key').length > 0 && localStorage.getItem('auth_key').length > 0) {
                window.location.reload();
            }
            if (response[0] == false) {
                this.SubdomainsService.gotoBookingsPage();
            }
            this.currentBooking = response;
            response.bodyguard_type == 4 ? this.form.get('dropoff').setValidators([Validators.required]) : this.form.get('dropoff').clearValidators();
            if (response.client_phone) {
                // this.clientPhone = response.client_phone;
                // detect mask here
            }
            this.form.patchValue({
                bookType: response.booking_type ? response.booking_type : null,
                status: response.status ? response.status : 0,
                sourceId: response.source_id, // == '1' ? response.source_id : '2'
                partner: response.partner,
                startDate: {
                    year: parseInt(response.b_start_date?.slice(0, 4)),
                    month: parseInt(response.b_start_date?.slice(5, 7).replace(/^\0/, '')),
                    day: parseInt(response.b_start_date?.slice(8, 10).replace(/^\0/, ''))
                },
                backDate: {
                    year: parseInt(response.b_back_date?.slice(0, 4)),
                    month: parseInt(response.b_back_date?.slice(5, 7).replace(/^\0/, '')),
                    day: parseInt(response.b_back_date?.slice(8, 10).replace(/^\0/, ''))
                },
                endDate: response.end_date ? moment.utc(response.end_date) : moment.utc(new Date()), // new Date(value.b_start_date),
                timeHour: response.b_start_date ? formatDate(response.b_start_date, 'HH', 'en', 'UTC') : formatDate(new Date(), 'HH', 'en', 'UTC'),
                timeHourBack: response.b_back_date ? formatDate(response.b_back_date, 'HH', 'en', 'UTC') : formatDate(new Date(), 'HH', 'en', 'UTC'),
                timeHourEnd: response.end_date ? formatDate(response.end_date, 'HH', 'en', 'UTC') : formatDate(new Date(), 'HH', 'en', 'UTC'),
                timeMin: formatDate(response.b_start_date, 'mm', 'en', 'UTC'),
                timeMinBack: response.b_back_date ? formatDate(response.b_back_date, 'mm', 'en', 'UTC') : '',
                duration: response.duration,
                durationMin: response.duration_min,
                paymentMethod: response.payment_method_id,
                pickup: response.pl_name,
                price: response.price > 0 ? response.price : null,
                realPrice: response.real_price,
                tips: response.tax > 0 ? response.tax : null,
                fullDisc: response.full_disc == 1,
                info: response.info ? response.info : '',
                clientName: response.user_name,
                clientEmail: response.user_email,
                clientPhone: response.user_phone, //parseInt(response.user_phone),
                agent: response.bi_bodyguard_id ? response.bi_bodyguard_id : 0,
                agentName: response.buser_full_name ? response.buser_full_name : '',
                agentType: response.bodyguard_type ? response.bodyguard_type : '1',
                car: response.car ? response.car : '0',
                workHour: Math.floor(+response.work_hours / 60) + '',
                workMin: +response.work_hours % 60 == 0 ? '00' : +response.work_hours % 60 + '',
                passengers: response.passengers ? response.passengers : '1',
                placeDropOff: response.drop_off_place,
                placePickUp: response.pick_up_place,
            });
            if (response.partner > 0) {
                this.form.patchValue({
                    clientEmail: response.partner_email,
                });
            }
            console.log('response.work_hours', Math.floor(+response.work_hours / 60), +response.work_hours / 60, +response.work_hours % 60);
            this.selectedDate = new Date(response.b_start_date).toLocaleString(this.translate.instant('info.lng'), {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                timeZone: 'UTC',
            });

            this.selectedDateBack = response.b_back_date ? new Date(response.b_back_date).toLocaleString(this.translate.instant('info.lng'), {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                timeZone: 'UTC',
            }) : new Date(response.b_start_date).toLocaleString(this.translate.instant('info.lng'), {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
                timeZone: 'UTC',
            });

            if (response.client_name) {
                this.form.patchValue({
                    clientName: response.client_name
                });
            }
            if (response.client_email) {
                this.form.patchValue({
                    clientEmail: response.client_email
                });
            }
            if (response.client_phone) {
                this.form.patchValue({
                    clientPhone: response.client_phone // parseInt(response.client_phone)
                });
            }

            if (response.end_date == null || response.end_date.toString() == '0000-00-00T00:00:00Z') {
                const hours = Math.floor(Number.parseInt(response.duration) * 60 * 60 * 1000);
                const diff = new Date(response.b_start_date).getTime() + hours;
                const end = diff;

                this.form.patchValue({
                    endDate: moment.utc(end),
                    timeHourEnd: formatDate(end, 'HH', 'en', 'UTC'),
                });
            }
            if (response.pick_up) {
                this.form.patchValue({
                    pickup: response.pick_up
                });
            }
            if (response.drop_off) {
                this.form.patchValue({
                    dropoff: response.drop_off
                });
            }
            if (response.invoice_id) {
                this.invoiceMode = 'new';
                this.currentInvoiceId = response.invoice_id;
                this.formNewInvoice.patchValue({
                    client_name: response.invoice_info.client_name,
                    client_phone: response.invoice_info.client_phone,
                    client_email: response.invoice_info.client_email,
                    client_address: response.invoice_info.client_address,
                    payment_method: response.invoice_info.payment_method,
                    comment: response.invoice_info.comment,
                    currency: response.invoice_info.currency,
                    paid: response.paid == '1',
                });
                this.commentInvoice = response.invoice_info.comment;
                this.createdInvoice = response.invoice_info.created;
                this.isSentInvoice = response.invoice_info.is_sent;
                if (response.invoice_info.is_sent == '1') {
                    this.sendInvoiceText = 'Already sent';
                }
            } else {
                this.formNewInvoice.patchValue({
                    client_name: response.user_name,
                    client_phone: response.user_phone,
                    client_email: response.user_email,
                    client_address: response.user_address,
                    payment_method: response.payment_method_id,
                });
                if (response.client_name) {
                    this.formNewInvoice.patchValue({
                        client_name: response.client_name,
                    });
                }
                if (response.client_email) {
                    this.formNewInvoice.patchValue({
                        client_email: response.client_email,
                    });
                }
                if (response.client_phone) {
                    this.formNewInvoice.patchValue({
                        client_phone: response.client_phone,
                    });
                }

                if (response.partner_name) {
                    this.formNewInvoice.patchValue({
                        client_name: response.partner_name,
                    });
                }
                if (response.partner_email) {
                    this.formNewInvoice.patchValue({
                        client_email: response.partner_email,
                    });
                }
                if (response.partner_phone) {
                    this.formNewInvoice.patchValue({
                        client_phone: response.partner_phone,
                    });
                }
            }
            if (animate) {
                this.animateLoader();
            }
            setTimeout(() => {
                this.checkDats();
            }, 1000);
        });
    }

    createRange(num, range = 1) {
        return new Array(num / range);
    }

    getBodyguardsForNewBooking(type) {
        this.globBodyguardType = type;
        this.bookingsService.getBodyguardsForNewBooking(type).subscribe(response => {
            console.log('newGetBodyguardsForNewBooking', response);
            this.allBodyguardsCi = response.bodyguards;
            this.bodyguards = response.bodyguards;
            this.partners = response.partners;
        });
    }

    getPlaces() {
        this.bookingsService.getPlaces().subscribe(response => {
            console.log('places', response.places);
            this.placesCi = response.places;
            this.places = response.places;
        });
    }

    deletePlace(name, place) {
        this.placePickUp.nativeElement.focus();
        const index = this.placesCi.indexOf(place);
        if (index !== -1) {
            this.placesCi.splice(index, 1);
        }
        const index2 = this.places.indexOf(place);
        if (index2 !== -1) {
            this.places.splice(index2, 1);
        }
        this.bookingsService.getPlaces(name).subscribe(response => {
            console.log('places del', response);
            this.delPl = false;
        });
    }

    public _filter(value: string): string[] {
        const filterValue = this.form.get(value).value.toLowerCase();
        return this.allBodyguardsCi.filter(it => {
            return it.full_name.toLowerCase().includes(filterValue);
        });
    }

    filter(value) {
        console.log('this._filter(value)', this._filter(value).length, this.bodyguards);
        if (this._filter(value).length == 0) {
            this.bodyguards = [{id: 0, full_name: 'There is no user'}];
        } else {
            this.bodyguards = this._filter(value);
        }
    }

    public _filterPlaces(value: string): string[] {
        const filterValue = this.form.get(value).value.toLowerCase();
        return this.placesCi.filter(it => {
            return it.name.toLowerCase().includes(filterValue);
        });
    }

    filterPlaces(value) {
        console.log('this._filter(value)', this._filterPlaces(value).length, this.placesCi, this.places);
        if (this._filterPlaces(value).length == 0) {
            this.places = [{name: 'No this place'}];
        } else {
            this.places = this._filterPlaces(value);
        }
    }

    patchPlace(formControl, name) {
        if (name != 'No this place') {
            this.form.get(formControl).setValue(name);
            this.submit();
        }
        formControl == 'placePickUp' ? this.showPickUpList = false : this.showDropOffList = false;
    }

    patchAgent(userId, userName) {
        if (userId > 0) {
            this.form.get('agent').setValue(userId);
            this.form.get('agentName').setValue(userName);
            this.submit();
        }
        this.showAgentList = false;
    }

    selectAgent(id, fullName, bimgImg, typeId) {

        this.newAgentId = id;
        this.newAgentName = fullName;
        this.newAgentType = typeId;
        console.log('typeId', typeId);
        if (typeId == 4) {
            this.form.get('dropoff').setValidators([Validators.required]);
            this.formNew.get('dropoff').setValidators([Validators.required]);
        } else {
            this.form.get('dropoff').clearValidators();
            this.form.get('dropoff').updateValueAndValidity();

            this.formNew.get('dropoff').clearValidators();
            this.formNew.get('dropoff').updateValueAndValidity();
        }
        //this.form.get("dropoff").clearValidators();
        this.newAgentImg = bimgImg;
        this.isChoosenBodyguard = true;

        this.showAddBodyguardForm();

        // this.calcDurationNew();
    }

    onScroll() {
        this.placesRef.nativeElement.blur();
        this.placesDropRef.nativeElement.blur();
        this.clientIAddress.nativeElement.blur();
    }

    animateLoader() {
        this.hidepreloaderState = this.hidepreloaderState === 'end' ? 'start' : 'end';
    }

    showAddBodyguardForm() {
        this.showAddBodyguardState = this.showAddBodyguardState === 'end' ? 'start' : 'end';
    }

    showInvoiceMode() {
        this.invoiceMode = 'new';
        this.showInvoiceModeState = this.showInvoiceModeState === 'end' ? 'start' : 'end';
    }

    animateLoaderBack() {
        this.showBookingState = 'start';
        this.showBookingBackState = 'start';
    }

    animateLoaderBackBack() {
        this.showBookingState = 'end';
        this.showBookingBackState = 'end';
    }

    getCars() {
        this.bookingsService.getCars().subscribe(response => {
            console.log('cars +++ ', response);
            this.cars = response;

            this.foreachCars(response);
        });
    }

    foreachCars(cars) {
        let cars1 = Object.values(cars);
        for (let i = 0; i < cars1.length; i++) {
            this.carsArray.push(cars1[i]);
        }

        let newarr = this.carsArray.sort((a, b) => a.price_h - b.price_h);

        this.carsArray = newarr;
    }

    // calcDurationNew() {
    //     const formData = {...this.formNew.value};
    //     console.log('formData.startDate', formData.endDate);
    //     if (formData.endDate == '') {
    //         this.formNew.patchValue({
    //             endDate: formData.startDate,
    //         });
    //     }
    //     const dt1 = formData.startDate.format('YYYY-MM-DD').slice(0, 10) + ' ' + formData.timeHour + ':' + formData.timeMin + ':00';
    //     const dt2 = formData.endDate.format('YYYY-MM-DD').slice(0, 10) + ' ' + formData.timeHourEnd + ':' + formData.timeMin + ':00';
    //     const diff = new Date(dt2).getTime() - new Date(dt1).getTime();
    //     const hours = Math.floor(diff / (60 * 60 * 1000));
    //     let priceH: any;
    //     if (this.newAgentType === '1') {
    //         priceH = 27;
    //     } else if (this.newAgentType === '2') {
    //         priceH = 30;
    //     } else if (this.newAgentType === '3') {
    //         priceH = 45;
    //     } else if (this.newAgentType === '4') {
    //         priceH = 80;
    //     } else if (this.newAgentType === '5') {
    //         priceH = 52;
    //     }
    //
    //     this.formNew.patchValue({
    //         duration: '' + hours,
    //         price: '' + priceH * hours
    //     });
    //
    // }
    //
    // calcMinNew() {
    //     const formData = {...this.formNew.value};
    //     this.formNew.patchValue({
    //         timeMin: formData.timeMin
    //     });
    // }
    //
    // calcDuration() {
    //     // this.submit();
    //     setTimeout(() => {
    //         if (!this.dats || this.dats && this.hour2 > this.hour1 && this.hour2 != '') {
    //             this.submit();
    //         }
    //     }, 1000);
    //     const formData = {...this.form.value};
    //
    //     const dt1 = formData.startDate.format('YYYY-MM-DD').slice(0, 10) + ' ' + formData.timeHour + ':' + formData.timeMin + ':00';
    //     const dt2 = formData.endDate.format('YYYY-MM-DD').slice(0, 10) + ' ' + formData.timeHourEnd + ':' + formData.timeMin + ':00';
    //     const diff = new Date(dt2).getTime() - new Date(dt1).getTime();
    //     const hours = Math.floor(diff / (60 * 60 * 1000));
    //
    //     this.form.patchValue({
    //         duration: '' + hours
    //     });
    //
    // }
    // calcMin() {
    //     const formData = {...this.form.value};
    //     console.log(formData.timeMin);
    //     this.form.patchValue({
    //         timeMin: formData.timeMin
    //     });
    // }

    hideList() {
        console.log(this.delPl);
        setTimeout(() => {
            this.showAgentList = false;
            if (!this.delPl) {
                this.showPickUpList = false;
                this.showDropOffList = false;
            }
        }, 200);
    }

    submit() {
        this.showLoader = true;
        const formData = {...this.form.value};
        console.log(formData, ' ++++++++++++++++ ', formData.startDate);
        const endDate = formData.endDate.format('YYYY-MM-DD').slice(0, 10) + ' ' + formData.timeHourEnd + ':' + formData.timeMin + ':00';
        const phone = formData.clientPhone?.number ? formData.clientPhone.number : '';
        // tslint:disable-next-line:max-line-length
        this.bookingsService.updateBooking(this.currentBookingId, formData.bookType, formData.sourceId, formData.status, formData.clientName, formData.clientEmail, phone, formData.duration, formData.durationMin,
            formData.startDate.year + '-' + formData.startDate.month + '-' + formData.startDate.day, formData.timeHour, formData.timeMin, formData.backDate.year + '-' + formData.backDate.month + '-' + formData.backDate.day, formData.timeHourBack, formData.timeMinBack, endDate,
            formData.placePickUp, formData.pickup, formData.placeDropOff, formData.dropoff, formData.paymentMethod, formData.price, formData.realPrice, formData.tips, formData.info, formData.agentType, formData.agent,
            formData.car, +formData.workHour * 60 + +formData.workMin, formData.passengers, formData.partner, formData.fullDisc).subscribe(response => {
            console.log('Response update booking', response);
            if (response) {
                setTimeout(() => {
                    this.showLoader = false;
                }, 1000);
                this.isChoosenBodyguard = false;
                this.getBooking(this.currentBookingId, false);
            }
        });
    }

    submitNewBooking() {
        this.animateLoader();
        const formData = {...this.form.value};
        const phone = formData.clientPhone?.number ? formData.clientPhone.number : '+33';
        this.bookingsService.addBooking(formData.status, formData.bookType, formData.sourceId, formData.clientName, formData.clientEmail, phone, formData.placePickUp, formData.pickup, formData.placeDropOff, formData.dropoff,
            new Date().toLocaleString(), new Date().toLocaleString(), formData.timeHour, formData.timeMin, new Date().toLocaleString(), formData.duration, formData.durationMin, formData.paymentMethod, formData.price, formData.realPrice, formData.info, this.newAgentType, this.newAgentId, null, null).subscribe(response => {
            this.router.navigate(['/bookings', response.booking]);
        });
    }

    duplicateBooking(relatedBooking = null) {
        this.animateLoader();
        const formData = {...this.form.value};
        console.log(formData);
        const phone = formData.clientPhone?.number ? formData.clientPhone.number : '';
        this.bookingsService.addBooking(formData.status, formData.bookType, formData.sourceId, formData.clientName, formData.clientEmail, phone, formData.placePickUp, formData.pickup, formData.placeDropOff, formData.dropoff,
            formData.startDate.year + '-' + formData.startDate.month + '-' + formData.startDate.day + ' ' + formData.timeHour + ':' + formData.timeMin,
            new Date().toLocaleString(), formData.timeHour, formData.timeMin, new Date().toLocaleString(), formData.duration, formData.durationMin, formData.paymentMethod, formData.price, formData.realPrice, formData.info, this.newAgentType,
            this.newAgentId, relatedBooking, formData.partner).subscribe(response => {
            console.log('response NEW BOOKING', response);
            this.router.navigate(['/bookings', response.booking]);
        });
    }

    addInExistInvoice() {
        if (this.formExistInvoice.invalid) {
            return;
        }
        // this.animateLoader();
        this.addInExistInvoiceText = 'Adding...';
        const formData = {...this.formExistInvoice.value};
        this.bookingsService.addBookingInExistInvoice(this.currentBookingId, formData.existINumber).subscribe(response => {
            if (response.add == false) {
                // invoice not exist
                // this.animateLoader();
                this.addInExistInvoiceText = 'Add booking in this invoice';
                this.invoiceNotExistMessage = true;
            } else {
                // this.animateLoader();
                this.addInExistInvoiceText = 'Added';
                this.showInvoiceMode();
                this.getBooking(this.currentBookingId, false);
                setTimeout(() => {
                    this.addInExistInvoiceText = 'Add booking in this invoice';
                }, 1000);
            }
        });
    }

    test() {
        console.log(this.form.get('clientPhone').value);
    }

    cutAZ(control) {
        const pattern = /\D/;
        if (pattern.test(this.form.get(control).value)) {
            console.log(this.form.get(control).value.slice(0, -1));
            this.form.get(control).setValue(this.form.get(control).value.slice(0, -1));
        }
    }

    saveInvoice(mode, send = null) {
        if (this.formNewInvoice.invalid) {
            return;
        }
        this.showLoader = true;
        const formData = {...this.formNewInvoice.value};
        console.log('formData invoice', formData);
        const phone = formData.client_phone?.number ? formData.client_phone.number : '';
        this.bookingsService.saveInvoice(this.currentBookingId, formData.currency, formData.client_name, phone, formData.client_email, formData.client_address, formData.comment, formData.paid, formData.payment_method, mode, this.currentInvoiceId, send).subscribe(response => {
            console.log('111 --- response invoice save', response);
            this.getBooking(this.currentBookingId, false);
            setTimeout(() => {
                this.showLoader = false;
            }, 1000);
        });
    }

    deleteInvoice(id) {
        this.showLoader = true;
        this.bookingsService.deleteInvoice(id).subscribe(response => {
            console.log('response invoice delete', response);
            this.getBooking(this.currentBookingId, false);
            setTimeout(() => {
                this.showLoader = false;
            }, 1000);
        });
    }

    viewInvoice(id) {
        window.open('https://1eyeonu.com/displayInvoice.php?id=' + id + '&PHPSESSID=' + localStorage.getItem('access_key'), '_blank');
    }

    sendInvoice() {
        this.saveInvoice('1', '1');
    }

    share() {
        const d = new Date((this.form.get('startDate').value.year + '-' + this.form.get('startDate').value.month + '-' + this.form.get('startDate').value.day).replace(/-/g, '/')).toLocaleString(this.translate.instant('info.lng'), {
            day: '2-digit',
            month: 'long',
        });
        const phone = this.form.get('clientPhone')?.value ? '%2B' + this.form.get('clientPhone')?.value.number.replace('+', '') : '';
        const link = 'Hello! Booking at ' + d + ', ' + this.form.get('timeHour')?.value + ':' + this.form.get('timeMin')?.value
            + '%0A' + 'Name: ' + this.form.get('clientName')?.value
            + '%0A' + 'Phone: ' + phone
            + '%0A' + 'From: ' + this.form.get('placePickUp')?.value?.replace('&', '%26') + ' ' + this.form.get('pickup')?.value?.replace('&', '%26')
            + '%0A' + 'To: ' + this.form.get('placeDropOff')?.value?.replace('&', '%26') + ' ' + this.form.get('dropoff')?.value?.replace('&', '%26')
            + '%0A' + 'Comment: ' + this.form.get('info')?.value;
        console.log(link, d);
        window.open('https://api.whatsapp.com/send/?phone&text=' + link.replace('undefined', ''));
    }
}
