import {Component, OnInit, ElementRef, HostListener, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Booking, BookingsService} from '../bookings.service';
import {SubdomainsService} from '../subdomain.service';
import {UsersService} from '../users.service';
import {PhoneFlagsService} from '../phoneFlags.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {formatDate} from '@angular/common';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import * as _moment from 'moment';
// @ts-ignore
import {default as _rollupMoment} from 'moment';
import {Address} from 'ngx-google-places-autocomplete/objects/address';
import {TranslateService} from '@ngx-translate/core';
import {SwiperComponent} from 'swiper/angular';

// import Swiper core and required modules
import SwiperCore, {EffectCoverflow, Pagination} from 'swiper/core';

// install Swiper modules
SwiperCore.use([EffectCoverflow, Pagination]);

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'LL',
    },
    display: {
        dateInput: 'MMM DD, YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'll',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-dashboard',
    templateUrl: './alarm.component.html',
    styleUrls: ['./alarm.component.css'],
    providers: [
        {provide: MAT_DATE_LOCALE, useValue: 'fr-FR'},
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    ],
    animations: [
        trigger('hidepreloader', [
            state('start', style({
                display: 'flex',
                opacity: 1
            })),
            state('end', style({
                display: 'none',
                opacity: 0
            })),
            transition('start => end', animate(100))
        ]),
        trigger('step1', [
            state('start', style({
                'z-index': 9,
                opacity: 1,
                left: 0,
                transform: 'scale(1)',
            })),
            state('end', style({
                'z-index': 1,
                opacity: 0.7,
                left: '-25%',
                transform: 'scale(0.9)',
            })),
            transition('start => end', animate(500)),
            transition('end => start', animate(500))
        ]),
        trigger('step2', [
            state('start', style({
                'z-index': 9,
                opacity: 1
            })),
            state('end', style({
                'z-index': 0,
                opacity: 0
            })),
            transition('start => end', animate(500)),
            transition('end => start', animate(500))
        ]),
        trigger('step3', [
            state('start', style({
                'z-index': 9,
                opacity: 1
            })),
            state('end', style({
                'z-index': 0,
                opacity: 0
            })),
            transition('start => end', animate(100)),
            transition('end => start', animate(100))
        ]),
        trigger('step4', [
            state('start', style({
                'z-index': 9,
                opacity: 1
            })),
            state('end', style({
                'z-index': 0,
                opacity: 0
            })),
            transition('start => end', animate(100)),
            transition('end => start', animate(100))
        ]),
    ],
})

export class AlarmComponent implements OnInit {
    constructor(private _adapter: DateAdapter<any>,
                private route: ActivatedRoute,
                private translate: TranslateService,
                private bookingsService: BookingsService,
                public PhoneFlagsService: PhoneFlagsService,
                private router: Router,
                public SubdomainsService: SubdomainsService,
                private UsersService: UsersService,
                private element: ElementRef) {

    }

// laz_art correction may 2021
    hours = Array(23).fill(0).map((x, i) => i + 1);
    zones16 = Array(15).fill(0).map((x, i) => i + 1);
    minutes = Array(12).fill(0).map((x, i) => i * 5);
    duration = Array(12).fill(0).map((x, i) => i + 1);
    durationOne = Array(25).fill(0).map((x, i) => i * 30);
    today = new Date();
    business = 0;
    zones = [{
        name: '',
        products: [{name: '', qwt: 0, price: 0}],
        agents: [{id: '0', date: new Date().toISOString().split('T')[0], hour: '12', min: '00', duration: '0'}],
        price: 0,
        duration: 0,
        priceH: 0,
        mode: 'g'
    }];
    products = [{name: '', qwt: '', price: ''}];
    globalCounterClonedProduct = 0;
    globalCounterClonedZones = 0;
    qwtDD = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    allProducts = [];
    allProductsCi = [];

    showLoader = false;
    showLoaderSign = false;

    clientPhoneError = false;
    clientEmailError = false;
    clientNameError = false;
    clientSurnameError = false;

    sentToSign = false;
    alreadySent = false;

// laz_art end

    isChoosenBodyguard = false;
    locale = 'fr';
    booking: any;
    bodyguards: any;
    form: FormGroup;
    formExistInvoice: FormGroup;
    formNewInvoice: FormGroup;
    formNew: FormGroup;

    currentBookingId: string;
    saveSaved = 'Save';
    addInExistInvoiceText = 'Add booking in this invoice';
    addNewInvoiceText = 'Generate invoice';
    updateInvoiceText = 'Update';
    sendInvoiceText = 'Send';
    viewInvoiceText = 'View';

    commentInvoice = '';
    createdInvoice = '';
    isSentInvoice = '';

    @ViewChild('opened') opened: ElementRef;
    @ViewChild('place') placesRef: ElementRef;
    @ViewChild('placeDrop') placesDropRef: ElementRef;
    @ViewChild('clientIAddress') clientIAddress: ElementRef;

    @ViewChild('widgetsContent', {read: ElementRef}) public widgetsContent: ElementRef<any>;

    hidepreloaderState = 'start';
    step1State = 'start';
    step2State = 'start';
    step3State = 'start';
    step4State = 'start';

    showBookingState = 'end';
    showBookingBackState = 'end';
    showInvoiceModeState = 'start';
    showAddBodyguardState = 'start';
    invoiceMode = '';
    invoiceNotExistMessage = false;
    currentInvoiceId = '0';

    newAgentId = '0';
    newAgentName = '';
    newAgentType = '1';
    newAgentImg = '';
    globBodyguardType = '';
    clientPhone = '';

    // message
    messageForm: FormGroup;
    messageShow = false;
    messageId = '0';
    messageUserName = '0';
    messageUserEmail = '0';
    messageUserPhone = '0';
    sendSmsText = 'Send SMS';
    sendEmailText = 'Send eMail';

    tva = 0;
    ht = 0;
    disabledLeft = true;
    showAlertInvoice = false;
    mAddress = '';
    mPrice = 0;
    showDeleteAlert = false;
    showFillAllFields = false;
    deletezoneNumber: 1;

    zonesTDateFake = [];

    public _filter(value: string): string[] {
        const filterValue = this.formNew.get(value).value.toLowerCase();
        // console.log('filterValue', filterValue);
        return this.allProductsCi.filter(it => {
            // console.log('filterValue it', it);
            // console.log('it.name.toLowerCase().includes(filterValue)', it.name.toLowerCase().includes(filterValue));
            // console.log('this.allProducts', this.allProducts);
            return it.name.toLowerCase().includes(filterValue);
        });
    }

    public handleAddressChange(address: Address) {
        this.formNew.patchValue({
            pickup: address.formatted_address
        });
        setTimeout(function() {
            this.submitNewBooking(this.currentBookingId);
        }.bind(this), 1000);
    }

    openPopUp(z) {
        this.deletezoneNumber = z;
        this.showDeleteAlert = true;
    }

    closePopUp() {
        this.showDeleteAlert = false;
    }

    handleAddressInFactureChange(address: Address) {
        this.formNew.patchValue({
            clientAddress: address.formatted_address
        });
    }

    onSwiper(swiper) {
        console.log(swiper);
    }

    onSlideChange() {
        console.log('slide change');
    }

    resetValue(v) {
        this.formNew.patchValue({
            v: ''
        });
        this.formNew.get(v).value;
    }

    public handleclientIAddressChange(address: Address) {
        this.formNewInvoice.patchValue({
            client_address: address.name + ' ' + address.formatted_address
        });
    }

    public handleNewAddressChange(address: Address) {
        console.log('address', address);
        this.formNew.patchValue({
            pickup: address.name + ' ' + address.formatted_address
        });
    }

    filter(value) {
        console.log('this._filter(value)', this._filter(value).length);
        if (this._filter(value).length == 0) {
            this.allProducts = [{name: 'Ce produit n’existe pas'}];
        } else {
            this.allProducts = this._filter(value);
        }
    }

    closeBooking() {
        this.router.navigate(['/alarms'], {queryParams: {update: '1'}});
    }

    deleteBooking(id) {
        this.UsersService.deleteBooking(id).subscribe(response => {
            console.log('deleteBooking', response);
            if (response.res) {
                setTimeout(() => {
                    this.router.navigate(['/alarms'], {queryParams: {update: '1'}});
                }, 500);
            } else {
                this.animateLoader();
            }
        });
    }

    // message start
    messageClicked(e, id, name, email, phone) {
        this.messageForm.patchValue({
            message: this.translate.instant('booking.Hello') + ' ' + name + '! ',
        });
        console.log(id + ' + ' + name);
        this.messageId = id;
        this.messageUserName = name;
        this.messageUserEmail = email;
        this.messageUserPhone = phone;
        this.messageShow = true;
    }

    closeMessage() {
        this.messageShow = false;
    }

    messageFormSubmit(transport) {
        if (transport == 'sms') {
            this.sendSmsText = 'Sending...';
        } else {
            this.sendEmailText = 'Sending...';
        }
        const messageFormData = {...this.messageForm.value};
        console.log('messageFormData - ', messageFormData);
        this.UsersService.sendMessage(this.messageId, this.messageUserEmail, this.messageUserPhone, transport, messageFormData.message).subscribe(response => {
            console.log('Response message', response);
            if (transport == 'sms') {
                this.sendSmsText = 'Sent';
            } else {
                this.sendEmailText = 'Sent';
            }
            // this.totalBookings = this.bookings.length;
            // this.gettotalRevenue(response);
            setTimeout(() => {
                if (transport == 'sms') {
                    this.sendSmsText = 'Send SMS';
                } else {
                    this.sendEmailText = 'Send eMail';
                }
            }, 1000);
        });
    }

    // message end

    cloneSeveralZones() {
        const i = this.formNew.get('numOfZ').value;
        if (this.zones.length > i) {
            this.deleteZone();
            this.cloneSeveralZones();
        } else if (this.zones.length < i) {
            this.cloneZone(null);
            this.cloneSeveralZones();
        }
    }

    cloneZone(index) {
        this.globalCounterClonedZones = this.globalCounterClonedZones + 1;
        this.zones.push({
            name: '',
            products: [{name: '', qwt: 0, price: 0}],
            agents: [{id: '0', date: new Date().toISOString().split('T')[0], hour: '12', min: '00', duration: '0'}],
            price: 0,
            duration: 0,
            priceH: 0,
            mode: 'g'
        });
        this.formNew.addControl('duration_' + this.globalCounterClonedZones, new FormControl(''));
        this.formNew.addControl('priceH_' + this.globalCounterClonedZones, new FormControl(0, [Validators.required, Validators.pattern('^[0-9]+$')]));
        this.formNew.addControl('zone_' + this.globalCounterClonedZones, new FormControl(''));
        this.formNew.addControl('zone_' + this.globalCounterClonedZones + '_product_0', new FormControl(''));
        this.formNew.addControl('zone_' + this.globalCounterClonedZones + '_product_0_qwt', new FormControl('0'));
        this.formNew.addControl('zone_' + this.globalCounterClonedZones + '_product_0_price', new FormControl(0));
        this.formNew.addControl('zone_' + this.globalCounterClonedZones + '_agent_0_id', new FormControl(''));
        this.formNew.addControl('zone_' + this.globalCounterClonedZones + '_agent_0_date', new FormControl(moment.utc().locale('fr'), [Validators.required]));
        this.formNew.addControl('zone_' + this.globalCounterClonedZones + '_agent_0_hour', new FormControl('12'));
        this.formNew.addControl('zone_' + this.globalCounterClonedZones + '_agent_0_min', new FormControl('00'));
        this.formNew.addControl('zone_' + this.globalCounterClonedZones + '_agent_0_duration', new FormControl('60'));
    }

    deleteZone() {
        this.zones.splice(this.deletezoneNumber, 1);

        setTimeout(function() {
            this.submitNewBooking(this.currentBookingId, true);
        }.bind(this), 500);
    }

    cloneProduct(zone, count) {
        this.zones[zone].products.push({name: '', qwt: 0, price: 0});
        this.formNew.addControl('zone_' + zone + '_product_' + count, new FormControl(''));
        this.formNew.addControl('zone_' + zone + '_product_' + count + '_qwt', new FormControl('0'));
        this.formNew.addControl('zone_' + zone + '_product_' + count + '_price', new FormControl(0));
    }

    deleteProduct(zone, index) {
        this.zones[zone].products.splice(index, 1);
        setTimeout(function() {
            this.submitNewBooking(this.currentBookingId, true);
        }.bind(this), 200);
        // deleteControls???
    }

    cloneAgent(zone, count) {
        this.zones[zone].agents.push({id: '0', date: new Date().toISOString().split('T')[0], hour: '12', min: '00', duration: '0'});
        this.formNew.addControl('zone_' + zone + '_agent_' + count + '_id', new FormControl(''));
        this.formNew.addControl('zone_' + zone + '_agent_' + count + '_date', new FormControl(moment.utc().locale('fr'), [Validators.required]));
        this.formNew.addControl('zone_' + zone + '_agent_' + count + '_hour', new FormControl('12'));
        this.formNew.addControl('zone_' + zone + '_agent_' + count + '_min', new FormControl('00'));
        this.formNew.addControl('zone_' + zone + '_agent_' + count + '_duration', new FormControl('0'));
    }

    deleteAgent(zone, index) {
        this.animateLoader();
        this.zones[zone].agents.splice(index, 1);
        setTimeout(function() {
            this.submitNewBooking(this.currentBookingId, true);
        }.bind(this), 200);
        // deleteControls???
    }

    // changeHourPrice() {
    //     this.zones.forEach((element, idx, array) => {
    //         this.zones[idx].price = 0;
    //         this.zones[idx].duration = this.formNew.get('duration_' + idx).value;
    //         this.zones[idx].products.forEach((elementInZ, idxInZ, arrayInZ) => {
    //             if (idxInZ === arrayInZ.length - 1) {
    //                 this.zones[idx].price = this.zones[idx].price + elementInZ.qwt * elementInZ.price + this.zones[idx].duration / 60 * this.formNew.get('priceH').value;
    //             } else {
    //                 this.zones[idx].price = this.zones[idx].price + elementInZ.qwt * elementInZ.price;
    //             }
    //         });
    //     });
    //
    //     setTimeout(() => {
    //         this.calculateFullPrice();
    //     }, 1000);
    // }

    calculateFullPrice() {
        var fullPrice = 0;
        this.zones.forEach((element, idx, array) => {
            if (idx === array.length - 1) {
                setTimeout(() => {
                    // this.tva = (+fullPrice + +element.price) - ((+fullPrice + +element.price) / (1 + +this.formNew.get('pOrP').value / 10) - (+fullPrice + +element.price)) * -1;// Math.round((+fullPrice + +element.price) * 0.1 * this.formNew.get('pOrP').value * 100) / 100; // round((($value['price'] / 1.1) - $value['price']) * -1, 2);
                    // this.ht = ((+fullPrice + +element.price) / (1 + +this.formNew.get('pOrP').value / 10) - (+fullPrice + +element.price)) * -1;   // Math.round((+fullPrice + +element.price) * 100) / 100 - Math.round((+fullPrice + +element.price) * 0.1 * this.formNew.get('pOrP').value * 100) / 100;
                    this.tva = (+fullPrice + +element.price) * (+this.formNew.get('pOrP').value / 10); // - ((+fullPrice + +element.price) / (1 + +this.formNew.get('pOrP').value / 10) - (+fullPrice + +element.price)) * -1;// Math.round((+fullPrice + +element.price) * 0.1 * this.formNew.get('pOrP').value * 100) / 100; // round((($value['price'] / 1.1) - $value['price']) * -1, 2);
                    this.ht = (+fullPrice + +element.price); // / (1 + +this.formNew.get('pOrP').value / 10) - (+fullPrice + +element.price)) * -1;   // Math.round((+fullPrice + +element.price) * 100) / 100 - Math.round((+fullPrice + +element.price) * 0.1 * this.formNew.get('pOrP').value * 100) / 100;
                    this.formNew.patchValue({
                        price: Math.round(((+fullPrice + +element.price) * (1 + +this.formNew.get('pOrP').value / 10)) * 100) / 100,
                    });
                }, 1000);
            } else {
                fullPrice = +fullPrice + +element.price;
            }
        });
    }

    calculateZonePrice(zone) {
        this.zones[zone].price = 0;
        this.zones[zone].duration = 0;
        // this.zones[zone].duration = this.formNew.get(durationZ).value;
        this.zones[zone].agents.forEach((element, idx, array) => {
            this.zones[zone].duration = this.zones[zone].duration + +element.duration;
        });
        this.zones[zone].priceH = this.formNew.get('priceH_' + zone).value;
        // setTimeout(function() {
        // this.formNew.patchValue({
        //     price: this.zones[zone].duration
        // });
        this.zones[zone].products.forEach((element, idx, array) => {
            if (idx === array.length - 1) {
                this.zones[zone].price = (this.zones[zone].price + element.qwt * element.price + this.zones[zone].duration / 60 * this.zones[zone].priceH) * +this.formNew.get('coef').value;
            } else {
                this.zones[zone].price = this.zones[zone].price + element.qwt * element.price;
            }
        });
        // }, 1000);

        // patchValue duration for zone
        this.calculateFullPrice();
    }

    patchProduct(name, nameVal, qt, price, priceVal, zone, product) {
        console.log('patchProduct', name, nameVal, price, priceVal);
        this.formNew.get(name).setValue(nameVal);
        this.formNew.get(price).setValue(priceVal);
        this.formNew.get(qt).setValue(1);
        this.zones[zone].products[product].name = nameVal;
        this.zones[zone].products[product].price = priceVal;
        this.zones[zone].products[product].qwt = 1;
        this.calculateZonePrice(zone);
        setTimeout(function() {
            this.submitNewBooking(this.currentBookingId);
        }.bind(this), 200);
    }

    inputChanged(name, price, qt, zone, product, durationZ) {
        console.log('formControlName', price, this.formNew.get(price).value, qt, this.formNew.get(qt).value);
        this.zones[zone].products[product].name = this.formNew.get(name).value;
        this.zones[zone].products[product].price = this.formNew.get(price).value;
        this.zones[zone].products[product].qwt = this.formNew.get(qt).value;
        // this.zones[zone].duration = this.formNew.get(durationZ).value;
        this.calculateZonePrice(zone);
    }

    ifDelete0(control) {
        console.log('this.formNew.get(control).value', this.formNew.get(control).value);
        if (this.formNew.get(control).value == 0) {
            this.formNew.get(control).setValue('');
        }
    }

    ifAdd0(control) {
        console.log('this.formNew.get(control).value', this.formNew.get(control).value);
        if (this.formNew.get(control).value == 0 || this.formNew.get(control).value == null) {
            this.formNew.get(control).setValue('0');
        }
    }

    inputChangedAgent(id, date, hour, min, duration, zone, index) {
        this.zonesTDateFake['zone_' + zone + '_agent_' + index + '_id'] = new Date(this.formNew.get(date).value.year + '-' + this.formNew.get(date).value.month + '-' + this.formNew.get(date).value.day).toLocaleString(this.translate.instant('info.lng'), {
            year: 'numeric',
            month: 'short',
            day: '2-digit',
        });
        const month = parseInt(this.formNew.get(date).value.month) < 10 ? '0' + this.formNew.get(date).value.month : this.formNew.get(date).value.month;
        const day = parseInt(this.formNew.get(date).value.day) < 10 ? '0' + this.formNew.get(date).value.day : this.formNew.get(date).value.day;
        this.zones[zone].agents[index].id = this.formNew.get(id).value;
        this.zones[zone].agents[index].date = this.formNew.get(date).value.year + '-' + month + '-' + day;
        this.zones[zone].agents[index].hour = this.formNew.get(hour).value;
        this.zones[zone].agents[index].min = this.formNew.get(min).value;
        this.zones[zone].agents[index].duration = this.formNew.get(duration).value;
        this.calculateZonePrice(zone);
    }

    zoneNameEdit(z, zone) {
        this.zones[z].name = this.formNew.get(zone).value;
    }

    ngOnInit() {
        this.SubdomainsService.isAdmin();
        if (this.SubdomainsService.subdomain === 'agent' || this.SubdomainsService.subdomain === 'pro') {
            this.router.navigate(['/bookings']);
        }

        this.route.params.subscribe((params: Params) => {
            if (params.id === 'new') {
                // this.animateLoader();
                setTimeout(function() {
                    this.submitNewBooking();
                }.bind(this), 0);
            } else {
                this.currentBookingId = params.id;
                this.getBooking(params.id);
            }
        });

        this.translate.stream('info.lng').subscribe((value) => {
            this._adapter.setLocale(value);
        });

        this.formNew = new FormGroup({
            status: new FormControl('1'),
            startDate: new FormControl(moment.utc().locale('fr'), [Validators.required]),
            timeHour: new FormControl('12', [Validators.required]),
            timeMin: new FormControl('00', [Validators.required]),
            // duration: new FormControl('360', [Validators.required]),
            pickup: new FormControl('', [Validators.required]),
            typeHabitation: new FormControl('0', [Validators.min(1)]),
            typeService: new FormControl('', [Validators.minLength(1)]),
            numOfZ: new FormControl('1'),
            pOrP: new FormControl('1'),
            dOrF: new FormControl('1'),
            companyName: new FormControl(''),
            clientName: new FormControl('', [Validators.required]),
            clientSurname: new FormControl('', [Validators.required]),
            clientEmail: new FormControl('', [Validators.email, Validators.required]),
            flag: new FormControl('fr', [Validators.required]),
            clientPhone: new FormControl('', [Validators.required]),
            clientAddress: new FormControl('', [Validators.required]),
            clientProfSiret: new FormControl(''),
            clientProfTva: new FormControl(''),
            clientContactName: new FormControl('', [Validators.required]),
            clientContactSurname: new FormControl('', [Validators.required]),
            clientProfRole: new FormControl(''),
            clientContactPhone: new FormControl('', [Validators.required]),
            clientContactEmail: new FormControl('', [Validators.email, Validators.required]),
            paymentMethod: new FormControl('0', [Validators.required, Validators.min(1)]),
            // currency: new FormControl('1'),
            price: new FormControl('', [Validators.required]),
            info: new FormControl(''),
            zone_0: new FormControl(''),
            duration_0: new FormControl('1 h 00 mn'),
            priceH_0: new FormControl(0, [Validators.required, Validators.pattern('^[0-9]+$')]),
            zone_0_product_0: new FormControl(''),
            zone_0_product_0_qwt: new FormControl(''),
            zone_0_product_0_price: new FormControl(''),
            zone_0_agent_0_id: new FormControl(''),
            zone_0_agent_0_date: new FormControl(moment.utc().locale('fr'), [Validators.required]),
            zone_0_agent_0_hour: new FormControl('12'),
            zone_0_agent_0_min: new FormControl('00'),
            zone_0_agent_0_duration: new FormControl('0'),
            tarifMPrice: new FormControl(''),
            // tslint:disable-next-line:max-line-length
            descriptionM: new FormControl('Par ce présent contrat, la société 1EYEONU Technology, se trouvant au 154 ter avenue Victor Hugo 75016 Paris. \n' + '\n' + 'Se devra d’assurer les prestations définies dans le présent contrat auprès de l’institut des PARONS en ce qui concerne les installations d’alarme anti-intrusion se trouvant au:\n' + '\n' + this.mAddress + '. \n' + '\n' + '\n' + 'Celui-ci comprend la visite de maintenance annuelle ainsi qu’une deuxième visite à votre souhait. Il est recommandé de faire effectuer une visite après de fort évènement climatique ou en prévisionde la venue du propriétaire afin que tout soit en ordre de fonctionnement, pour cela nous demandons au commanditaire de la visite de prévenir notre commande dans un délai de 15 jours avant la dite visite. \n' + '\n' + 'Tous les déplacements, ainsi que le temps passé sur l’installation lors d’une visite périodique dans la limite de deux visites annuelles, sont compris dans l’abonnement, Hors coût du matériel. 1EYEONU technology garantie une veille technologique spécifique au secteur de l’alarme et à le devoir d’informer le client. \n' + '\n' + 'Durée du contrat:  le contrat est d’une durée d’un an tacite reconduction. \n' + '\n' + 'Le montant du contrat est de ' + this.mPrice + '€ HT annuel  ce montant est payable comptant ou par mensualités de ' + +this.mPrice / 12 + '€ HT.\n' + '\n' + 'Merci de votre confiance je vous prie d’agréer, Monsieur l’expression de mes sentiments \n' + 'respectueux.'),
            coef: new FormControl('1'),
        });

        this.messageForm = new FormGroup({
            message: new FormControl('Hello ' + this.messageUserName),
        });

        // this.formExistInvoice = new FormGroup({
        //     existINumber: new FormControl('', [Validators.required]),
        // });
        // this.formNewInvoice = new FormGroup({
        //     client_name: new FormControl('', [Validators.required]),
        //     client_phone: new FormControl('', [Validators.required]),
        //     client_email: new FormControl('', [Validators.required]),
        //     client_address: new FormControl('', [Validators.required]),
        //     comment: new FormControl(''),
        //     paid: new FormControl(''),
        //     payment_method: new FormControl('', [Validators.required]),
        // });
        // this.getBodyguardsForNewBooking('1'); GET TECHNICIENT HERE
    }

    flagChanged() {
        const formData = {...this.formNew.value};
        this.PhoneFlagsService.setFlag(formData.flag);
        this.formNew.patchValue({
            flag: formData.flag,
        });
    }

    scrollLeft() {
        this.widgetsContent.nativeElement.scrollTo({left: (this.widgetsContent.nativeElement.scrollLeft + 400), behavior: 'smooth'});
        this.disabledLeft = false;
    }

    scrollRight() {
        this.widgetsContent.nativeElement.scrollTo({left: (this.widgetsContent.nativeElement.scrollLeft - 400), behavior: 'smooth'});
        setTimeout(() => {
            if (this.widgetsContent.nativeElement.scrollLeft == 0) {
                this.disabledLeft = true;
            }
        }, 1000);

    }

    focusOnSome() {
        // let inputField = document.querySelectorAll('input.ng-invalid');
        // if ( inputField[0] ) {
        //     inputField[0].focus();
        // }
    }


    viewInvoiceNew() {
        if (this.formNew.invalid) {
            this.showAlertInvoice = true;
            this.showFillAllFields = true;
            this.focusOnSome();
        } else {
            window.open('https://1eyeonu.com/alarmInvoice.php?id=' + this.currentBookingId, '_blank');
        }
    }

    viewCM() {
        if (this.formNew.invalid) {
            this.showAlertInvoice = true;
            this.showFillAllFields = true;
            this.focusOnSome();
        } else {
            window.open('https://1eyeonu.com/alarmInvoiceCM.php?id=' + this.currentBookingId, '_blank');
        }
    }

    sendToSign() {
        if (this.sentToSign) {
            this.alreadySent = true;
            return;
        }
        // show loader on button
        const formData = {...this.formNew.value};
        const clientPhone = this.PhoneFlagsService.phoneDialcode + formData.clientPhone;
        if (formData.clientContactPhone?.length > 0 && formData.clientContactEmail?.length > 0 && formData.clientContactName?.length > 0 && formData.clientContactSurname?.length > 0) {
            this.bookingsService.sendAlarmYouSign(this.currentBookingId).subscribe(response => {
                console.log('Response sendAlarmYouSign', response);
            });
            this.showLoaderSign = true;
            setTimeout(() => {
                this.showLoaderSign = false;
                this.sentToSign = true;
            }, 1500);
        } else {
            if (formData.clientContactPhone?.length == 0 || !formData.clientContactName) {
                this.clientPhoneError = true;
            }
            if (formData.clientContactEmail?.length == 0 || !formData.clientContactEmail) {
                this.clientEmailError = true;
            }
            if (formData.clientContactName?.length == 0 || !formData.clientContactName) {
                this.clientNameError = true;
            }
            if (formData.clientContactSurname?.length == 0 || !formData.clientContactSurname) {
                this.clientSurnameError = true;
            }
        }
    }

    patchMText() {
        this.formNew.patchValue({
            descriptionM: 'Par ce présent contrat, la société 1EYEONU Technology, se trouvant au 154 ter avenue Victor Hugo 75016 Paris. \n' + '\n' + 'Se devra d’assurer les prestations définies dans le présent contrat auprès de l’institut des PARONS en ce qui concerne les installations d’alarme anti-intrusion se trouvant au:\n' + '\n' + this.formNew.get('pickup').value + '. \n' + '\n' + '\n' + 'Celui-ci comprend la visite de maintenance annuelle ainsi qu’une deuxième visite à votre souhait. Il est recommandé de faire effectuer une visite après de fort évènement climatique ou en prévisionde la venue du propriétaire afin que tout soit en ordre de fonctionnement, pour cela nous demandons au commanditaire de la visite de prévenir notre commande dans un délai de 15 jours avant la dite visite. \n' + '\n' + 'Tous les déplacements, ainsi que le temps passé sur l’installation lors d’une visite périodique dans la limite de deux visites annuelles, sont compris dans l’abonnement, Hors coût du matériel. 1EYEONU technology garantie une veille technologique spécifique au secteur de l’alarme et à le devoir d’informer le client. \n' + '\n' + 'Durée du contrat:  le contrat est d’une durée d’un an tacite reconduction. \n' + '\n' + 'Le montant du contrat est de ' + Math.round(+this.formNew.get('tarifMPrice').value * 100) / 100 + '€ HT annuel  ce montant est payable comptant ou par mensualités de ' + Math.round(+this.formNew.get('tarifMPrice').value / 12 * 100) / 100 + '€ HT.\n' + '\n' + 'Merci de votre confiance je vous prie d’agréer, Monsieur l’expression de mes sentiments \n' + 'respectueux.',
        });
    }

    getBooking(id, animate = false) {
        setTimeout(() => {
            this.calculateFullPrice();
        }, 1500);
        this.bookingsService.getAlarmBooking(id).subscribe(response => {
            console.log('Response Alarm booking', response);
            // work with product dropdown
            this.allProducts = response.allProduct;
            this.allProductsCi = response.allProduct;
            this.booking = response.booking;
            this.formNew.patchValue({
                status: response.booking.status,
                pickup: response.booking.address != null ? response.booking.address : '',
                // tslint:disable-next-line:max-line-length
                descriptionM: 'Par ce présent contrat, la société 1EYEONU Technology, se trouvant au 154 ter avenue Victor Hugo 75016 Paris. \n' + '\n' + 'Se devra d’assurer les prestations définies dans le présent contrat auprès de l’institut des PARONS en ce qui concerne les installations d’alarme anti-intrusion se trouvant au:\n' + '\n' + response.booking.address + '. \n' + '\n' + '\n' + 'Celui-ci comprend la visite de maintenance annuelle ainsi qu’une deuxième visite à votre souhait. Il est recommandé de faire effectuer une visite après de fort évènement climatique ou en prévisionde la venue du propriétaire afin que tout soit en ordre de fonctionnement, pour cela nous demandons au commanditaire de la visite de prévenir notre commande dans un délai de 15 jours avant la dite visite. \n' + '\n' + 'Tous les déplacements, ainsi que le temps passé sur l’installation lors d’une visite périodique dans la limite de deux visites annuelles, sont compris dans l’abonnement, Hors coût du matériel. 1EYEONU technology garantie une veille technologique spécifique au secteur de l’alarme et à le devoir d’informer le client. \n' + '\n' + 'Durée du contrat:  le contrat est d’une durée d’un an tacite reconduction. \n' + '\n' + 'Le montant du contrat est de ' + Math.round(+response.booking.mPrice * 100) / 100 + '€ HT annuel  ce montant est payable comptant ou par mensualités de ' + Math.round(+response.booking.mPrice / 12 * 100) / 100 + '€ HT.\n' + '\n' + 'Merci de votre confiance je vous prie d’agréer, Monsieur l’expression de mes sentiments \n' + 'respectueux.',
                typeHabitation: response.booking.type_habitation,
                typeService: response.booking.type_service != null ? response.booking.type_service.split(',') : [],
                // numOfZ: response.booking.numOfZ,
                pOrP: response.booking.pOrP,
                dOrF: response.booking.dOrF,
                companyName: response.booking.companyName,
                clientName: response.booking.clientName,
                clientSurname: response.booking.clientSurname,
                clientPhone: this.PhoneFlagsService.patchPhone(response.booking.clientPhone),
                flag: response.booking.clientPhone ? this.PhoneFlagsService.patchFlag(response.booking.clientPhone) : 'fr',
                clientEmail: response.booking.clientEmail,
                clientAddress: response.booking.clientAddress,
                clientProfSiret: response.booking.clientProfSiret,
                clientProfTva: response.booking.clientProfTva,
                clientContactName: response.booking.clientContactName,
                clientContactSurname: response.booking.clientContactSurname,
                clientProfRole: response.booking.clientProfRole,
                clientContactPhone: this.PhoneFlagsService.patchPhone(response.booking.clientContactPhone),
                clientContactEmail: response.booking.clientContactEmail,
                paymentMethod: response.booking.payment_method_id,
                price: response.booking.price,
                tarifMPrice: response.booking.mPrice,
                coef: response.booking.coef,
                // startDate: moment.utc(value.b_start_date), // new Date(value.b_start_date),
                // endDate: moment.utc(value.end_date), // new Date(value.b_start_date),
                // timeHour: formatDate(value.b_start_date, 'HH', 'en', 'UTC'),
                // timeHourEnd: formatDate(value.end_date, 'HH', 'en', 'UTC'),
                // timeMin: formatDate(value.b_start_date, 'mm', 'en', 'UTC'),
                // duration: value.duration,
                // paymentMethod: value.payment_method_id,
                // info: value.info,
                // clientName: value.user_name,
                // clientEmail: value.user_email,
                // clientPhone: value.user_phone,
            });
            if (response.booking.sentToSign == 1) {
                this.sentToSign = true;
            }

            if (response.booking.clientPhone) {
                this.PhoneFlagsService.patchFlag(response.booking.clientPhone);
            }

            if (response.zones.length > 1) {
                this.globalCounterClonedZones = response.zones.length - 1;
                this.formNew.patchValue({
                    numOfZ: '' + response.zones.length,
                });
            }

            if (response.booking.pOrP == '2') {
                this.business = 1;
            }

            // this.formNew.get('pOrP').setValue(response.booking.pOrP);

            if (response.zones.length > 0) {
                this.zones = response.zones;
            }

            console.log('this.zones', this.zones);

            response.zones.forEach((element, idx, array) => {
                if (idx == 0) {
                    this.formNew.patchValue({
                        zone_0: element.name,
                        duration_0: element.duration,
                        priceH_0: element.priceH,
                        // zone_0_product_0: new FormControl(''),
                        // zone_0_product_0_qwt: new FormControl(''),
                        // zone_0_product_0_price: new FormControl(''),
                    });
                    element.agents.forEach((elementAgents, idxAgents, arrayAgents) => {
                        if (idxAgents == 0) {
                            this.formNew.patchValue({
                                zone_0_agent_0_id: elementAgents.id,
                                zone_0_agent_0_date: {
                                    year: parseInt(elementAgents.date?.slice(0, 4)),
                                    month: parseInt(elementAgents.date?.slice(5, 7).replace('0', '')),
                                    day: parseInt(elementAgents.date?.slice(8, 10).replace('0', ''))
                                },
                                zone_0_agent_0_hour: elementAgents.hour,
                                zone_0_agent_0_min: elementAgents.min,
                                zone_0_agent_0_duration: elementAgents.duration,
                            });
                            //fake
                            this.zonesTDateFake['zone_0_agent_0_id'] = new Date(elementAgents.date).toLocaleString(this.translate.instant('info.lng'), {
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit',
                            });
                        } else {
                            this.formNew.addControl('zone_0_agent_' + idxAgents + '_id', new FormControl(elementAgents.id));
                            this.formNew.addControl('zone_0_agent_' + idxAgents + '_date', new FormControl({
                                year: parseInt(elementAgents.date?.slice(0, 4)),
                                month: parseInt(elementAgents.date?.slice(5, 7).replace('0', '')),
                                day: parseInt(elementAgents.date?.slice(8, 10).replace('0', ''))
                            }));
                            this.formNew.addControl('zone_0_agent_' + idxAgents + '_hour', new FormControl(elementAgents.hour));
                            this.formNew.addControl('zone_0_agent_' + idxAgents + '_min', new FormControl(elementAgents.min));
                            this.formNew.addControl('zone_0_agent_' + idxAgents + '_duration', new FormControl(elementAgents.duration));
                            //fake
                            this.zonesTDateFake['zone_0_agent_' + idxAgents + '_id'] = new Date(elementAgents.date).toLocaleString(this.translate.instant('info.lng'), {
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit',
                            });
                        }
                    });
                    // product cycle
                    element.products.forEach((elementProducts, idxProducts, arrayProducts) => {
                        if (idxProducts == 0) {
                            this.formNew.patchValue({
                                zone_0_product_0: elementProducts.name,
                                zone_0_product_0_qwt: elementProducts.qwt,
                                zone_0_product_0_price: elementProducts.price,
                            });
                        } else {
                            this.formNew.addControl('zone_0_product_' + idxProducts, new FormControl(elementProducts.name));
                            this.formNew.addControl('zone_0_product_' + idxProducts + '_qwt', new FormControl(elementProducts.qwt));
                            this.formNew.addControl('zone_0_product_' + idxProducts + '_price', new FormControl(elementProducts.price));
                        }
                    });
                } else {
                    this.formNew.addControl('zone_' + idx, new FormControl(element.name));
                    this.formNew.addControl('duration_' + idx, new FormControl(element.duration));
                    this.formNew.addControl('priceH_' + idx, new FormControl(element.priceH));

                    element.agents.forEach((elementAgents, idxAgents, arrayAgents) => {
                        this.formNew.addControl('zone_' + idx + '_agent_' + idxAgents + '_id', new FormControl(elementAgents.id));
                        this.formNew.addControl('zone_' + idx + '_agent_' + idxAgents + '_date', new FormControl({
                            year: parseInt(elementAgents.date?.slice(0, 4)),
                            month: parseInt(elementAgents.date?.slice(5, 7).replace('0', '')),
                            day: parseInt(elementAgents.date?.slice(8, 10).replace('0', ''))
                        }));
                        this.formNew.addControl('zone_' + idx + '_agent_' + idxAgents + '_hour', new FormControl(elementAgents.hour));
                        this.formNew.addControl('zone_' + idx + '_agent_' + idxAgents + '_min', new FormControl(elementAgents.min));
                        this.formNew.addControl('zone_' + idx + '_agent_' + idxAgents + '_duration', new FormControl(elementAgents.duration));
                        // fake
                        this.zonesTDateFake['zone_' + idx + '_agent_' + idxAgents + '_id'] = new Date(elementAgents.date).toLocaleString(this.translate.instant('info.lng'), {
                            year: 'numeric',
                            month: 'short',
                            day: '2-digit',
                        });
                    });

                    // product cycle
                    element.products.forEach((elementProducts, idxProducts, arrayProducts) => {
                        this.formNew.addControl('zone_' + idx + '_product_' + idxProducts, new FormControl(elementProducts.name));
                        this.formNew.addControl('zone_' + idx + '_product_' + idxProducts + '_qwt', new FormControl(elementProducts.qwt));
                        this.formNew.addControl('zone_' + idx + '_product_' + idxProducts + '_price', new FormControl(elementProducts.price));
                    });
                }
                console.log('element.name', element.name);
            });
            // this.cloneSeveralZones();

            // if (value.invoice_id) {
            //     this.currentInvoiceId = value.invoice_id;
            //     this.formNewInvoice.patchValue({
            //         client_name: value.invoice_info.client_name,
            //         client_phone: value.invoice_info.client_phone,
            //         client_email: value.invoice_info.client_email,
            //         client_address: value.invoice_info.client_address,
            //         payment_method: value.invoice_info.payment_method,
            //     });
            //     if (value.paid == '1') {
            //         this.formNewInvoice.patchValue({
            //             paid: true,
            //         });
            //     } else {
            //         this.formNewInvoice.patchValue({
            //             paid: false,
            //         });
            //     }
            //     this.commentInvoice = value.invoice_info.comment;
            //     this.createdInvoice = value.invoice_info.created;
            //     this.isSentInvoice = value.invoice_info.is_sent;
            //     if (value.invoice_info.is_sent == '1') {
            //         this.sendInvoiceText = 'Already sent';
            //     }
            // } else {
            //     this.formNewInvoice.patchValue({
            //         client_name: value.user_name,
            //         client_phone: value.user_phone,
            //         client_email: value.user_email,
            //         client_address: value.user_address,
            //         payment_method: value.payment_method_id,
            //     });
            //     if (value.client_name) {
            //         this.formNewInvoice.patchValue({
            //             client_name: value.client_name,
            //         });
            //     }
            //     if (value.client_email) {
            //         this.formNewInvoice.patchValue({
            //             client_email: value.client_email,
            //         });
            //     }
            //     if (value.client_phone) {
            //         this.formNewInvoice.patchValue({
            //             client_phone: value.client_phone,
            //         });
            //     }
            // }

            if (animate) {
                this.animateLoader();
            }
        });
    }

    getBodyguardsForNewBooking(type) {
        this.globBodyguardType = type;
        this.bookingsService.getBodyguardsForNewBooking(type).subscribe(response => {
            this.bodyguards = response;
        });
    }

    selectAgent(id, fullName, bimgImg, typeId) {

        this.newAgentId = id;
        this.newAgentName = fullName;
        this.newAgentType = typeId;
        console.log('typeId', typeId);
        if (typeId == 4) {
            this.form.get('dropoff').setValidators([Validators.required]);
            this.formNew.get('dropoff').setValidators([Validators.required]);
        } else {
            this.form.get('dropoff').clearValidators();
            this.form.get('dropoff').updateValueAndValidity();

            this.formNew.get('dropoff').clearValidators();
            this.formNew.get('dropoff').updateValueAndValidity();
        }
        this.newAgentImg = bimgImg;
        this.isChoosenBodyguard = true;

    }

    onScroll() {
        this.placesRef.nativeElement.blur();
        this.placesDropRef.nativeElement.blur();
        this.clientIAddress.nativeElement.blur();
    }

    animateLoader() {
        this.hidepreloaderState = this.hidepreloaderState === 'end' ? 'start' : 'end';
    }

    showInvoiceMode() {
        this.invoiceMode = 'new';
        this.showInvoiceModeState = this.showInvoiceModeState === 'end' ? 'start' : 'end';
    }

    // submit() {
    //     if (this.form.invalid) {
    //         return;
    //     }
    //     this.animateLoader();
    //     const formData = {...this.form.value};
    //     const endDate = formData.endDate.format('YYYY-MM-DD').slice(0, 10) + ' ' + formData.timeHourEnd + ':' + formData.timeMin + ':00';
    //     console.log('Value - ', formData);
    //     // tslint:disable-next-line:max-line-length
    //     this.bookingsService.updateBooking(this.currentBookingId, formData.status, formData.duration, formData.startDate._i, formData.timeHour, formData.timeMin, endDate, formData.pickup, formData.dropoff, formData.paymentMethod, formData.price, formData.info, this.newAgentType, this.newAgentId).subscribe(response => {
    //         console.log('Response update booking', response);
    //         this.animateLoader();
    //         this.saveSaved = 'Saved';
    //         setTimeout(() => {
    //             this.saveSaved = 'Save';
    //             this.isChoosenBodyguard = false;
    //             this.getBooking(this.currentBookingId, false);
    //         }, 1000);
    //     });
    // }

    recalcPrice() {
        this.zones.forEach((element, idx, array) => {
            this.calculateZonePrice(idx);
        });
    }

    submitNewBooking(current_project_id = null, getUpdate = false) {
        // if (this.formNew.invalid) {
        //     return;
        // }
        if (!getUpdate) {
            // this.animateLoader();
        }
        this.showLoader = true;
        const formData = {...this.formNew.value};
        const clientPhone = this.PhoneFlagsService.phoneDialcode + formData.clientPhone;
        const clientContactPhone = this.PhoneFlagsService.phoneDialcode + formData.clientContactPhone;
        console.log('Value NEW - ', formData);
        this.bookingsService.addAlarmBooking(formData, this.zones, current_project_id, clientPhone, clientContactPhone).subscribe(response => {
            console.log('response NEW BOOKING', response);
            this.sentToSign = false;
            if (current_project_id == null) {
                this.router.navigate(['/alarms', response.project_id]);
            }
            if (!getUpdate) {
                // this.animateLoader();
            }
            setTimeout(() => {
                this.showLoader = false;
            }, 1000);
        });
        if (getUpdate) {
            setTimeout(() => {
                let currentUrl = this.router.url;
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigate([currentUrl]);
            }, 500);
        }
    }

    duplicateBooking() {
        if (this.form.invalid) {
            return;
        }
        this.animateLoader();
        const formData = {...this.form.value};
        const phone = formData.clientPhone;
        const endDate = formData.endDate.format('YYYY-MM-DD').slice(0, 10) + ' ' + formData.timeHourEnd + ':' + formData.timeMin + ':00';
        console.log('Value NEW - ', formData, this.newAgentId, this.newAgentType, phone);
        this.bookingsService.addBooking(formData.status, 3, 2, formData.clientName, formData.clientEmail, phone, '', formData.pickup, '',
            formData.dropoff, formData.startDate._i, formData.startDate._i, formData.timeHour, formData.timeMin, endDate, formData.duration, 0, formData.paymentMethod, formData.price, 0, formData.info, this.newAgentType, this.newAgentId).subscribe(response => {
            console.log('response NEW BOOKING', response);
            this.router.navigate(['/bookings', response.booking]);
        });
    }

    addInExistInvoice() {
        if (this.formExistInvoice.invalid) {
            return;
        }
        this.animateLoader();
        this.addInExistInvoiceText = 'Adding...';
        const formData = {...this.formExistInvoice.value};
        this.bookingsService.addBookingInExistInvoice(this.currentBookingId, formData.existINumber).subscribe(response => {
            if (response.add == false) {
                // invoice not exist
                this.animateLoader();
                this.addInExistInvoiceText = 'Add booking in this invoice';
                this.invoiceNotExistMessage = true;
            } else {
                // this.animateLoader();
                this.addInExistInvoiceText = 'Added';
                this.showInvoiceMode();
                this.getBooking(this.currentBookingId);
                setTimeout(() => {
                    this.addInExistInvoiceText = 'Add booking in this invoice';
                }, 1000);
            }
        });
    }

    saveInvoice(mode, send = null) {
        if (this.formNewInvoice.invalid) {
            return;
        }
        this.animateLoader();
        const formData = {...this.formNewInvoice.value};
        console.log('formData invoice', formData);
        this.bookingsService.saveInvoice(this.currentBookingId, 1, formData.client_name, formData.client_phone, formData.client_email, formData.client_address, formData.comment, formData.paid, formData.payment_method, mode, this.currentInvoiceId, send).subscribe(response => {
            this.getBooking(this.currentBookingId);
        });
    }

    viewInvoice(id) {
        window.open('https://1eyeonu.com/displayInvoice.php?id=' + id + '&PHPSESSID=' + localStorage.getItem('access_key'), '_blank');
    }

    sendInvoice() {
        this.saveInvoice('1', '1');
    }
}
